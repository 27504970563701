import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { languagesData } from "../../../../i18n/i18n";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function SettingPage({ userInfo }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const lan = localStorage.getItem("i18n");
  const getlanguageStr = () => {
    for (const lanData of languagesData) {
      if (lanData.code == lan) {
        return lanData.title;
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-2xl mx-auto px-4 py-8">
        {/* 标题区域 */}
        <div className="mb-6">
          <h1 className="text-2xl font-medium text-gray-900">
            {translate(getText("設置"))}
          </h1>
        </div>

        {/* 设置列表 */}
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
          {/* 语言设置 */}
          <div
            className="flex items-center justify-between px-6 py-4 cursor-pointer hover:bg-gray-50 transition-colors border-b border-gray-100"
            onClick={() => {
              navigate("/changelanguage");
            }}
          >
            <div className="flex items-center space-x-3">
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                />
              </svg>
              <span className="text-gray-700">
                {translate(getText("语言"))}
              </span>
            </div>
            <div className="flex items-center space-x-2 text-gray-600">
              <span>{getlanguageStr()}</span>
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>

          {/* 版本信息 */}
          <div className="flex items-center justify-between px-6 py-4">
            <div className="flex items-center space-x-3">
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="text-gray-700">
                {translate(getText("版本号"))}
              </span>
            </div>
            <div className="text-gray-600">2.6.5</div>
          </div>
        </div>

        {/* 退出登录按钮 */}
        <button
          className="w-full bg-white text-red-600 font-medium py-4 rounded-xl shadow-sm 
            hover:bg-red-50 active:bg-red-100 transition-colors duration-200"
          onClick={() => {
            localStorage.removeItem("uid");
            localStorage.removeItem("username");
            localStorage.removeItem("x-access-token");
            navigate("/login-page");
          }}
        >
          {translate(getText("退出登錄"))}
        </button>
      </div>
    </div>
  );
}
