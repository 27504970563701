import { homeApi } from "@/api/home-api";
import { imageConfig } from "@config/config";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CoinListData } from "./types";
import { useCtmarketlist } from "@hooks/useCtmarketlist";
import { getLogo } from "@utils/get-logo";

export function CoinList({
  coinListData,
  nowTab,
  uri = "trade",
}: {
  coinListData: CoinListData;
  nowTab: string;
  uri?: string;
}) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [popIndex, setPopIndex] = useState(2);
  const [name, setName] = useState("");
  const toPage = (name) => {
    navigate(`/${uri}/${name}`);
    // setIsShowCoin(false);
  };

  //去重
  function uniqueBySet(arr, key) {
    const uniqueSet = new Set();
    const uniqueArray = [];
    for (const item of arr) {
      const keyValue = item[key];
      if (!uniqueSet.has(keyValue)) {
        uniqueArray.push(item);
        uniqueSet.add(keyValue);
      }
    }
    return uniqueArray;
  }

  const nodeList = useMemo(() => {
    const nodes = [];
    let coinListDataTemp = coinListData;
    for (const key in coinListDataTemp) {
      nodes.push(coinListDataTemp[key]);
      // nodes.push(
      //   <div
      //     className={
      //       nowTab === key ? "coinPopupTopTitlelb-13" : "coinPopupTopTitlelb-12"
      //     }
      //     onClick={() => {
      //       toPage(key);
      //     }}
      //   >
      //     {key.toUpperCase()}/USDT
      //   </div>
      // );
    }
    return nodes;
  }, [coinListData]);
  const { ctmarketlist } = useCtmarketlist();

  return (
    <div className="css-sda488 eyn9kq66 !h-auto !md:h-[666px]">
      <div className="css-104x08q eyn9kq65">
        <div className=" css-znojfv e2y03nk4">
          {/* <div className=" css-1r3mth e2y03nk3">
                    <span className="css-1tf6jmc e2y03nk2">Favorite</span>
                  </div> */}
          <div className="css-12ti8dz css-1r3mth e2y03nk3">
            <div className="css-g8f50d e2y03nk0" style={{ opacity: 1 }} />
            <span className="css-l5snd e2y03nk1">Market</span>
          </div>
        </div>
      </div>
      <div id="market" className="css-13udsys eyn9kq64">
        <div className="css-1sbvlkw e4ajnrh5">
          <div className="css-1b4evln e4ajnrh4">
            <div
              className="css-155p5ll e4ajnrh2"
              style={{ minWidth: 14, maxWidth: 14 }}
            >
              <div className="css-12okdp4 e4ajnrh1">
                <span />
              </div>
            </div>
            <div className="css-evkm8q e4ajnrh2" style={{ minWidth: 73 }}>
              <div className="css-1miek3y e4ajnrh1">
                <span>Pair</span>
              </div>
            </div>
            <div className="css-pz1rk8 e4ajnrh2" style={{ minWidth: 83 }}>
              <div className="css-1miek3y e4ajnrh1">
                <span>Price</span>
              </div>
            </div>
            <div className="css-gmuyey e4ajnrh2" style={{ minWidth: 50 }}>
              <div className="css-1miek3y e4ajnrh1">
                <span>24h</span>
              </div>
            </div>
          </div>
          <div
            className="css-13udsys e4ajnrh3"
            style={{ position: "relative" }}
          >
            <div>
              <div
                aria-label="grid"
                aria-readonly="true"
                className="ReactVirtualized__Grid ReactVirtualized__List css-1knqwy6"
                role="grid"
                tabIndex={0}
                style={{
                  boxSizing: "border-box",
                  direction: "ltr",
                  position: "relative",
                  willChange: "transform",
                  overflow: "hidden",
                }}
              >
                <div
                  className="ReactVirtualized__Grid__innerScrollContainer pb-2"
                  role="rowgroup"
                  style={{
                    width: "auto",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {Object.entries(coinListData).map(([key, value], index) => (
                    <div
                      key={key}
                      style={{
                        height: 24,
                        // top: 24 * index,
                        // position: "absolute",
                        // left: 0,
                        width: "100%",
                      }}
                      onClick={() => {
                        toPage(key);
                      }}
                    >
                      <div className="css-1u9mfbt cursor-pointer hover:bg-grey-25 dark:hover:bg-dark-grey-25 css-p74clm e4ajnrh0">
                        <div
                          className="css-1u9mfbt css-evkm8q e4ajnrh2"
                          style={{ minWidth: 73 }}
                        >
                          <div className="css-oif990 egl67q33">
                            <div
                              className="css-h8y124 e1e6pa0j0"
                              // direction="horizontal"
                            >
                              <div className="w-3 h-3 flex items-center">
                                <img
                                  className="size-3"
                                  alt="Bitcoin-logo"
                                  // fetchpriority="high"
                                  width={12}
                                  height={12}
                                  decoding="async"
                                  data-nimg={1}
                                  srcSet=""
                                  src={getLogo(key, ctmarketlist)}
                                  style={{ color: "transparent" }}
                                />
                              </div>
                              <div
                                // direction="vertical"
                                className="css-lcjqpa e1e6pa0j0"
                              >
                                <div className="css-102mtiz egl67q32">
                                  <div className="css-eq7f8j egl67q31">
                                    <span className="css-18s3p6a egl67q34">
                                      {key.toUpperCase()}
                                    </span>
                                    <span className="css-149rphx egl67q30">
                                      /USDT
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="css-1u9mfbt css-pz1rk8 e4ajnrh2"
                          style={{ minWidth: 83 }}
                        >
                          <span className="css-bnlq06 egl67q34">
                            {value.close}
                          </span>
                        </div>
                        <div
                          className="css-1u9mfbt css-gmuyey e4ajnrh2"
                          style={{ minWidth: 50 }}
                        >
                          <div className="flex items-center justify-end">
                            <div className="css-8irbms em8wip62">
                              <div
                                className={`!title-5 css-1ys42qh em8wip60 ${
                                  value.close < value.open
                                    ? "!text-red-500"
                                    : "!text-green-500"
                                }`}
                              >
                                {value.close < value.open ? "" : "+"}
                                {value.close &&
                                  (
                                    ((value.close - value.open) / value.open) *
                                    100
                                  ).toFixed(2)}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="!hidden resize-triggers">
                <div className="expand-trigger">
                  <div style={{ width: 271, height: 302 }} />
                </div>
                <div className="contract-trigger" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
