// src/pages/followup/MyFollowup.tsx
import React, { useState, useEffect } from 'react';
import { Dialog, Tabs, FloatingBubble } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import './index.css';
import { followApi } from '@/api/followup';
import { userApi } from '@/api/user-api';
import { message } from 'antd';
import { Header } from '@components/header';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

interface Trader {
  id: string;
  name: string;
  avatar: string;
  followAmount: number;
  profit: number;
  profitRate: number;
  followTime: string;
}

interface UserInfo {
  email: string;
  avatar: string;
  totalFollowAmount: number;
  totalProfit: number;
  totalProfitRate: number;
  currentFollowing: number;
}

// Mock当前带单数据
const MOCK_CURRENT_ORDERS = [
  {
    id: '1',
    followerId: '12345',
    amount: 133479.28,
    downPrice: 93640.1,
    direction: 'buy', // 买入方向
    profit: 99909.24,
    profitRate: 5,
    status: 'active',
    period: '1min(20:21~20:22)',
  },
  {
    id: '2',
    followerId: '12346',
    amount: 150000,
    downPrice: 94200.5,
    direction: 'sell', // 卖出方向
    profit: 85000,
    profitRate: 8,
    status: 'active',
    period: '1min(20:25~20:26)',
  },
];

// Mock历史带单数据
const MOCK_HISTORY_ORDERS = [
  {
    id: '3',
    followerId: '12347',
    amount: 120000,
    downPrice: 92100.3,
    direction: 'buy',
    profit: 78500,
    profitRate: 15,
    status: 'completed',
    period: '1min(19:21~19:22)',
  },
  {
    id: '4',
    followerId: '12348',
    amount: 98000,
    downPrice: 91800.6,
    direction: 'sell',
    profit: -25000,
    profitRate: -2.5,
    status: 'completed',
    period: '1min(19:25~19:26)',
  },
];

const MyFollowup: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('myTraders');

  const [currentOrders, setCurrentOrders] = useState<any[]>([]);
  const [historyOrders, setHistoryOrders] = useState<any[]>([]);
  const [currentFollows, setCurrentFollows] = useState<Trader[]>([]);
  const [loading, setLoading] = useState(false);
  const [myFollowList, setMyFollowList] = useState([]);
  const [allOrders, setAllOrders] = useState<any[]>([]); // 存储所有订单数据
  const [mcdUserInfo, setMcdUserInfo] = useState<any>({});
  const [userInfoData, setUserInfoData] = useState({});

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      setUserInfoData(data.data);
    }
  };

  // Mock用户信息
  // const userInfo: UserInfo = {
  //   email: 'xili@gmail.com',
  //   avatar: '/followup/3.png',
  //   totalFollowAmount: 0,
  //   totalProfit: 0,
  //   totalProfitRate: 0,
  //   currentFollowing: 0,
  // };

  // Mock我的交易员数据
  const mockMyTraders: Trader[] = [
    {
      id: '1',
      name: '交易达人A',
      avatar: '/trader1.png',
      followAmount: 1000,
      profit: 150,
      profitRate: 15,
      followTime: '2024-01-15',
    },
    // ... 可以添加更多mock数据
  ];

  const getMcdUserInfo = async () => {
    const uid = localStorage.getItem('uid');
    const data = await followApi.getMcdUserInfo(uid);
    if (data.ok) {
      setMcdUserInfo(data.data);
    }
  };

  const getMyFollowupOrder = async () => {
    const { code, level, msg, data } = await followApi.getMyFollowList();

    if (code === 0) {
      setMyFollowList(data);
    } else {
      message.warning(msg);
    }
  };

  // Mock获取当前带单数据
  const getCurrentOrders = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 300));
    // setCurrentOrders(MOCK_CURRENT_ORDERS);
    const uid = localStorage.getItem('uid');
    const data = await followApi.getHistoryOrders(uid);
    if (data.ok) {
      setCurrentOrders(data.data);
    }
  };

  // Mock获取历史带单数据
  const getHistoryOrders = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 300));
    // setHistoryOrders(MOCK_HISTORY_ORDERS);
    const uid = localStorage.getItem('uid');
    const data = await followApi.getHistoryOrders(uid);
    if (data.ok) {
      setHistoryOrders(data.data);
    }
  };

  // 获取订单数据的函数
  const getFollowOrders = async () => {
    setLoading(true);
    try {
      const uid = localStorage.getItem('uid');
      const { code, data, msg } = await followApi.getHistoryOrders(uid);
      if (code === 0) {
        setAllOrders(data || []);
      } else {
        message.warning(msg);
      }
    } catch (error) {
      console.error('获取订单失败:', error);
      message.error(t('獲取訂單失敗'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyFollowupOrder();
    getFollowOrders();
    getCurrentOrders();
    getHistoryOrders();
    getMcdUserInfo();
    loadUserInfoData();
    return () => {};
  }, []);

  // Mock历史跟单数据
  const mockHistoryFollows: Trader[] = [
    {
      id: '1',
      name: 'Trading Master A',
      avatar: '/trader1.png',
      followAmount: 2000,
      profit: -100,
      profitRate: -5,
      followTime: '2023-12-01',
    },
    {
      id: '2',
      name: 'Crypto Expert B',
      avatar: '/trader2.png',
      followAmount: 5000,
      profit: 750,
      profitRate: 15,
      followTime: '2023-11-15',
    },
    {
      id: '3',
      name: 'Bitcoin Trader C',
      avatar: '/trader3.png',
      followAmount: 3000,
      profit: 450,
      profitRate: 15,
      followTime: '2023-10-28',
    },
    {
      id: '4',
      name: 'Forex King D',
      avatar: '/trader4.png',
      followAmount: 10000,
      profit: -500,
      profitRate: -5,
      followTime: '2023-10-01',
    },
    {
      id: '5',
      name: 'Strategy Pro E',
      avatar: '/trader5.png',
      followAmount: 8000,
      profit: 1200,
      profitRate: 15,
      followTime: '2023-09-15',
    },
    {
      id: '6',
      name: 'Market Guru F',
      avatar: '/trader6.png',
      followAmount: 15000,
      profit: -750,
      profitRate: -5,
      followTime: '2023-09-01',
    },
    // ... 可以添加更多mock数据
  ];

  useEffect(() => {
    // 跟单员
    if (activeTab === 'myTraders') {
      getMyFollowupOrder();
    }
    // 当前跟单和历史跟单都使用同一个接口
    else if (activeTab === 'currentFollows' || activeTab === 'historyFollows') {
      getFollowOrders();
    }
  }, [activeTab]);

  const handleEndFollow = async (uid: string) => {
    const result = await Dialog.confirm({
      title: t('提示'),
      content: t('确认要结束跟单吗？'),
      cancelText: t('取消'),
      confirmText: t('确认'),
      closeOnAction: true,
    });

    if (result) {
      const data = await followApi.cancelFollow(uid);
      if (data.ok) {
        console.log('结束跟单', uid);
        getMyFollowupOrder();
      }
    }
  };

  // 添加成为交易员的处理函数
  const handleBecomeTrader = async () => {
    navigate('/followup/become-trader');
    return;
  };

  const renderTraderCard = (trader: Trader) => (
    <div
      key={trader.id}
      className={`bg-white rounded-2xl p-4 border transition-all ${
        trader.isHistory
          ? 'border-gray-100 opacity-75'
          : 'border-gray-100 hover:border-gray-200'
      }`}
    >
      {/* 头部信息 */}
      <div className="flex items-center gap-3 mb-2">
        <div className="relative">
          <img
            src="/followup/1.png"
            alt=""
            className={`w-11 h-11 rounded-full object-cover ring-2 ${
              trader.isHistory ? 'ring-gray-100 opacity-80' : 'ring-gray-50'
            }`}
          />
          {!trader.isHistory && trader.isOnline && (
            <div className="absolute -right-0.5 -bottom-0.5 w-3.5 h-3.5 bg-green-500 rounded-full ring-2 ring-white" />
          )}
        </div>

        <div className="flex-1">
          <div
            className={`text-base font-semibold ${
              trader.isHistory ? 'text-gray-600' : 'text-gray-800'
            }`}
          >
            {trader.name}
          </div>
          <div className="flex items-center text-xs text-gray-400 mt-0.5">
            <svg
              className="w-3.5 h-3.5 mr-1 opacity-60"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm.5-13H11v6l5.2 3.2.8-1.3-4.5-2.7V7z" />
            </svg>
            {trader.date}
          </div>
        </div>
      </div>

      {/* 数据展示 */}
      <div className="flex items-center border-t border-gray-50 pt-3 -mx-2">
        <div className="flex-1 px-2">
          <div
            className={`text-xs font-medium ${
              trader.isHistory ? 'text-gray-600' : 'text-gray-800'
            }`}
          >
            {trader.amount} <span className="text-gray-400">USDT</span>
          </div>
          <div className="text-xs text-gray-400 mt-1">{t('跟單金額')}</div>
        </div>

        <div className="flex-1 px-2 border-l border-gray-50">
          <div
            className={`text-xs font-medium ${
              trader.isHistory
                ? 'text-gray-600'
                : trader.income >= 0
                ? 'text-green-500'
                : 'text-red-500'
            }`}
          >
            {trader.income > 0 ? '+' : ''}
            {trader?.income} <span className="opacity-90">USDT</span>
          </div>
          <div className="text-xs text-gray-400 mt-1">Income</div>
        </div>

        <div className="flex-1 px-2 border-l border-gray-50">
          <div
            className={`text-xs font-medium ${
              trader.isHistory
                ? 'text-gray-600'
                : trader.rateOfReturn >= 0
                ? 'text-green-500'
                : 'text-red-500'
            }`}
          >
            {trader.rateOfReturn > 0 ? '+' : ''}
            {trader.rateOfReturn}%
          </div>
          <div className="text-xs text-gray-400 mt-1">Rate of Return</div>
        </div>
      </div>
    </div>
  );

  // 渲染当前带单列表
  const renderCurrentOrders = () => (
    <div className={`${isMobile ? 'space-y-3 px-3' : 'flex flex-wrap gap-8'}`}>
      {currentOrders
        .filter((order) => order.status == 0 || order.status == 1)
        .map((order) => (
          <div
            key={order.id}
            className={`relative bg-gradient-to-br from-white to-emerald-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ${
              !isMobile ? 'w-[340px]' : ''
            } border-l-4 border-emerald-400 hover:translate-y-[-2px]`}
          >
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('幣種')}</div>
                <div className="text-gray-800 font-medium">
                  {order.coin || 'BTC'}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('收益')}</div>
                <div className="text-emerald-500 font-medium">
                  {order.isWin === 1 ? order.ploss : -order.ploss} USDT
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單週期')}</div>
                <div className="text-gray-800 font-medium">{order.time}</div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單方向')}</div>
                <div
                  className={`font-medium ${
                    order.hyzd === 'buy' ? 'text-emerald-500' : 'text-rose-500'
                  }`}
                >
                  {order.hyzd === 'buy' ? t('買') : t('賣')}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單價格')}</div>
                <div className="text-gray-800 font-medium">
                  {order.buyprice}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單金額')}</div>
                <div className="text-gray-800 font-medium">{order.num}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );

  // 渲染历史带单列表
  const renderHistoryOrders = () => (
    <div className={`${isMobile ? 'space-y-3 px-3' : 'flex flex-wrap gap-8'}`}>
      {historyOrders
        .filter((order) => order.status == 2 || order.status == 4)
        .map((order) => (
          <div
            key={order.id}
            className={`relative bg-gradient-to-br from-white to-emerald-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ${
              !isMobile ? 'w-[340px]' : ''
            } border-l-4 border-emerald-400 hover:translate-y-[-2px]`}
          >
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('收益')}</div>
                <div
                  className={`font-medium ${
                    order.isWin === 1 ? 'text-emerald-500' : 'text-rose-500'
                  }`}
                >
                  {order.isWin === 1 ? order.ploss : -order.ploss} USDT
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單週期')}</div>
                <div className="text-gray-800 font-medium">{order.time}</div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單方向')}</div>
                <div
                  className={`font-medium ${
                    order.hyzd === 1 ? 'text-emerald-500' : 'text-rose-500'
                  }`}
                >
                  {order.hyzd === 1 ? t('買') : t('賣')}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單價格')}</div>
                <div className="text-gray-800 font-medium">
                  {order.buyprice}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單金額')}</div>
                <div className="text-gray-800 font-medium">{order.num}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
  return (
    <div
      className={`bg-white rounded-2xl shadow-sm pb-6 py-0 px-1 ${
        !isMobile ? 'max-w-[1200px] mx-auto' : ''
      }`}
    >
      <Header />
      {/* 用户信息区域 */}

      <div className="flex items-center justify-between my-2">
        <div className="text-gray-500">
          <span
            className="cursor-pointer hover:text-blue-500 transition-colors"
            onClick={() => navigate('/followup/btc')}
          >
            {t('跟單交易')}
          </span>
          {' > '}
          <span>{t('我的跟单')}</span>
        </div>
        <button
          onClick={() => navigate('/followup/btc')}
          className="text-gray-500 hover:text-blue-500 transition-colors"
        >
          ← {t('返回')}
        </button>
      </div>

      {isMobile && (
        <div className="bg-white rounded-2xl shadow-sm p-6 space-y-6">
          {/* 用户信息区域 */}
          <div className="flex flex-col items-center">
            <img
              src={userInfoData.avatar}
              alt=""
              className="w-20 h-20 rounded-full object-cover ring-4 ring-gray-50 shadow-sm mb-3"
            />
            <div className="text-sm font-medium text-gray-900 mb-1">
              {userInfoData.username}
            </div>
            <div className="text-xs text-blue-500 flex items-center gap-1">
              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
              </svg>
              {t('正在關注')}
              <span className="font-medium">{mcdUserInfo.starCount}</span>
            </div>
          </div>

          {/* 统计数据区域 */}
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-gray-50 rounded-xl p-4 hover:shadow-md transition-all duration-200">
              <div className="flex items-baseline gap-1 mb-1">
                <span className="text-xs font-semibold text-gray-900">
                  {mcdUserInfo.amount}
                </span>
                <span className="text-xs text-gray-400">USDT</span>
              </div>
              <div className="text-xs text-gray-600">{t('跟單總金額')}</div>
            </div>

            <div className="bg-gray-50 rounded-xl p-4 hover:shadow-md transition-all duration-200">
              <div className="flex items-baseline gap-1 mb-1">
                <span className="text-xs font-semibold text-gray-900">
                  {mcdUserInfo.ploss}
                </span>
                <span className="text-xs text-gray-400">USDT</span>
              </div>
              <div className="text-xs text-gray-600">{t('總盈虧')}</div>
            </div>

            <div className="bg-gray-50 rounded-xl p-4 hover:shadow-md transition-all duration-200">
              <div className="text-xs font-semibold text-gray-900 mb-1">
                {mcdUserInfo.profitRate}%
              </div>
              <div className="text-xs text-gray-600">{t('總收益率')}</div>
            </div>
          </div>

          {/* 成为交易员按钮 */}
          <button
            disabled={userInfoData.merchandiser === 1}
            style={{
              backgroundColor: userInfoData.merchandiser === 1 ? '#E5E7EB' : '',
            }}
            onClick={handleBecomeTrader}
            className="w-full py-3 px-4 bg-[#4ea5ab] hover:bg-[#03b989] active:bg-[#03a57a] text-white rounded-xl font-medium transition-colors duration-200 flex items-center justify-center gap-2"
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                d="M12 4v16m8-8H4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('成為交易員')}
          </button>
        </div>
      )}

      {!isMobile && (
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <div className="flex items-center justify-between">
            {/* 左侧区域 */}
            <div className="flex gap-16">
              {/* 左侧：头像和所有文字内容垂直排列 */}
              <div className="flex items-center gap-4">
                <img
                  src={userInfoData.avatar}
                  alt=""
                  className="w-16 h-16 rounded-full object-cover ring-4 ring-gray-50 shadow-sm"
                />
                <div className="flex flex-col justify-center">
                  {/* 用户信息 */}
                  <div className="text-sm font-bold text-gray-900 mb-1 font-sans">
                    {userInfoData.username}
                  </div>
                  <div className="text-xs text-gray-500 flex items-center gap-1 mb-3 ">
                    {t('正在關注')}
                    <span className="font-medium">{mcdUserInfo.starCount}</span>
                  </div>

                  {/* 统计数据 */}
                  <div className="flex gap-8">
                    <div>
                      <div className="text-base text-gray-900 font-medium font-sans">
                        0
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('跟單總金額(USDT)')}
                      </div>
                    </div>

                    <div>
                      <div className="text-base text-gray-900 font-medium font-sans">
                        0
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('已實現盈虧(USDT)')}
                      </div>
                    </div>

                    <div>
                      <div className="text-base text-gray-900 font-medium font-sans">
                        0%
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('總收益率')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 右侧按钮 */}
            <button
              onClick={handleBecomeTrader}
              className="px-6 py-2.5 bg-[#4ea5ab] hover:bg-[#03b989] active:bg-[#03a57a] text-white rounded-full font-medium transition-colors duration-200"
            >
              {t('成為交易員')}
            </button>
          </div>
        </div>
      )}

      {/* Tab切换区域 */}
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        className="follow-tabs"
      >
        <Tabs.Tab title={t('我的交易員')} key="myTraders">
          {/* <div className="tab-content"> */}
          <div
            className={`${isMobile ? 'tab-content' : 'flex flex-wrap gap-8'}`}
          >
            {loading ? (
              <div className="loading-state">{t('加載中...')}</div>
            ) : myFollowList.length > 0 ? (
              myFollowList.map((item, index) => {
                return (
                  <div
                    key={item?.uid}
                    className={`relative bg-gradient-to-br from-white to-emerald-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ${
                      !isMobile ? 'w-[340px]' : ''
                    } border-l-4 border-emerald-400 hover:translate-y-[-2px]`}
                  >
                    <div className="trader-info">
                      <img
                        src="/followup/income-DWjozVpO.svg"
                        alt=""
                        className="trader-avatar"
                      />
                      <div className="trader-details">
                        <div className="trader-name">{item?.name}</div>
                        <div className="follow-time">{item?.date}</div>
                      </div>
                    </div>
                    <div className="trader-stats">
                      <div className="stat-item">
                        <div className="stat-value">
                          {item?.day} {t('天')}
                        </div>
                        <div className="stat-label">{t('跟單天數')}</div>
                      </div>
                      <div className="stat-item">
                        <div
                          className={`stat-value ${
                            item?.profit >= 0
                              ? 'profit-positive'
                              : 'profit-negative'
                          }`}
                        >
                          {item?.profit > 0 ? '+' : ''}
                          {item?.profit} USDT
                        </div>
                        <div className="stat-label">{t('收益')}</div>
                      </div>
                      <button
                        className="text-xs text-gray-400 hover:text-gray-600"
                        onClick={() => handleEndFollow(item?.uid)}
                      >
                        {t('结束跟单')}
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="empty-state">
                <img src="/empty-state.png" alt="" />
                <p>{t('暫無數據')}</p>
              </div>
            )}
          </div>
        </Tabs.Tab>
        <Tabs.Tab title={t('當前跟單')} key="current">
          <div>
            {currentOrders.length > 0 ? (
              renderCurrentOrders()
            ) : (
              <div className="text-center text-gray-500 py-8">
                {t('暫無帶單記錄')}
              </div>
            )}
          </div>
        </Tabs.Tab>
        <Tabs.Tab title={t('歷史跟單')} key="history">
          <div>
            {historyOrders.length > 0 ? (
              renderHistoryOrders()
            ) : (
              <div className="text-center text-gray-500 py-8">
                {t('暫無歷史記錄')}
              </div>
            )}
          </div>
        </Tabs.Tab>
      </Tabs>
      {/* {isMobile && (
        <FloatingBubble
          style={{
            '--initial-position-top': '48px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
          }}
          onClick={() => navigate('/followup/btc')}
        >
          <div className="flex items-center gap-1.5 px-3 py-1.5">
            <span className="text-xs">Back</span>
          </div>
        </FloatingBubble>
      )} */}
    </div>
  );
};

export default MyFollowup;
