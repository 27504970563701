import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSwitchAccountStore } from './switch-account-modal-store';
import { financeApi } from '@/api/finance-api';
import { userApi } from '@/api/user-api';

export const SwitchAccountModal: React.FC = () => {
  const { t } = useTranslation();
  const { 
    visible, 
    setVisible,
  } = useSwitchAccountStore();
  const [userInfo, setuserInfo] = useState({} as any);
  const [mockUserInfo, setMockUserInfo] = useState({} as any);
  const uid = localStorage.getItem("uid");
  const currentType = localStorage.getItem("propertyType");

  useEffect(() => {
    loadData();
    getMockUserInfo();
  }, []);
  //加载数 据
  const loadData = async () => {
    const data = await financeApi.userCoin({ uid });
    if (data.ok) {
      console.log(data.data);

      setuserInfo(data.data);
    }
  };
  const changepropertyType = async (type) => {
    const data = await userApi.mockUser({ type, uid });
    if (data.ok) {
            
      localStorage.setItem("propertyType", type);
      setVisible(false);
      window.location.reload();
    }
  };


  //加载数 据1
  const getMockUserInfo = async () => {
    const data = await userApi.mockUserInfo({ uid });
    if (data.ok) {
      setMockUserInfo(data.data);
    }
  };


  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width="95%"
      style={{ maxWidth: '400px', minWidth: '280px' }}
      centered
      className="rounded-lg overflow-hidden shadow-2xl"
    >
      <div className="">
        <h3 className="text-lg font-semibold text-gray-800 text-center mb-5">
          {t('我的账户')}
        </h3>
        
        <div className="space-y-3">
          <div
            onClick={() => changepropertyType(1)}
            className={`
              relative p-4 rounded-md cursor-pointer
              transform transition-all duration-200 ease-in-out
              hover:scale-[1.02] hover:shadow-lg
              ${currentType === "1" 
                ? 'bg-primary-50/70 ring-2 ring-primary-700 shadow-sm' 
                : 'bg-white hover:bg-gray-50/80 border border-gray-200'
              }
            `}
          >
            <div className="text-gray-800 font-medium text-sm mb-2">
              {t('实际账户')}
            </div>
            <div className="flex items-baseline">
              <span className="text-gray-400 text-xs mr-2">USDT</span>
              <span className="text-primary-700 text-xl font-semibold tracking-tight">
                {userInfo?.usdt}
              </span>
            </div>
          </div>

          <div
            onClick={() => changepropertyType(2)}
            className={`
              relative p-4 rounded-md cursor-pointer
              transform transition-all duration-200 ease-in-out
              hover:scale-[1.02] hover:shadow-lg
              ${currentType === "2" 
                ? 'bg-primary-50/70 ring-2 ring-primary-700 shadow-sm' 
                : 'bg-white hover:bg-gray-50/80 border border-gray-200'
              }
            `}
          >
            <div className="text-gray-800 font-medium text-sm mb-2">
              {t('模拟账户')}
            </div>
            <div className="flex items-baseline">
              <span className="text-gray-400 text-xs mr-2">USDT</span>
              <span className="text-primary-700 text-xl font-semibold tracking-tight">
                {mockUserInfo?.money}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}; 