import { Empty } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Dialog } from "react-vant";
import { newsApi } from "../../../../api/news-api";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function CenterPage({
  coinListData,
  leverorders,
  closeorder,
  addnumFun,
  strutcnumFun,
  editLossWinFun,
}) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const lan = localStorage.getItem("i18n");
  const [index, setindex] = useState(1);
  //加 减 改
  const [tempData, settempData] = useState({} as any);
  const [addnum, setaddnum] = useState("");
  const [addnumVisible, setaddnumVisible] = useState(false);
  const [strutcnum, setstrutcnum] = useState("");
  const [strutcnumVisible, setstrutcnumVisible] = useState(false);
  const [lossnum, setlossnum] = useState("");
  const [winnum, setwinnum] = useState("");
  const [editLossWinVisible, seteditLossWinVisible] = useState(false);

  const getboomPrice = (type, bsnum, nowTab) => {
    nowTab = nowTab?.replace("/USDT", "")?.toLowerCase();
    const openPrice = coinListData[nowTab]?.close;
    let price = 0;
    if (type == 1) {
      price = openPrice * (1 - 1 / bsnum + 0.005);
    }
    if (type == 2) {
      price = openPrice * (1 + 1 / bsnum - 0.005);
    }
    return price;
  };

  const getNode1 = () => {
    const nodes = [];
    const leverorderstemp = leverorders.filter(
      (data) => data.status === (index == 1 ? 1 : 2)
    );
    for (let index = 0; index < leverorderstemp.length; index++) {
      const data = leverorderstemp[index];
      const priceyd = (
        data.num *
        data.fold *
        ((data.buyprice -
          coinListData[data.coinname.replace("/USDT", "").toLowerCase()]
            ?.close) /
          data.buyprice)
      ).toFixed(4);
      const node = (
        <li className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 mb-4">
          <div
            className="p-4"
            onClick={() => {
              const sendData = {
                orderNo: data.orderNo,
                coinname: data.coinname,
                num: data.num,
                ploss: data.ploss,
                buytime: data.buytime,
                status: data.status,
                isWin: data.isWin,
              };
              if (data.status == 1) return;
              navigate(
                `/marketOrderInfo/${data.id}?data=${JSON.stringify(sendData)}`
              );
            }}
          >
            {/* 主要信息区域 */}
            <div className="grid grid-cols-3 gap-4">
              {/* 左侧信息 */}
              <div className="space-y-3">
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("交易品種"))}
                  </div>
                  <div className="text-gray-900 font-medium">
                    {data.coinname}
                  </div>
                </div>
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("保证金"))}
                  </div>
                  <div className="text-gray-900">{data.num?.toFixed(4)}</div>
                </div>
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("倍数"))}
                  </div>
                  <div className="text-gray-900">{data.fold}</div>
                </div>
              </div>

              {/* 中间价格信息 */}
              <div className="space-y-3">
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("開倉價"))}
                  </div>
                  <div className="text-gray-900">
                    {data.buyprice?.toFixed(4)}
                  </div>
                </div>
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText(data.status == 1 ? "現價" : "结算價"))}
                  </div>
                  <div className="text-gray-900">
                    {data.status == 1
                      ? coinListData[
                          data.coinname.replace("/USDT", "").toLowerCase()
                        ]?.close
                      : data.sellprice}
                  </div>
                </div>
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("强平价格"))}
                  </div>
                  <div className="text-gray-900">
                    {data.boomPrice?.toFixed(4)}
                  </div>
                </div>
              </div>

              {/* 右侧信息 */}
              <div className="space-y-3">
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("方向"))}
                  </div>
                  <div
                    className={`inline-block px-2 py-1 rounded-full text-sm
                    ${
                      data.hyzd == 1
                        ? "bg-green-50 text-green-600"
                        : "bg-red-50 text-red-600"
                    }`}
                  >
                    {translate(getText(data.hyzd == 1 ? "買多" : "買空"))}
                  </div>
                </div>
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("止損價"))}
                  </div>
                  <div className="text-gray-900">{data.lossPrice}</div>
                </div>
                <div>
                  <div className="text-gray-500 text-sm mb-1">
                    {translate(getText("止盈價"))}
                  </div>
                  <div className="text-gray-900">{data.winPrice}</div>
                </div>
              </div>
            </div>

            {/* 收益信息 */}
            <div className="mt-4 pt-4 border-t border-gray-100">
              {data.status == 1 && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">
                    {translate(getText("預期收益"))}
                  </span>
                  <span
                    className={`text-lg font-medium ${
                      (priceyd < 0 && data.hyzd == 1) ||
                      (priceyd > 0 && data.hyzd == 2)
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {(priceyd < 0 && data.hyzd == 1) ||
                    (priceyd > 0 && data.hyzd == 2)
                      ? Math.abs(priceyd)
                      : -Math.abs(priceyd)}
                  </span>
                </div>
              )}
              {data.status == 2 && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">
                    {translate(getText("收益"))}
                  </span>
                  <span
                    className={`text-lg font-medium ${
                      data.ploss > 0 ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {data.ploss > 0 ? "+" : ""}
                    {data.ploss}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* 操作按钮区域 */}
          {data.status == 1 && (
            <div className="flex items-center justify-end gap-2 px-4 py-3 bg-gray-50 rounded-b-lg">
              <button
                className="px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-200"
                onClick={(e) => {
                  Dialog.confirm({
                    title: translate(getText("提示")),
                    message: translate(getText("是否确认平仓？")),
                    cancelButtonText: translate(getText("取消")),
                    confirmButtonText: translate(getText("确认")),
                    onConfirm: () => {
                      closeorder(
                        data.id,
                        data?.num,
                        (priceyd < 0 && data.hyzd == 1) ||
                          (priceyd > 0 && data.hyzd == 2)
                          ? Math.abs(priceyd)
                          : -Math.abs(priceyd)
                      );
                    },
                  });
                }}
              >
                {translate(getText("平倉"))}
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-50 rounded-md transition-colors duration-200"
                onClick={() => {
                  settempData(data);
                  setaddnumVisible(true);
                  setaddnum("");
                }}
              >
                {translate(getText("加仓"))}
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-orange-600 hover:bg-orange-50 rounded-md transition-colors duration-200"
                onClick={() => {
                  settempData(data);
                  setstrutcnumVisible(true);
                  setstrutcnum("");
                }}
              >
                {translate(getText("减仓"))}
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 rounded-md transition-colors duration-200"
                onClick={() => {
                  settempData(data);
                  seteditLossWinVisible(true);
                  setlossnum(data?.lossPrice);
                  setwinnum(data?.winPrice);
                }}
              >
                {translate(getText("設定止盈止損"))}
              </button>
            </div>
          )}
        </li>
      );
      nodes.push(node);
    }
    return nodes;
  };

  const getNode2 = () => {
    const nodes = [];
    const leverorderstemp = leverorders.filter(
      (data) => data.status === (index == 1 ? 1 : 2)
    );
    for (let index = 0; index < leverorderstemp.length; index++) {
      const data = leverorderstemp[index];
      const priceyd = (
        data.num *
        data.fold *
        ((data.buyprice -
          coinListData[data.coinname.replace("/USDT", "").toLowerCase()]
            ?.close) /
          data.buyprice)
      ).toFixed(4);
      const node = (
        <li
          className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 cursor-pointer mb-4"
          onClick={() => {
            const sendData = {
              orderNo: data.orderNo,
              coinname: data.coinname,
              num: data.num,
              ploss: data.ploss,
              buytime: data.buytime,
              status: data.status,
              isWin: data.isWin,
            };
            if (data.status == 1) return;
            navigate(
              `/marketOrderInfo/${data.id}?data=${JSON.stringify(sendData)}`
            );
          }}
        >
          <div className="p-4">
            {/* 头部信息 */}
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center space-x-2">
                <span className="text-base font-medium text-gray-900">
                  {data.coinname}
                </span>
                <div
                  className={`px-2 py-1 rounded-full text-xs font-medium
                  ${
                    data.hyzd == 1
                      ? "bg-green-50 text-green-600"
                      : "bg-red-50 text-red-600"
                  }`}
                >
                  {data.hyzd == 1
                    ? translate(getText("買多"))
                    : translate(getText("買空"))}
                </div>
              </div>
              <div className="text-sm text-gray-500">
                {data.buytime?.substring(0, 16)}
              </div>
            </div>

            {/* 金额信息 */}
            <div className="flex justify-between items-center py-3 border-t border-b border-gray-100">
              <div className="flex items-baseline space-x-1">
                <span className="text-lg font-semibold text-gray-900">
                  {data.num.toFixed(4)}
                </span>
                <span className="text-sm text-gray-500">USDT</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-600">
                  {translate(getText("盈虧"))}
                </span>
                {data?.status == 2 && (
                  <span
                    className={`text-base font-medium
                    ${data.isWin == 1 ? "text-green-600" : "text-red-600"}`}
                  >
                    <span>
                      {data.isWin == 1 ? "+" : "-"}
                      {data.ploss}
                    </span>
                  </span>
                )}
              </div>
            </div>

            {/* 底部信息 */}
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div>
                <div className="text-sm text-gray-500 mb-1">
                  {translate(getText("購買"))}
                  {lan == "zh" ? "" : <span>&nbsp;</span>}
                  {translate(getText("價格"))}
                </div>
                <div className="text-sm font-medium text-gray-900">
                  {data.buyprice}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500 mb-1">
                  {translate(getText("倍数"))}
                </div>
                <div className="text-sm font-medium text-gray-900">
                  {data.fold}X
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500 mb-1">
                  {translate(getText("操作"))}
                </div>
                <div>
                  {data?.status == 1 && (
                    <button
                      className="px-4 py-1.5 bg-red-600 text-white text-sm font-medium rounded-md hover:bg-red-700 transition-colors duration-200"
                      onClick={(e) => {
                        closeorder(
                          data.id,
                          data?.num,
                          (priceyd < 0 && data.hyzd == 1) ||
                            (priceyd > 0 && data.hyzd == 2)
                            ? Math.abs(priceyd)
                            : -Math.abs(priceyd)
                        );
                        e.stopPropagation();
                      }}
                    >
                      {translate(getText("平仓"))}
                    </button>
                  )}
                  {data?.status != 1 && (
                    <span className="text-blue-600 text-sm cursor-pointer hover:text-blue-700">
                      {translate(getText("詳情"))}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </li>
      );
      nodes.push(node);
    }
    return nodes;
  };
  return (
    <div className="max-w-7xl mx-auto">
      {/* Tab Navigation - 更现代的设计 */}
      <div className="flex justify-center mb-8">
        <div className="bg-gray-100/50 backdrop-blur-sm p-1.5 rounded-xl flex gap-1 shadow-inner">
          <button
            className={`px-8 py-3 rounded-lg font-medium text-sm transition-all duration-200 min-w-[140px]
              ${
                index == 1
                  ? "bg-white text-blue-600 shadow-sm"
                  : "text-gray-600 hover:bg-white/50"
              }`}
            onClick={() => setindex(1)}
          >
            {translate(getText("在持"))}
          </button>
          <button
            className={`px-8 py-3 rounded-lg font-medium text-sm transition-all duration-200 min-w-[140px]
              ${
                index == 2
                  ? "bg-white text-blue-600 shadow-sm"
                  : "text-gray-600 hover:bg-white/50"
              }`}
            onClick={() => setindex(2)}
          >
            {translate(getText("已成交"))}
          </button>
          <button
            className={`px-8 py-3 rounded-lg font-medium text-sm transition-all duration-200 min-w-[140px]
              ${
                index == 3
                  ? "bg-white text-blue-600 shadow-sm"
                  : "text-gray-600 hover:bg-white/50"
              }`}
            onClick={() => setindex(3)}
          >
            {translate(getText("已平倉"))}
          </button>
        </div>
      </div>

      {/* Content Container - 更好的布局和间距 */}
      <div className="px-4 md:px-6">
        <ul className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6">
          {index == 1 && getNode1()}
          {index != 1 && getNode2()}
        </ul>
      </div>

      {/* Empty State - 优雅的空状态展示 */}
      {(!getNode1().length && index == 1) ||
      (!getNode2().length && index != 1) ? (
        <div className="flex flex-col items-center justify-center py-16">
          <div className="w-16 h-16 mb-4">
            <svg
              className="w-full h-full text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
          </div>
          <p className="text-gray-500 text-sm">
            {translate(getText("暂无数据"))}
          </p>
        </div>
      ) : null}
    </div>
  );
}
