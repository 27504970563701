import './index.css';

export const TopNavBox = () => {
  return (
    <div className="relative w-full md:h-[60px] h-8 overflow-hidden transition-all duration-300 ease-[cubic-bezier(0.4,0,0.2,1)]">
      <div className="flex h-1 bg-[rgb(124,92,188)]"></div>
      <div data-mode="exchange" className="css-rmiqmu ech2t3n0 flex justify-center !-translate-x-1/2 !-top-[20px]">
        <div
          data-testid="reku-icon"
          data-name="icon-DoubleChevronDown"
          className="w-fit h-fit inline-flex items-center justify-center"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-transform duration-300"
          >
            <mask
              id="mask0_63_2900"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
              style={{ maskType: "alpha" }}
            >
              <path d="M0 0L24 0V24H0L0 0Z" fill="currentColor"></path>
            </mask>
            <g mask="url(#mask0_63_2900)">
              <path
                d="M6.70001 12.275C6.88335 12.0917 7.11668 12 7.40001 12C7.68335 12 7.91668 12.0917 8.10001 12.275L12 16.175L15.9 12.275C16.0833 12.0917 16.3167 12 16.6 12C16.8833 12 17.1167 12.0917 17.3 12.275C17.4833 12.4583 17.575 12.6917 17.575 12.975C17.575 13.2583 17.4833 13.4917 17.3 13.675L12.7 18.275C12.6 18.375 12.4917 18.446 12.375 18.488C12.2583 18.5293 12.1333 18.55 12 18.55C11.8667 18.55 11.7417 18.5293 11.625 18.488C11.5083 18.446 11.4 18.375 11.3 18.275L6.70001 13.675C6.51668 13.4917 6.42501 13.2583 6.42501 12.975C6.42501 12.6917 6.51668 12.4583 6.70001 12.275Z"
                fill="currentColor"
              ></path>
              <path
                d="M6.70001 5.72501C6.88335 5.54168 7.11668 5.45001 7.40001 5.45001C7.68335 5.45001 7.91668 5.54168 8.10001 5.72501L12 9.62501L15.9 5.72501C16.0833 5.54168 16.3167 5.45001 16.6 5.45001C16.8833 5.45001 17.1167 5.54168 17.3 5.72501C17.4833 5.90835 17.575 6.14168 17.575 6.42501C17.575 6.70834 17.4833 6.94168 17.3 7.12501L12.7 11.725C12.6 11.825 12.4917 11.896 12.375 11.938C12.2583 11.9793 12.1333 12 12 12C11.8667 12 11.7417 11.9793 11.625 11.938C11.5083 11.896 11.4 11.825 11.3 11.725L6.70001 7.12501C6.51668 6.94168 6.42501 6.70834 6.42501 6.42501C6.42501 6.14168 6.51668 5.90834 6.70001 5.72501Z"
                fill="currentColor"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
