import { useTranslation } from "react-i18next";
import { getText } from "../../../../utils/util";
import { financeApi } from "@/api/finance-api";
import { useState, useEffect } from "react";

export default function ExtractOrderList() {
  const { t: translate } = useTranslation();
  const uid = localStorage.getItem("uid");
  const [localTxList, setLocalTxList] = useState([]);


  const loadListPcpagesData = async () => {
    const data = await financeApi.listPcpage({ uid });
    if (data.ok) {
      setLocalTxList(data.data);
    }
  };

  useEffect(() => {
    loadListPcpagesData();
  }, []);

  return (
    <div className="max-w-2xl mx-auto p-4">
      <ul className="space-y-4">
        {localTxList.map((cz) => (
          <li key={cz.id} className="bg-white rounded-lg shadow p-4 flex justify-between items-center hover:shadow-md transition-shadow">
            <div className="flex-1">
              <h1 className="text-xl font-bold text-gray-900">
                {cz.currenyNum} {cz.currenyName?.toUpperCase()}
              </h1>
              <div className="mt-1">
                <span className="text-sm text-gray-500">{cz.addtime}</span>
              </div>
            </div>
            <div>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                cz.status === 1 
                  ? 'bg-yellow-100 text-yellow-800' // 待审查
                  : cz.status === 2 
                  ? 'bg-green-100 text-green-800'   // 已完成
                  : 'bg-red-100 text-red-800'       // 失败
              }`}>
                {translate(
                  getText(
                    cz.status === 1 
                      ? "待審查" 
                      : cz.status === 2 
                      ? "已完成" 
                      : "失敗"
                  )
                )}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
} 