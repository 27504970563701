import React from "react";

interface BankNoteProps {
  className?: string;
}

const BankNote: React.FC<BankNoteProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.544 6.072C10.087 6.524 8.551 7 7 7a14.4 14.4 0 0 1-2.72-.258c-.529-.102-.88-.169-1.134-.202l-.125-.014-.006.078C3 6.819 3 7.12 3 7.589v10.828l.007.01.007.011C3.535 18.658 4.713 19 7 19c1.837 0 3.462-.457 5.08-.956l.376-.116C13.913 17.476 15.449 17 17 17c1.027 0 1.946.11 2.72.258.529.102.88.169 1.134.202q.074.01.125.014l.006-.078c.015-.215.015-.516.015-.985V5.583l-.014-.021C20.465 5.34 19.287 5 17 5c-1.837 0-3.462.458-5.08.956zm9.553 11.408h.004zM2.903 6.52h-.004zm8.428-2.476C12.96 3.542 14.837 3 17 3c2.546 0 4.016.389 4.836.75l.008.004c.043.019.142.062.251.134.111.073.204.155.286.233a2 2 0 0 1 .287.36c.056.086.154.244.213.407.12.33.12.564.119.761v10.796c0 .425 0 .794-.02 1.084-.017.27-.058.649-.253.983a2 2 0 0 1-.447.541 2 2 0 0 1-.616.337c-.379.134-.793.09-1.073.053-.318-.042-.728-.121-1.216-.215l-.032-.006A12.4 12.4 0 0 0 17 19c-1.235 0-2.486.386-4.026.862l-.305.094C11.04 20.458 9.163 21 7 21c-2.546 0-4.016-.389-4.836-.75l-.008-.004c-.043-.019-.142-.062-.251-.134a2 2 0 0 1-.286-.233 2 2 0 0 1-.287-.36 2 2 0 0 1-.213-.407c-.12-.33-.12-.564-.119-.761V7.555c0-.425 0-.794.02-1.084.017-.27.058-.649.253-.983.106-.182.244-.374.447-.541s.418-.268.616-.337c.379-.134.793-.09 1.072-.053.319.042.729.121 1.217.215l.032.006A12.4 12.4 0 0 0 7 5c1.235 0 2.486-.386 4.026-.862zM18 8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1m-6 2.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M8.5 12a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0M6 10a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1"
      fill="currentColor"
    />
  </svg>
);

export default BankNote;
