import { getLogo } from '@utils/get-logo';
import { CoinListData } from './types';
import { useCtmarketlist } from '@hooks/useCtmarketlist';
import { useState } from 'react';
import { BottomSheet } from '@/components/bottom-sheet';
import { MarketList } from './market-list';
import { useTranslation } from 'react-i18next';
import { getText } from '@utils/util';

export const TopInfo = ({
  coinname,
  coinListData,
  isFollowMode,
  setIsFollowMode,
}: {
  coinname: string;
  coinListData: CoinListData;
  isFollowMode: boolean;
  setIsFollowMode: (value: boolean) => void;
}) => {
  const currentCoin = coinListData[coinname];
  const { ctmarketlist } = useCtmarketlist();
  const [showSheet, setShowSheet] = useState(false);
  const merchandiser = localStorage.getItem('merchandiser');
  const { t: translate } = useTranslation();

  if (!currentCoin) return null;

  console.log(4, currentCoin);

  return (
    <>
      <div className="css-msepic egbmhdk3 mb-4">
        <div className="col-span-2 md:hidden flex items-center justify-between text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main gap-2 px-4">
          <div className="flex gap-2 items-center">
            <div>
              <img
                alt="Bitcoin (BTC) Logo"
                loading="lazy"
                width={40}
                height={40}
                decoding="async"
                data-nimg={1}
                src={getLogo(coinname, ctmarketlist)}
                style={{ color: 'transparent' }}
              />
            </div>
            <div className="title-css-info">{coinname?.toUpperCase()}/USDT</div>
          </div>
          <div className="flex items-center gap-2">
          {merchandiser == 1 && (<div className="flex items-center gap-1">
              <span className="text-xs">
                {isFollowMode
                  ? translate(getText('跟单'))
                  : translate(getText('合约'))}
              </span>
              <button
                className="w-12 h-6 rounded-full relative transition-colors duration-300 ease-in-out"
                style={{
                  backgroundColor: isFollowMode ? '#00b8b9' : '#e5e7eb',
                }}
                onClick={() => setIsFollowMode(!isFollowMode)}
              >
                <div
                  className="w-5 h-5 rounded-full bg-white absolute top-0.5 transition-transform duration-300 ease-in-out"
                  style={{
                    left: '2px',
                    transform: isFollowMode
                      ? 'translateX(24px)'
                      : 'translateX(0)',
                  }}
                />
              </button>
            </div>)}
            <button
              className="px-3 py-1 rounded-full text-sm text-[#00b8b9] border border-[#00b8b9] title-css-info-5x"
              onClick={() => setShowSheet(true)}
            >
              Market
            </button>
          </div>
        </div>
        <div className="css-1bin0ik egbmhdk2 !text-[10px]">
          <span className="css-1ddp69g egbmhdk1 !text-[10px]">Last Price</span>
          <span className="css-b5xy2g egbmhdk0 !text-[12px]">
            {currentCoin.close}
            <div className="css-8irbms em8wip62">
              <div className="css-6deuz6 em8wip61">
                <div
                  data-testid="reku-icon"
                  data-name="icon-ArrowSlantDown"
                  className="css-17x35y8 e1d9s5l60"
                ></div>
                <div
                  className={`!title-6 css-1ys42qh em8wip60 !text-[10px] ${
                    currentCoin.close < currentCoin.open
                      ? '!text-red-500'
                      : '!text-green-500'
                  }`}
                >
                  {currentCoin.close < currentCoin.open ? '' : '+'}
                  {currentCoin.close &&
                    (
                      ((currentCoin.close - currentCoin.open) /
                        currentCoin.open) *
                      100
                    ).toFixed(2)}
                  %
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="css-1bin0ik egbmhdk2">
          <span className="css-1ddp69g egbmhdk1 !text-[10px]">24h High</span>
          <span className="css-b5xy2g egbmhdk0 !text-[12px]">
            {currentCoin.high}
          </span>
        </div>
        <div className="css-1bin0ik egbmhdk2">
          <span className="css-1ddp69g egbmhdk1 !text-[10px]">24h Low</span>
          <span className="css-b5xy2g egbmhdk0 !text-[12px]">
            {currentCoin.low}
          </span>
        </div>
        <div className="css-1bin0ik egbmhdk2">
          <span className="css-1ddp69g egbmhdk1 !text-[10px]">24h Volume</span>
          <span className="css-b5xy2g egbmhdk0 !text-[12px]">
            {currentCoin.volume}
          </span>
        </div>
      </div>

      <BottomSheet visible={showSheet} onClose={() => setShowSheet(false)}>
        <MarketList
          coinListData={coinListData}
          nowTab={coinname}
          uri="trade"
          handleClick={() => {
            setShowSheet(false);
          }}
        />
      </BottomSheet>
    </>
  );
};
