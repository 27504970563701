import { imageConfig } from "@config/config";
import { getText } from "@utils/util";

interface RechargeCardProps {
  data: {
    id: string | number;
    name: string;
    img: string;
    czline?: string;
  };
  translate: (text: string) => string;
  onCardClick: (id: string | number) => void;
}

const RechargeCard: React.FC<RechargeCardProps> = ({ data, translate, onCardClick }) => {
  return (
    <div
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:-translate-y-0.5 cursor-pointer mb-4 w-full sm:w-[48%] lg:w-[31%] xl:w-[23%]"
      onClick={() => onCardClick(data.id)}
    >
      <div className="p-3 md:p-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-3">
          <div className="flex items-center gap-2 sm:gap-3 w-full sm:w-auto">
            <img
              src={imageConfig.baseImageUrl + data.img}
              className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
              alt={data.name}
            />
            <div className="text-sm sm:text-base font-medium text-gray-800 break-all">
              {`${data.name.toUpperCase()}${
                data.czline ? `(${data.czline})`.toUpperCase() : ""
              }`}
            </div>
          </div>
          <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto gap-2">
            <span className="px-2 sm:px-3 py-1 sm:py-1.5 bg-gray-50 rounded-full text-xs sm:text-sm text-gray-600 hover:bg-gray-100 whitespace-nowrap">
              {translate(getText("前往購買"))}
            </span>
            <i className="fas fa-chevron-right text-gray-400"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RechargeCard; 