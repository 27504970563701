import { useState } from "react";
import classNames from "classnames";
// import { isWindows } from "react-device-detect";
import { css } from "@emotion/css";
import tw from "twin.macro";
import { Icons } from "@components/Icon";
import Input, { InputProps } from "./Input";

const baseInputCss = css`
  input[type="password"] {
    -webkit-text-security: disc;
    ${tw`!text-icon-primary dark:!text-dark-icon-primary`}
  }
`;

const inputCssMedium = css`
  ${baseInputCss}
  input[type="password"] {
    ${tw`!text-[16px] xl:!text-[28px] xl:!tracking-[-4px] mt-[-6px] xl:mt-[-2px]`}
  }
  @-moz-document url-prefix() {
    input[type="password"] {
      ${tw`!text-[16px] xl:!text-[26px] xl:!tracking-[3px] ml-1 mt-[-6px] xl:mt-0.5 font-["Arial"]`}
    }
  }
`;

const inputCssMediumWindows = css`
  ${baseInputCss}
  input[type="password"] {
    ${tw`!text-[16px] xl:!text-[28px] xl:!tracking-[-4px] mt-[-6px] xl:mt-[-2px]`}
  }
  @-moz-document url-prefix() {
    input[type="password"] {
      ${tw`!text-[14px] xl:!tracking-[4px] ml-1 mt-[-6px] xl:mt-0.5 font-["Arial"]`}
    }
  }
`;

const inputCssSmallSize = css`
  ${baseInputCss}
  input[type="password"] {
    ${tw`!text-[14px] xl:!text-[20px] xl:tracking-[-2px] mt-[-2px]`}
  }
  @-moz-document url-prefix() {
    input[type="password"] {
      ${tw`!text-[10px] mt-[0px] font-["Arial"]`}
    }
  }
`;

type InputPasswordProps = Omit<InputProps, "type" | "suffix">;
const InputPassword: React.FC<InputPasswordProps> = ({
  className,
  ...props
}: InputPasswordProps) => {
  const isWindows = true;
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const inputClassName =
    props.size === "sm"
      ? isWindows
        ? inputCssSmallSize
        : inputCssMedium
      : isWindows
      ? inputCssMediumWindows
      : inputCssMedium;
  return (
    <Input
      type={showPassword ? "text" : "password"}
      className={classNames(
        {
          [inputClassName]: props.value,
        },
        className
      )}
      suffix={
        <Icons
          className="cursor-pointer text-icon-contrast dark:text-dark-icon-contrast"
          icon={showPassword ? "EyeShow" : "EyeHide"}
          width={24}
          height={24}
          onClick={toggleShowPassword}
        />
      }
      size={props.size || "md"}
      {...props}
    />
  );
};

export default InputPassword;
