// src/pages/followup/components/PCFollowModal/index.tsx

import { Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { followApi } from '@/api/followup';
import { financeApi } from '@/api/finance-api';

interface Trader {
  id: string;
  name: string;
  avatar: string;
  strategy: string;
  followerCount: number;
  balance: number;
  availableBalance: number;
}

interface PCFollowModalProps {
  trader: Trader | null;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (amount: number) => Promise<void>;
  mockUserInfo: any;
}

export const PCFollowModal: React.FC<PCFollowModalProps> = ({
  trader,
  mockUserInfo,
  isVisible,
  onClose,
  onConfirm,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [usercoinData, setusercoinData] = useState({});

  const loadUserCoinData = async () => {
    const uid = localStorage.getItem('uid');
    const data = await financeApi.userCoin({ uid });
    if (data.ok) {
      setusercoinData(data.data);
    }
  };

  useEffect(() => {
    loadUserCoinData();
    return () => {};
  }, []);

  const handleConfirm = async () => {
    if (!amount || isSubmitting) return;

    setIsSubmitting(true);
    try {
      const addRes = await followApi.addFollow(trader.uid, Number(amount));
      if (addRes.code === 0) {
        message.success(t('操作成功'));
        setTimeout(() => {
          setAmount('');
          onClose();
          navigate('/followup/order');
        }, 500);
      } else {
        setIsSubmitting(false);
        message.warning(addRes.msg);
      }
    } catch (error) {
      console.error('Follow failed:', error);
      setIsSubmitting(false);
    }
  };

  if (!trader) return null;

  trader.availableBalance = mockUserInfo?.money || 0;
  const isAmountValid =
    Number(amount) > 0 && Number(amount) <= trader.availableBalance;
  const availableBalance = trader?.availableBalance ?? 0;

  const formatTraderName = (name: string) => {
    if (name.length <= 9) return name;
    return `${name.slice(0, 6)}...${name.slice(-5)}`;
  };

  return (
    <Modal
      title={t('跟单设置')}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={480}
      centered
    >
      <div className="py-6">
        {/* 交易员信息 */}
        <div className="flex items-center gap-4 mb-6">
          <img
            src={trader?.avatar || '/followup/2.png'}
            alt=""
            className="w-16 h-16 rounded-full object-cover ring-2 ring-gray-100"
          />
          <div>
            <div className="font-medium text-gray-900 text-sm mb-1">
              {formatTraderName(trader?.name)}
            </div>
            <div className="text-gray-500">{trader.strategy}</div>
          </div>
        </div>

        {/* 统计信息 */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <div className="text-center p-4 bg-gray-50 rounded-lg">
            <div className="font-medium text-gray-900">
              {trader?.dayCount || 39}
            </div>
            <div className="text-xs text-gray-500 mt-1">{t('带单天数')}</div>
          </div>
          <div className="text-center p-4 bg-gray-50 rounded-lg">
            <div className="font-medium text-gray-900">
              {trader?.followCount || 687}
            </div>
            <div className="text-xs text-gray-500 mt-1">{t('跟单人数')}</div>
          </div>
          <div className="text-center p-4 bg-gray-50 rounded-lg">
            <div className="font-medium text-gray-900">
              {trader?.profit || 0}
            </div>
            <div className="text-xs text-gray-500 mt-1">{t('收益(USDT)')}</div>
          </div>
        </div>

        {/* 输入金额区域 */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <span className="text-gray-700">{t('跟随交易员下单金额')}</span>
            <div className="text-right">
              <span className="text-xs text-gray-500">{t('可用')} </span>
              <span className="font-medium text-sm text-gray-900">
                {usercoinData?.usdt}
              </span>
              <span className="text-xs text-gray-500"> USDT</span>
            </div>
          </div>

          <div className="relative">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder={t('请输入金额')}
              disabled={trader.availableBalance <= 0}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
            />
            <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
              USDT
            </span>
          </div>
        </div>

        {/* 确认按钮 */}
        <button
          onClick={handleConfirm}
          disabled={!isAmountValid || isSubmitting}
          className={`w-full py-3 rounded-lg font-medium transition-all ${
            isAmountValid && !isSubmitting
              ? 'bg-[#4ea5ab] text-white hover:bg-[#03b989] active:bg-[#03a57a]'
              : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
        >
          {t('确认')}
        </button>
      </div>
    </Modal>
  );
};
