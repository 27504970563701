import { Toast } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { financeApi } from '../../api/finance-api';
import { getText } from '../../utils/util';
import { RekuLayout } from '@components/reku/layout/main';
import { userApi } from '@/api/user-api';
import { Eye, EyeOff } from 'lucide-react';

export default function PaymentPassword() {
  const { t: translate } = useTranslation();
  const uid = localStorage.getItem('uid');
  const [userInfo, setUserInfo] = useState({} as any);
  const [isEditing, setIsEditing] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      setUserInfo(data.data);
    }
  };

  const formatPassword = (pass) => {
    if (!pass) return '';
    const start = pass.substring(0, 3);
    const end = pass.substring(pass.length - 3);
    return `${start}...${end}`;
  };

  const togglePasswordVisibility = (field: 'old' | 'new' | 'confirm') => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async () => {
    if (!newPassword || !confirmPassword || newPassword !== confirmPassword) {
      Toast.show({ content: translate(getText('新密碼不一致')) });
      return;
    }

    if (newPassword.length < 6) {
      Toast.show({ content: translate(getText('密碼必須為6位數以上')) });
      return;
    }

    if (userInfo.paypassword && !oldPassword) {
      Toast.show({ content: translate(getText('請輸入舊密碼')) });
      return;
    }

    if (userInfo.paypassword && oldPassword !== userInfo.paypassword) {
      Toast.show({ content: translate(getText('舊密碼錯誤')) });
      return;
    }

    // 显示加载提示
    const handler = Toast.show({
      icon: 'loading',
      content: translate(getText('加載中...')),
      duration: 0, // 持续时间为0，表示不自动关闭
    });

    try {
      const data = await userApi.payPassword({
        userCode: userInfo.userCode || '',
        passwd: newPassword,
      });
      console.log('faith=============data', data);

      // 关闭加载提示
      handler.close();

      if (data.ok) {
        Toast.show({ content: translate(getText('設置成功')) });
        loadUserInfoData();
        resetForm();
      } else {
        Toast.show({ content: translate(getText('設置失敗')) });
      }
    } catch (error) {
      Toast.show({ content: translate(getText('系統錯誤')) });
    }
  };

  const resetForm = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsEditing(false);
    setShowPasswords({
      old: false,
      new: false,
      confirm: false,
    });
  };

  useEffect(() => {
    loadUserInfoData();
  }, []);

  return (
    <div className="page">
      <RekuLayout title={translate(getText('設置支付密碼'))}>
        <div className="p-4 space-y-4">
          {userInfo.paypassword ? (
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-gray-600">
                    {translate(getText('當前密碼'))}
                  </p>
                  <p className="text-lg font-medium">
                    {'******'}
                    {/* {formatPassword(userInfo.paypassword)} */}
                  </p>
                </div>
                {!isEditing && (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="px-4 py-2 text-[#4da4aa] border border-[#4da4aa] rounded-lg hover:bg-[#4da4aa]/10"
                  >
                    {translate(getText('修改密碼'))}
                  </button>
                )}
              </div>
            </div>
          ) : null}

          {(!userInfo.paypassword || isEditing) && (
            <div className="space-y-4">
              {userInfo.paypassword && (
                <div className="space-y-2">
                  <p className="text-sm text-gray-600">
                    {translate(getText('舊密碼'))}
                  </p>
                  <div className="relative">
                    <input
                      type={showPasswords.old ? 'text' : 'password'}
                      maxLength={40}
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4da4aa] pr-10"
                      placeholder={translate(getText('請輸入舊密碼'))}
                    />
                    <button
                      type="button"
                      onClick={() => togglePasswordVisibility('old')}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      {showPasswords.old ? (
                        <EyeOff className="w-5 h-5" />
                      ) : (
                        <Eye className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <p className="text-sm text-gray-600">
                  {translate(getText('新密碼'))}
                </p>
                <div className="relative">
                  <input
                    type={showPasswords.new ? 'text' : 'password'}
                    maxLength={40}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4da4aa] pr-10"
                    placeholder={translate(getText('輸入新密碼'))}
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('new')}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    {showPasswords.new ? (
                      <EyeOff className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>

              <div className="space-y-2">
                <p className="text-sm text-gray-600">
                  {translate(getText('確認新密碼'))}
                </p>
                <div className="relative">
                  <input
                    type={showPasswords.confirm ? 'text' : 'password'}
                    maxLength={40}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4da4aa] pr-10"
                    placeholder={translate(getText('請再次輸入新密碼'))}
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('confirm')}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    {showPasswords.confirm ? (
                      <EyeOff className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>

              <div className="flex space-x-4">
                {isEditing && (
                  <button
                    onClick={resetForm}
                    className="flex-1 py-3 border border-gray-300 rounded-lg font-medium hover:bg-gray-50 transition-colors"
                  >
                    {translate(getText('取消'))}
                  </button>
                )}
                <button
                  onClick={handleSubmit}
                  className="flex-1 bg-[#4da4aa] text-white py-3 rounded-lg font-medium hover:bg-[#4da4aa]/90 transition-colors"
                >
                  {translate(getText(isEditing ? '保存修改' : '保存修改'))}
                </button>
              </div>
            </div>
          )}
        </div>
      </RekuLayout>
    </div>
  );
}
