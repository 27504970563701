import { Toast } from 'antd-mobile';
import { Image, message, Select, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { imageConfig } from '../../../../config/config';
import { useTranslation } from 'react-i18next';
import { getText } from '../../../../utils/util';

export default function Info({ userInfo, sendCardsc }) {
  const navigate = useNavigate();
  const [cardsc, setCardsc] = useState('');
  const [rztype, setRztype] = useState(1);
  const { t: translate } = useTranslation();
  const [fileNowName, setfileNowName] = useState('');

  //点击
  const preview = (name) => {
    setfileNowName(name);
  };
  //上传
  const handleChange = (info) => {
    if (info.file.status === 'done') {
      if (info.file.response && info.file.response.ok) {
        Toast.show({
          content: `${info.file.name} ${translate(getText('上傳成功'))}`,
        });
        const data = info.file.response;
        if (fileNowName === 'cardsc') {
          setCardsc(data.data);
        }
      } else {
        Toast.show({ content: translate(getText(`上传失败`)) });
      }
    } else if (info.file.status === 'error') {
      Toast.show({ content: translate(getText(`上传失败`)) });
    }
  };
  const reloadData = () => {
    setCardsc('');
    setRztype(1);
  };
  useEffect(() => {
    setCardsc(userInfo?.cardsc);
  }, [userInfo]);
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-2xl shadow-sm p-6 md:p-8">
        {/* 标题区域 */}
        <div className="mb-8">
          <h2 className="text-xl md:text-2xl font-medium text-gray-900">
            {translate(getText('證件手持照片'))}
          </h2>
        </div>

        {/* 上传区域 - 调整尺寸和比例 */}
        <div className="mb-8">
          <div className="w-full max-w-2xl mx-auto">
            {' '}
            {/* 增加最大宽度限制并居中 */}
            <Upload
              name="file"
              accept="image/*"
              showUploadList={false}
              action={imageConfig.uploadUrl}
              onChange={handleChange}
            >
              <div
                onClick={() => {
                  preview('cardsc');
                }}
                className="cursor-pointer"
              >
                {cardsc ? (
                  // 已上传图片显示 - 调整高度
                  <div className="relative w-full aspect-[16/9] rounded-xl overflow-hidden border-2 border-gray-200 hover:border-blue-500 transition-colors">
                    <img
                      src={imageConfig.baseImageUrl + cardsc}
                      className="w-full h-full object-contain bg-gray-50" // 修改为 object-contain 并添加背景色
                    />
                  </div>
                ) : (
                  // 上传占位区域 - 调整高度
                  <div className="relative w-full aspect-[16/9] rounded-xl border-2 border-dashed border-gray-300 hover:border-blue-500 transition-colors bg-gray-50 flex flex-col items-center justify-center">
                    <img
                      src="/shouchi.jpg"
                      className="w-32 h-32 mb-4 opacity-75" // 增加图标尺寸
                    />
                    <div className="text-center">
                      <span className="text-gray-600 text-lg">
                        {' '}
                        {/* 增加文字大小 */}
                        {translate(getText('證件手持照片'))}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Upload>
          </div>
        </div>

        {/* 提交按钮 - 调整宽度 */}
        <div className="flex justify-center">
          <button
            className={`
              w-full md:w-80 py-4 rounded-xl text-center font-medium text-lg
              transition-all duration-200
              ${
                !cardsc
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 shadow-sm'
              }
            `}
            onClick={() => {
              if (!cardsc) {
                return;
              }
              sendCardsc({
                ...userInfo,
                cardsc,
              });
              reloadData();
            }}
          >
            {translate(getText('提交'))}
          </button>
        </div>
      </div>
    </div>
  );
}
