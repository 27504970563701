import { imageConfig } from '@config/config';
import { useCompanyData } from '@hooks/index';
import { message } from 'antd';

export const Logo = (props: { className?: string }) => {
  const companyData = useCompanyData();
  console.log('faith=============companyData', companyData);
  return (
    <div className={props.className}>
      <img
        className="w-full h-full"
        src={imageConfig.baseImageUrl + companyData?.companyLogo}
        alt=""
      />
    </div>
  );
};
