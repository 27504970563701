import { useNavigate } from '@hooks/index';
import { convertToSeconds, getText } from '@utils/util';
import { Modal, Toast } from 'antd-mobile';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function OrderBox({
  nowTab,
  type,
  setType,
  isShowOrder,
  setIsShowOrder,
  coinListData,
  index,
  ctmarketlist,
  userInfo,
  mockUserInfo,
  buyCoin,
  hysetInfo,
  setyqsy,
  zq,
  isFollowMode,
}) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [type2, setType2] = useState(1);
  const [num, setNum] = useState('');
  const [hyTimes, setHyTimes] = useState([]);
  const [hyTzeds, setHyTzeds] = useState([]);
  const [hyYkbls, setHyYkbls] = useState([]);
  const [hyTzbls, setHyTzbls] = useState([]);
  let [minNum, setminNum] = useState(100);
  const [cykbl, setcykbl] = useState(100);
  const [isUse, setIsUse] = useState(true);

  const reset = () => {
    // Reset all state values to defaults
    setNum('');
    setminNum(100);
    setcykbl(100);
    setIsUse(true);
  };
  const propertyType = localStorage.getItem('propertyType');
  const [selectTimes, setSelectTimes] = useState([]);
  //滚动
  const [scrollDistance, setScrollDistance] = useState(0);
  const divRef = useRef(null);
  const [selectIndex, setselectIndex] = useState(1);
  //加载节点
  //百分比
  const [bfbIndex, setbfbIndex] = useState(0);
  const getNodes = () => {
    if (!hyTimes) {
      return '';
    }
    const nodes = [];
    for (let index = 0; index < hyTimes.length; index++) {
      let hyTime = hyTimes[index];
      let hyTzed = hyTzeds[index];
      let cykbl = hyYkbls[index];
      const node = (
        <div
          className={type2 === index + 1 ? 'orderpoplb-63' : 'orderpoplb-64'}
          onClick={() => {
            setType2(index + 1);
            setminNum(hyTzed);
            // setNum(hyTzed);
            setcykbl(cykbl);
          }}
        >
          {/* <div>{hyTime} </div> */}
          <div>{cykbl}%</div>
        </div>
      );
      nodes.push(node);
    }
    return nodes;
  };

  //合约投资比例
  const getNodesHytzbl = () => {
    if (!hyTzbls) {
      return '';
    }
    const nodes = [];
    for (let index = 0; index < hyTzbls.length; index++) {
      let hytzbl = hyTzbls[index];
      const node = (
        <div
          className={`${
            bfbIndex === index + 1
              ? 'orderpoplb-63 orderpoplb-item-act'
              : 'orderpoplb-64'
          } orderpoplb-item`}
          onClick={() => {
            setNum(
              parseFloat(
                `${
                  Number(propertyType) === 1
                    ? (userInfo?.usdt ?? 0) * 0.01 * hytzbl
                    : (mockUserInfo?.money ?? 0) * 0.01 * hytzbl
                }`
              )?.toFixed(2)
            );
            setbfbIndex(index + 1);
          }}
        >
          <div>{hytzbl}%</div>
        </div>
      );
      nodes.push(node);
    }
    return nodes;
  };

  const handleScroll = () => {
    const scrollTop = divRef.current.scrollTop;
    const scrollAmount = 41; // 每次滚动的基础值，可以根据需求调整
    const index = parseInt(`${scrollTop / scrollAmount}`);
    setselectIndex(index + 1);
    // // 计算滚动的目标位置，向上滚动时需要减去一个滚动基础值
    // const targetScrollTop = scrollTop-(index + 2)*scrollAmount;
    // // 使用动画效果滚动到目标位置
    // divRef.current.scrollTo({
    //   top: targetScrollTop,
    //   behavior: "smooth", // 可以选择 smooth 或者 auto
    // });
  };

  const getTimesStr = useCallback(() => {
    console.log(hyTimes);

    const timesStrArr = [];
    for (let index = 1; index <= 6; index++) {
      const errorTimeStr = localStorage.getItem('errortime');
      const errorTime = parseInt(errorTimeStr);
      let currentTime = new Date();
      // 将当前时间转换为 GMT-4
      currentTime = new Date(currentTime.getTime() + errorTime);
      //向下取整时间
      if (hyTimes) {
        const jgs = convertToSeconds(hyTimes[zq - 1]);
        currentTime = roundDownToNearestInterval(currentTime, jgs);
        currentTime.setSeconds(currentTime.getSeconds() + jgs * index);
      }
      // 增加时间
      // currentTime.setMinutes(currentTime.getMinutes() + addTime * index);
      // 格式化为 HH:mm 格式
      let hours = currentTime.getHours().toString().padStart(2, '0'); // 获取小时，并确保两位数格式
      let minutes = currentTime.getMinutes().toString().padStart(2, '0'); // 获取分钟，并确保两位数格式
      let formattedTime = `${hours}:${minutes}`;
      console.log('当前时间：', formattedTime, currentTime.getTime());
      timesStrArr.push({
        time: currentTime.getTime(),
        str: formattedTime,
      });
    }
    console.log(timesStrArr);
    setSelectTimes(timesStrArr);
  }, [zq, hyTimes]);

  function formatDate(date) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let hour = String(date.getHours()).padStart(2, '0');
    let minute = String(date.getMinutes()).padStart(2, '0');
    let second = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  //时间向下取值
  function roundDownToNearestInterval(currentDate, seconds) {
    let currentSeconds = currentDate.getSeconds();
    let currentMinutes = currentDate.getMinutes();
    let currentHours = currentDate.getHours();
    // 计算当前时间总秒数
    let totalSeconds =
      currentHours * 3600 + currentMinutes * 60 + currentSeconds;
    // 计算向下取整后的总秒数
    let roundedTotalSeconds = Math.floor(totalSeconds / seconds) * seconds;
    // 将向下取整后的总秒数转换为小时、分钟、秒
    let roundedHours = Math.floor(roundedTotalSeconds / 3600);
    let remainingSeconds = roundedTotalSeconds % 3600;
    let roundedMinutes = Math.floor(remainingSeconds / 60);
    let roundedSeconds = remainingSeconds % 60;
    // 创建一个新的 Date 对象，设置小时、分钟、秒数为向下取整后的值
    let roundedDate = new Date(currentDate);
    roundedDate.setHours(roundedHours);
    roundedDate.setMinutes(roundedMinutes);
    roundedDate.setSeconds(roundedSeconds);
    return roundedDate;
  }

  const refreshData = () => {
    setHyTimes(hysetInfo.hyTime);
    setHyTzeds(hysetInfo.hyTzed);
    setHyYkbls(hysetInfo.hyYkbl);
    setHyTzbls(hysetInfo.hyTzbl);
    setcykbl(hysetInfo.hyYkbl ? hysetInfo.hyYkbl[zq - 1] : 100);
    // setNum(hysetInfo.hyTzed ? hysetInfo.hyTzed[0] : 100);

    if (hysetInfo.hyYkbl) {
      const rangeStr = hysetInfo.hyYkbl ? hysetInfo.hyYkbl[zq - 1] : 100;

      if (!String(rangeStr).includes('-')) {
        setcykbl(rangeStr);
      } else {
        const [min, max] = rangeStr.split('-').map(Number);
        const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
        setcykbl(randomValue);
      }
    }

    console.log('faith=============hysetInfo.hyTzed', hysetInfo.hyTzed);
    setminNum(hysetInfo.hyTzed ? hysetInfo.hyTzed[zq - 1] : 100);
  };

  useEffect(() => {
    setHyTimes(hysetInfo.hyTime);
    setHyTzeds(hysetInfo.hyTzed);
    setHyYkbls(hysetInfo.hyYkbl);
    setHyTzbls(hysetInfo.hyTzbl);
    setcykbl(hysetInfo.hyYkbl ? hysetInfo.hyYkbl[zq - 1] : 100);

    if (hysetInfo.hyYkbl) {
      const rangeStr = hysetInfo.hyYkbl ? hysetInfo.hyYkbl[zq - 1] : 100;

      if (!String(rangeStr).includes('-')) {
        setcykbl(rangeStr);
      } else {
        const [min, max] = rangeStr.split('-').map(Number);
        const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
        setcykbl(randomValue);
      }
    }

    // setNum(hysetInfo.hyTzed ? hysetInfo.hyTzed[0] : 100);
    setminNum(hysetInfo.hyTzed ? hysetInfo.hyTzed[zq - 1] : 100);
  }, [hysetInfo, zq]);

  const handleOrderClick = () => {
    // setIsUse(false);
    // setTimeout(() => {
    //   setIsUse(true);
    // }, 3000);
    // const plantime = formatDate(
    //   new Date(selectTimes[selectIndex - 1]?.time)
    // );
    // let intplantime = selectTimes[selectIndex - 1]?.time;
    // intplantime = parseInt(`${intplantime / 1000}`);
    // buyCoin({
    //   ccoinname: `${nowTab.toUpperCase()}/USDT`,
    //   ctzed: num,
    //   ctzfx: type,
    //   ctime: hyTimes[zq - 1],
    //   cykbl,
    //   plantime,
    //   intplantime,
    //   isFollowMode,
    // });
    // setTimeout(() => {
    //   reset();
    // }, 1000);

    // end

    if (Number(num) < minNum) {
      Toast.show({
        content: `${translate(getText('最低投資額'))} ${minNum}`,
      });
      return;
    }
    if (!isUse) return;

    const plantime = formatDate(new Date(selectTimes[selectIndex - 1]?.time));
    let intplantime = selectTimes[selectIndex - 1]?.time;
    intplantime = parseInt(`${intplantime / 1000}`);

    Modal.show({
      title: (
        <div className="flex items-center justify-between relative w-full px-2">
          <div className="w-6"></div>
          <span className="font-bold">{nowTab.toUpperCase()}</span>
          <div
            className="w-6 h-6 flex items-center justify-center cursor-pointer"
            onClick={() => Modal.clear()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      ),
      content: (
        <div className="flex flex-col w-full px-4 pt-2 pb-3">
          <div className="text-center mb-4">
            <span className="text-xl text-teal-500">{plantime}</span>
          </div>

          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-gray-400 text-sm">
                {translate(getText('方向'))}
              </span>
              <span className="text-teal-500 text-sm">
                {type == 1
                  ? translate(getText('看漲'))
                  : translate(getText('看跌'))}
              </span>
            </div>

            <div className="flex justify-between items-center whitespace-nowrap">
              <span className="text-gray-400 text-sm">
                {translate(getText('投資金額'))}
              </span>
              <span className="text-gray-700 text-sm ml-2">{num} USDT</span>
            </div>
          </div>

          <div className="mt-4">
            <button
              style={{
                width: '100%',
                height: '44px',
                backgroundColor: '#00b578',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: '500',
              }}
              onClick={() => {
                Modal.clear();
                setIsUse(false);
                setTimeout(() => {
                  setIsUse(true);
                }, 3000);

                const plantime = formatDate(
                  new Date(selectTimes[selectIndex - 1]?.time)
                );
                let intplantime = selectTimes[selectIndex - 1]?.time;
                intplantime = parseInt(`${intplantime / 1000}`);

                buyCoin({
                  ccoinname: `${nowTab.toUpperCase()}/USDT`,
                  ctzed: num,
                  ctzfx: type,
                  ctime: hyTimes[zq - 1],
                  cykbl,
                  plantime,
                  intplantime,
                  isFollowMode,
                });
                refreshData();
                // setTimeout(() => {
                //   reset();
                // }, 1000);
              }}
            >
              {translate(getText('确认'))}
            </button>
          </div>
        </div>
      ),
      closeOnAction: true,
      actions: [],
      maskClosable: false,
    });
  };

  useEffect(() => {
    getTimesStr();
  }, [zq, isShowOrder, getTimesStr]);
  return (
    <div className="orderpoplb-7">
      <div className="orderpoplb-8">
        <div className="orderpoplb-9">
          <div className="orderpoplb-10">
            <div className="orderpoplb-11">
              {/* Time selection */}
              <div className="orderpoplb-12">
                {/* Fixed */}
                <div className="orderpoplb-22">
                  <div className="orderpoplb-23">
                    <div className="orderpoplb-24">
                      <div className="orderpoplb-25"></div>
                    </div>
                    <div className="orderpoplb-26">
                      <div className="orderpoplb-27"></div>
                    </div>
                  </div>
                </div>
                {/* Options */}
                <div
                  className="orderpoplb-18"
                  ref={divRef}
                  onScroll={handleScroll}
                >
                  <div className="orderpoplb-19">
                    <div className="orderpoplb-20">
                      <div className="orderpoplb-21"></div>
                      <div className="orderpoplb-28">
                        {selectTimes.map((item, index) => (
                          <div
                            className={`orderpoplb-29 ${
                              selectIndex == index + 1 ? 'orderpoplb-act' : ''
                            }`}
                          >
                            <div
                              className={
                                selectIndex == index + 1
                                  ? 'orderpoplb-30'
                                  : 'orderpoplb-36'
                              }
                            >
                              <span className="orderpoplb-31">{item.str}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="orderpoplb-47">
                <div className="orderpoplb-58">
                  <div className="orderpoplb-59">
                    <input
                      placeholder={translate(getText('最少10起買')).replace(
                        '10',
                        `${minNum}`
                      )}
                      type="number"
                      maxLength={140}
                      autoComplete="off"
                      className="orderpoplb-61"
                      min={minNum}
                      name="num"
                      value={num}
                      onChange={(e) => {
                        setbfbIndex(0);
                        let value = e.target.value;
                        value = value.match(/\d+\.?\d{0,2}/)?.[0] ?? '';
                        setNum(value ? value : '');
                        setTimeout(() => {
                          if (e.target.value && cykbl) {
                            setyqsy(parseInt(e.target.value) * cykbl * 0.01);
                          } else {
                            setyqsy(0);
                          }
                        }, 0);
                      }}
                    />
                  </div>
                </div>
                <div className="orderpoplb-62">{getNodesHytzbl()}</div>
                <div className="orderpoplb-48">
                  <div className="orderpoplb-49">
                    <div className="orderpoplb-50">
                      {translate(getText('最小'))}
                    </div>
                    <div className="orderpoplb-51">
                      <span className="orderpoplb-52">{minNum}</span>
                    </div>
                  </div>
                  <div className="orderpoplb-53">
                    <div className="orderpoplb-54">
                      {translate(getText('可用'))}
                    </div>
                    <div className="orderpoplb-55">
                      <span className="orderpoplb-56">
                        {Number(propertyType) === 1
                          ? userInfo?.usdt ?? 0
                          : mockUserInfo?.money ?? 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={type == 1 ? 'orderpoplb-67' : 'orderpoplb-67-1'}
                onClick={handleOrderClick}
              >
                {translate(getText(type == 1 ? '看涨' : '看跌'))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
