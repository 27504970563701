import { Toast } from 'antd-mobile';
import { Cell, Dialog } from 'react-vant';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { c2cApi } from '../../api/c2c-api';
import { coinApi } from '../../api/coin-api';
import { currencyApi } from '../../api/currency-api';
import { financeApi } from '../../api/finance-api';
import TopBar from '../../components/topBar';
import TopBar2 from '../../components/topBar2';
import { getText } from '../../utils/util';
import CenterPage from './components/centerPage';
import C2CCKTS from './components/c2cckts';
import C2CCKTS2 from './components/c2cckts2';
import C2CCK from '../c2cckcenter/components/c2cck';
import { Header } from '@components/header';
import { useLocation } from 'react-router-dom';
import { RekuLayout } from '@components/reku/layout';

export default function RechargeList() {
  const navigate = useNavigate();
  const uid = localStorage.getItem('uid');
  const { t: translate } = useTranslation();
  const [coinList, setCoinList] = useState([] as any[]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const coin = queryParams.get('coin');
  //加载种类
  const loadCoinData = async () => {
    console.log('faith=============111213312321312312321312');
    const data = await coinApi.list();
    console.log('faith=============setCoinList-setCoinList', data);
    if (data.ok) {
      const filteredData = data.data.filter((coinItem) =>
        coinItem.name.toLowerCase().includes(coin?.toLowerCase())
      );
      if (filteredData.length > 0) {
        filteredData.sort((a, b) => a.sort - b.sort);
        setCoinList(filteredData);
      } else {
        setCoinList([]);
      }
    }
  };

  useEffect(() => {
    loadCoinData();
  }, []);
  return (
    <div className="page">
      <RekuLayout
        title={
          <div className={'flex justify-between items-center'}>
            <div className="font-semibold">{translate(getText('入金'))}</div>
            <div>
              <button
                className={'text-blue-500'}
                onClick={() => navigate('/recharge')}
              >
                {translate(getText('充值明细'))}
              </button>
            </div>
          </div>
        }
      >
        <CenterPage coinList={coinList} />
      </RekuLayout>
    </div>
  );
}
