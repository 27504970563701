import { useState, useEffect } from "react";
import { userApi } from "@/api/user-api";

interface UserInfo {
  id: number;
  username: string;
  phone: string;
  type: number;
  password: string;
  paypassword: string;
  cardzm: string;
  cardfm: string;
  rzstatus: number;
  level: number;
  invit1: string;
  invit2: string;
  invit3: string;
  path: string;
  logins: number;
  addip: string;
  addr: string;
  addtime: number;
  endtime: number;
  lgtime: null | string;
  loginip: null | string;
  loginaddr: null | string;
  logintime: string;
  rztime: number;
  rzuptime: null | string;
  status: number;
  txstate: number;
  invit: string;
  stoptime: null | string;
  isAgent: number;
  areaCode: string;
  realName: string;
  area: null | string;
  rztype: string;
  buyOn: number;
  lang: null | string;
  userType: number;
  jifen: number;
  codeAmount: number;
  depatmentId: number;
  companyId: number;
  userCode: string;
  grado: number;
  token: null | string;
  money: null | number;
  winOrLose: number;
  language: null | string;
  recharge: null | number;
  myzc: null | number;
  twUserKuangji: null | any;
  cardsc?: string | null;
}
export function useUserInfo() {
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadUserInfoData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await userApi.userInfo();
      if (data.ok) {
        setUserInfo(data.data);
      } else {
        setError(data.msg || "获取用户信息失败");
      }
    } catch (err) {
      setError("获取用户信息时发生错误");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUserInfoData();
  }, []);

  return { userInfo, loading, error, loadUserInfoData };
}
