import { Badge } from "antd";
import { Card, Toast } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getText } from "../../../../utils/util";
import "./index.css";
import QRCode from "qrcodejs2";
import copy from "copy-to-clipboard";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";

export default function CenterPage({ userInfo, teamInfo, teamSets }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [userLV, setuserLV] = useState("");
  const la = localStorage.getItem("i18n");
  const origin = window.location.origin;
  const baseUrl = "https://tzwza.com";

  const creatQrCode = () => {
    let text = `${baseUrl}/register?invit=${userInfo?.invit}&ref=${origin}`;
    document.getElementById("qrcode").innerHTML = "";
    const qrcode = new QRCode(document.getElementById("qrcode"), {
      text: text, //二维码内容字符串
      width: 130, //图像宽度
      height: 130, //图像高度
      colorDark: "#000000", //二维码前景色
      colorLight: "#ffffff", //二维码背景色
      correctLevel: QRCode.CorrectLevel.H, //容错级别
    });
  };

  // 函数来保存二维码
  async function saveQRCode() {
    const canvas = await html2canvas(document.getElementById("qrcode"));
    // 将canvas转换为图片数据URL
    const imageDataUrl = canvas.toDataURL("image/png");
    // 创建一个隐藏的a标签来触发下载
    const link = document.createElement("a");
    link.href = imageDataUrl;
    link.download = "qrcode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //获取
  const getUserLV = () => {
    //排序
    teamSets.sort(function (a, b) {
      return a.sort - b.sort;
    });
    for (const teamSet of teamSets) {
      //判断等级
      if (
        teamInfo?.numCount >= teamSet?.min &&
        teamInfo?.numCount <= teamSet?.max
      ) {
        setuserLV(teamSet?.name);
        break;
      }
    }
  };
  //团队配置
  const getTeamSetArray = () => {
    const nodes = [];
    //排序
    teamSets.sort(function (a, b) {
      return a.sort - b.sort;
    });
    for (const teamSet of teamSets) {
      //写入元素
      nodes.push(
        <div className="grid grid-cols-2 p-3 hover:bg-gray-50 transition-colors">
          <div className="font-medium text-gray-600">{teamSet?.name}</div>
          <div className="font-medium text-gray-600">
            {teamSet?.min} ≤ N＜ {teamSet?.max}
          </div>
        </div>
      );
    }
    return nodes;
  };

  //团队信息
  const getTeamArray = () => {
    const oneTeamNum = teamInfo?.oneTeam?.length;
    const twoTeamNum = teamInfo?.twoTeam?.length;
    const threeTeamNum = teamInfo?.threeTeam?.length;
    const nodes = [];
    //排序
    teamSets.sort(function (a, b) {
      return a.sort - b.sort;
    });
    for (const teamSet of teamSets) {
      //写入元素
      nodes.push(
        <div className="grid grid-cols-4 bg-gray-50 p-3 rounded-t-lg">
          <div className="font-medium text-gray-600">{teamSet?.name}</div>
          <div className="font-medium text-gray-600">
            {parseInt((oneTeamNum / teamSet?.max) * 100) > 100
              ? 100
              : parseInt((oneTeamNum / teamSet?.max) * 100)}
            %
          </div>
          <div className="font-medium text-gray-600">
            {parseInt((twoTeamNum / teamSet?.max) * 100) > 100
              ? 100
              : parseInt((twoTeamNum / teamSet?.max) * 100)}
            %
          </div>
          <div className="font-medium text-gray-600">
            {parseInt((threeTeamNum / teamSet?.max) * 100) > 100
              ? 100
              : parseInt((threeTeamNum / teamSet?.max) * 100)}
            %
          </div>
        </div>
      );
    }
    return nodes;
  };
  const handleCopy = (value) => {
    if (copy(value)) {
      Toast.show({ content: translate(getText("成功")) });
    }
  };
  useEffect(() => {
    creatQrCode();
  }, [userInfo]);
  useEffect(() => {
    getUserLV();
  }, [teamSets, teamInfo]);
  return (
    <div className="max-w-4xl mx-auto px-4 py-6">
      {/* 二维码容器 */}
      <div className="bg-white rounded-2xl shadow-sm p-6 mb-6">
        <div className="flex justify-center">
          {/* 二维码外层容器 - 添加固定尺寸和居中 */}
          <div className="w-[150px] h-[150px] bg-white p-2 rounded-xl shadow-sm border border-gray-100 flex items-center justify-center">
            {/* 二维码本身 - 确保不会被拉伸 */}
            <div
              id="qrcode"
              className="w-[130px] h-[130px] flex items-center justify-center"
            ></div>
          </div>
        </div>

        {/* 邀请信息 */}
        <div className="mt-6 space-y-4">
          <div className="bg-gray-50 rounded-xl p-4">
            <div
              className="flex items-center justify-between cursor-pointer hover:bg-gray-100 p-3 rounded-lg transition-all"
              onClick={() => handleCopy(`${userInfo?.invit}`)}
            >
              <div className="flex items-center space-x-2">
                <span className="text-gray-600">
                  {translate(getText("我的邀请码"))}:
                </span>
                <span className="font-medium text-gray-900">
                  {userInfo?.invit}
                </span>
              </div>
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </div>

            <div
              className="flex items-center justify-between cursor-pointer hover:bg-gray-100 p-3 rounded-lg transition-all"
              onClick={() =>
                handleCopy(
                  `${baseUrl}/register?invit=${userInfo?.invit}&ref=${origin}`
                )
              }
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                <span className="text-gray-600">
                  {translate(getText("我的邀请链接"))}:
                </span>
                <span className="text-sm text-gray-900 break-all">
                  {baseUrl}/register?invit={userInfo?.invit}&&ref={origin}
                </span>
              </div>
              <svg
                className="w-5 h-5 text-gray-400 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* 保存按钮 */}
        <button
          className="w-full mt-4 py-2.5 bg-[rgb(4,207,153)] text-white rounded-lg hover:bg-[rgb(4,187,133)] transition-colors"
          onClick={() => saveQRCode()}
        >
          {translate(getText("点击保存二维码"))}
        </button>
      </div>

      {/* 统计信息 */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
        <div className="bg-white p-4 rounded-xl shadow-sm">
          <div className="text-gray-600 mb-1">
            {translate(getText("推荐人数"))}
          </div>
          <div className="text-xl font-semibold">{teamInfo?.numCount}</div>
        </div>
        <div className="bg-white p-4 rounded-xl shadow-sm">
          <div className="text-gray-600 mb-1">
            {translate(getText("当前等级"))}
          </div>
          <div className="text-xl font-semibold">{userLV}</div>
        </div>
      </div>

      {/* 规则说明 */}
      <div className="bg-white rounded-2xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <span className="text-lg font-medium">
            {translate(getText("规则说明"))}
          </span>
          <svg
            className="w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>

        {/* 团队等级表格 */}
        <div className="mb-8">
          <div className="grid grid-cols-2 bg-gray-50 p-3 rounded-t-lg">
            <div className="font-medium text-gray-600">
              {translate(getText("团队等级"))}
            </div>
            <div className="font-medium text-gray-600">
              {translate(getText("要求"))}
            </div>
          </div>
          <div className="divide-y divide-gray-100">{getTeamSetArray()}</div>
        </div>

        {/* 团队信息表格 */}
        <div>
          <div className="grid grid-cols-4 bg-gray-50 p-3 rounded-t-lg">
            <div className="font-medium text-gray-600">
              {translate(getText("团队等级"))}
            </div>
            <div className="font-medium text-gray-600">
              {translate(getText("1级下级"))}
            </div>
            <div className="font-medium text-gray-600">
              {translate(getText("2级下级"))}
            </div>
            <div className="font-medium text-gray-600">
              {translate(getText("3级下级"))}
            </div>
          </div>
          <div className="divide-y divide-gray-100">{getTeamArray()}</div>
        </div>
      </div>
    </div>
  );
}
