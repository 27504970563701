import React, { useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "@/utils/use-media-query";
import { Link } from "@components/link";
import { Container } from "@components/container";
import { Button } from "@components/button";
import { Icons } from "@components/Icon";
import FeatureCrypto from "../svgx/FeatureCrypto";
import FeatureStock from "../svgx/FeatureStock";
import FeatureCryptoMockup from "./feature-crypto-mockup.webp";
import FeatureStockMockup from "./feature-stock-mockup.webp";
import FeatureCryptoMockupMobile from "./hero-mockup.webp";
import FeatureStockMockupMobile from "./hero-mockup.webp";
import { useTranslation } from "react-i18next";
import { Image } from "@components/image";
type PortfolioType = "crypto" | "us-stock";
const getImageMock = (type: PortfolioType, isDesktop: boolean) => {
  if (type === "crypto") {
    return isDesktop ? FeatureCryptoMockup : FeatureCryptoMockupMobile;
  }
  return isDesktop ? FeatureStockMockup : FeatureStockMockupMobile;
};
const MultiplePortfolio = () => {
  const { t } = useTranslation("home");
  const isDesktop = true;
  const [active, setActive] = useState<PortfolioType>("us-stock");
  const features = [
    {
      id: 1,
      type: "crypto",
      title: t("multiple_portfolio.crypto.title"),
      subtitle: t("multiple_portfolio.crypto.subtitle"),
      list: [
        t("multiple_portfolio.crypto.list.1"),
        t("multiple_portfolio.crypto.list.2"),
        t("multiple_portfolio.crypto.list.3"),
      ],
      cta: t("multiple_portfolio.crypto.cta"),
      href: "/markets/crypto",
    },
    {
      id: 2,
      type: "us-stock",
      title: t("multiple_portfolio.us_stock.title"),
      subtitle: t("multiple_portfolio.us_stock.subtitle"),
      list: [
        t("multiple_portfolio.us_stock.list.1"),
        t("multiple_portfolio.us_stock.list.2"),
        t("multiple_portfolio.us_stock.list.3"),
      ],
      cta: t("multiple_portfolio.us_stock.cta"),
      href: "/markets/saham-as",
    },
  ];
  return (
    <section className="bg-background-neutral-inverse dark:bg-dark-background-card-main">
      <Container className="py-10 xl:py-20">
        <h1 className="text-heading-md xl:text-heading-xl text-dark-text-neutral-onImageStrong text-center mb-8 xl:mb-12">
          {t("multiple_portfolio.title")}
        </h1>
        <div className="h-full xl:h-[400px] flex flex-col xl:flex-row gap-4 xl:gap-8">
          {features.map((item) => (
            <div
              key={item.id}
              className={classNames(
                "relative h-[248px] xl:!h-[unset] min-h-[248px] xl:min-h-[unset] xl:w-[400px] xl:min-w-[400px] flex flex-col xl:flex-row rounded-md overflow-hidden transition-all duration-700 xl:duration-1000 ease-in",
                {
                  "!h-[630px] xl:!w-full": active === item.type,
                }
              )}
              onClick={() => {
                setActive(item.type as PortfolioType);
              }}
            >
              <div
                className={classNames(
                  "absolute left-0 z-10 p-4 xl:p-6 flex flex-col justify-between flex-1",
                  "w-full min-h-[248px] h-[calc(100%_-_236px)] xl:w-[calc(100%_-_400px)] xl:min-w-[400px] xl:h-full",
                  {
                    "bg-background-subtle-teal dark:bg-dark-background-solid-teal":
                      item.type === "crypto",
                    "bg-background-subtle-purple dark:bg-dark-background-solid-purple":
                      item.type === "us-stock",
                  }
                )}
              >
                <div>
                  {item.type === "crypto" ? (
                    <FeatureCrypto className="w-8 h-8 xl:w-10 xl:h-10" />
                  ) : (
                    <FeatureStock className="w-8 h-8 xl:w-10 xl:h-10" />
                  )}
                  <h2 className="text-heading-md xl:text-heading-lg text-text-neutral-strong dark:text-dark-text-neutral-strong mt-2">
                    {item.title}
                  </h2>
                  <p
                    className={classNames(
                      "text-body-md xl:text-body-lg text-text-neutral-main dark:text-dark-text-neutral-main mt-2 opacity-0 transition-opacity duration-500 delay-400",
                      {
                        "!opacity-100": active === item.type,
                      }
                    )}
                  >
                    {item.subtitle}
                  </p>
                </div>
                <div>
                  <div className="relative xl:min-h-[72px]">
                    <p
                      className={classNames(
                        "absolute bottom-0 text-body-md xl:text-body-lg text-text-neutral-main dark:text-dark-text-neutral-main mt-4 opacity-100 transition-opacity duration-500 delay-400",
                        {
                          "!opacity-0": active === item.type,
                        }
                      )}
                    >
                      {item.subtitle}
                    </p>
                    <div
                      className={classNames(
                        "absolute bottom-0 flex flex-col gap-2 opacity-0 transition-opacity duration-500 delay-0",
                        {
                          "!delay-500 !opacity-100": active === item.type,
                        }
                      )}
                    >
                      {item.list.map((list) => (
                        <p
                          key={list}
                          className="flex items-center gap-2 text-body-md text-text-neutral-main dark:text-dark-text-neutral-main"
                        >
                          <Icons icon="CheckCircle" className="w-5 h-5" />
                          {list}
                        </p>
                      ))}
                    </div>
                  </div>

                  <Link href={item.href} className="xl:!w-fit">
                    <Button
                      size="sm"
                      variant={item.type === "crypto" ? "primary" : "secondary"}
                      block
                      className="xl:!h-10 xl:!w-fit xl:!text-md mt-4"
                    >
                      {item.cta}
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="w-full xl:w-[400px] h-[240px] xl:h-full absolute right-0 bottom-0 overflow-hidden">
                <Image
                  src={getImageMock(item.type as PortfolioType, isDesktop)}
                  width={400}
                  height={400}
                  className="w-full h-full object-cover object-top"
                  alt="image-feature"
                />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
export default MultiplePortfolio;
