import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'antd-mobile';
import { message } from 'antd';
import { followApi } from '@/api/followup';
import { Header } from '@components/header';
import { userApi } from '@/api/user-api';
import { financeApi } from '@/api/finance-api';
import { isMobile } from 'react-device-detect';

// Mock统计数据

const BecomeTrader: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const REQUIRED_BALANCE = 10000; // 需要的最低余额
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const [platformTitle, setPlatformTitle] = useState('');
  const [statsData, setStatsData] = useState<any>({});
  const [balance, setBalance] = useState<number>(100000);
  const [hasFollowing, setHasFollowing] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [requirements, setRequirements] = useState({
    hasBalance: false,
    hasNoFollowing: true,
  });

  const [mockUserInfo, setMockUserInfo] = useState<any>({});

  // 获取用户信息
  const getMockUserInfo = async () => {
    const uid = localStorage.getItem('uid');

    const userCoin = await financeApi.userCoin({ uid });
    if (userCoin.ok) {
      setBalance(userCoin.data.usdt);

      const useinfodata = await userApi.userInfo();

      const userdata = await followApi.getMcdUserInfo(uid);
      if (userdata.ok && useinfodata.ok) {
        updateRequirements(
          userCoin.data.usdt,
          userdata.data.starCount > 0 || useinfodata.data.merchandiser === 1
            ? true
            : false
        );
      }
    }
  };

  // 更新 requirements 的函数
  const updateRequirements = (newBalance: number, following: boolean) => {
    setRequirements({
      hasBalance: newBalance >= REQUIRED_BALANCE,
      hasNoFollowing: !following,
    });
  };

  // 当 balance 或 hasFollowing 改变时更新 requirements
  useEffect(() => {
    updateRequirements(balance, hasFollowing);
  }, [balance, hasFollowing]);

  useEffect(() => {
    const title = localStorage.getItem('title') || 'GQ';
    console.log('faith=============title', title);
    setPlatformTitle(title);
  }, []); // 组件挂载时获取一次

  // 刷新状态
  const refreshStatus = async () => {
    if (refreshing) return;

    try {
      setRefreshing(true);
      await getMockUserInfo();
    } catch (error) {
      message.error(t('刷新失敗'));
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    // 设置10秒定时器
    intervalIdRef.current = setInterval(() => {
      refreshStatus();
    }, 10000);

    // 组件卸载时清除定时器
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []); // 空依赖数组意味着只在组件挂载时设置定时器
  // 获取统计数据
  const getStatsData = async () => {
    const MOCK_STATS_DATA = {
      maxProfitRate: '40%', // 最高分润比例
      followCount: 4196, // 跟单总人次
      totalProfit: 5242440.75, // 总分润收入
      dailyAvgProfit: 12453.67, // 日均分润
      monthlyProfit: 373610.1, // 月度分润
    };

    try {
      // 请求接口
      setStatsData(MOCK_STATS_DATA);
    } catch (error) {
      console.error('获取统计数据失败:', error);
    }
  };

  // 组件加载时获取数据
  useEffect(() => {
    refreshStatus();
    getStatsData();
  }, []);

  const handleApply = async () => {
    if (!requirements.hasBalance || !requirements.hasNoFollowing) {
      return;
    }

    const result = await Dialog.confirm({
      content: t('確定要成為交易員嗎？'),
      confirmText: t('確定'),
      cancelText: t('取消'),
    });

    if (result) {
      try {
        const { code, msg } = await followApi.applyTrader();
        if (code === 0) {
          message.success(t('成為交易員成功'));
          navigate('/followup/btc'); // 成功后跳转
        } else {
          message.warning(msg);
        }
      } catch (error) {
        message.error(t('操作失敗，請稍後重試'));
      }
    }
  };

  return (
    <div className="px-4 pb-6 py-0 bg-gray-50 min-h-screen">
      <Header />

      {isMobile && (
        <>
          {/* 标题 */}
          <div className="flex items-center justify-between my-2">
            <div className="text-gray-500">
              <span
                className="cursor-pointer"
                onClick={() => navigate('/followup/btc')}
              >
                {t('跟單交易')}
              </span>
              {' > '}
              {t('成為交易員')}
            </div>
            <button
              onClick={() => navigate('/followup/btc')}
              className="px-3 py-1 flex items-center text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100 transition-colors duration-200"
            >
              &larr; {t('返回')}
            </button>
          </div>

          {/* 图标和标题 */}
          <div className="bg-white rounded-2xl p-6 text-center mb-2">
            <img
              src="/followup/become-trader-h5-logo-C24bo3d1.png"
              alt="Trader Icon"
              className="w-24 h-24 mx-auto mb-2"
            />
            <div className="text-sm font-bold mb-2">
              {`${t('成為')}${platformTitle}${t('帶單交易大師')}`}
            </div>
            <div className="text-gray-500 text-xs">
              {t('加入全球最大的跟單社區，賺取高額分潤收入')}
            </div>
          </div>

          {/* 统计数据 */}
          {/* 统计数据 */}
          <div className="flex gap-2 mb-2">
            <div className="flex-1 bg-white rounded-xl p-2.5 text-center">
              <div className="text-blue-500 text-[12px] font-[700]">
                {statsData?.maxProfitRate}
              </div>
              <div className="text-gray-500 text-xs mt-0.5">
                {t('分潤比例最高')}
              </div>
            </div>
            <div className="flex-1 bg-white rounded-xl p-2.5 text-center">
              <div className="text-blue-500 text-[12px] font-[700]">
                {statsData?.followCount?.toLocaleString()}
              </div>
              <div className="text-gray-500 text-xs mt-0.5">
                {t('跟單人次')}
              </div>
            </div>
            <div className="flex-1 bg-white rounded-xl p-2.5 text-center">
              <div className="text-blue-500 text-[12px] font-[700]">
                {statsData?.totalProfit?.toLocaleString()}
              </div>
              <div className="text-gray-500 text-xs mt-0.5">
                {t('分潤收入')}
              </div>
            </div>
          </div>

          {/* 资格审查 */}
          <div className="bg-white rounded-2xl p-4 mb-2">
            <div className="flex items-center justify-between mb-2">
              <div className="text-sm font-bold">
                {t('交易員申請資格')}{' '}
                <span className="mr-2 text-xs text-gray-500">
                  （{t('餘額')} {balance?.toLocaleString()} USDT）
                </span>
              </div>
            </div>

            <div className="flex items-center justify-between py-2 border-b border-gray-100">
              <div className="flex items-center gap-2">
                <div
                  className={
                    requirements.hasBalance ? 'text-green-500' : 'text-red-500'
                  }
                >
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    {requirements.hasBalance ? (
                      // 勾选图标
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    ) : (
                      // 警告图标
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                    )}
                  </svg>
                </div>
                <div
                  className={
                    requirements.hasBalance ? 'text-green-500' : 'text-gray-500'
                  }
                >
                  {t('跟單賬戶餘額')} ≥ {REQUIRED_BALANCE.toLocaleString()} USDT
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 py-2">
              <div
                className={
                  requirements.hasNoFollowing
                    ? 'text-green-500'
                    : 'text-red-500'
                }
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  {requirements.hasNoFollowing ? (
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  ) : (
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                  )}
                </svg>
              </div>
              <div
                className={
                  requirements.hasNoFollowing
                    ? 'text-green-500'
                    : 'text-gray-500'
                }
              >
                {t('當前沒有跟隨交易員')}
              </div>
            </div>
          </div>

          {/* 申请按钮 */}
          <button
            className={`w-full rounded-full py-3 mb-6 ${
              requirements.hasBalance && requirements.hasNoFollowing
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-500'
            }`}
            onClick={handleApply}
          >
            {t('申請成為交易員')}
          </button>

          {/* 权益列表 */}
          <div className="text-sm font-bold mb-2">{t('交易員權益')}</div>
          <div className="space-y-4">
            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                <img
                  src="/followup/111.png"
                  className="w-5 h-5 text-blue-500"
                  alt=""
                />
              </div>
              <div>
                <div className="font-bold mb-1">{t('高額分潤收益')}</div>
                <div className="text-xs text-gray-500">
                  {t('行業內最高帶單分潤收益，最高享 40% 盈利分潤')}
                </div>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                  <img
                    src="/followup/222.png"
                    className="w-5 h-5 text-blue-500"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="font-bold mb-1">{t('全球影響力')}</div>
                <div className="text-xs text-gray-500">
                  {t(
                    '進駐全球最大跟單社區，連接百萬跟單用戶，享譽全球100+國家和地區，構建個人品牌與影響力'
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                  <img
                    src="/followup/333.png"
                    className="w-5 h-5 text-blue-500"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="font-bold mb-1">{t('資源扶持')}</div>
                <div className="text-xs text-gray-500">
                  {t(
                    '平台社群互動，站內外流量雙向增長，跟單用戶官宣曝光，持續運營秘笈用戶'
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0">
                  <img
                    src="/followup/444.png"
                    className="w-5 h-5 text-blue-500"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="font-bold mb-1">{t('貼心服務')}</div>
                <div className="text-xs text-gray-500">
                  {t(
                    '交易員新人社群，專屬活動助力成長。7×24小時專屬客服，享受平台VIP待遇'
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!isMobile && (
        <div className="max-w-[1200px] mx-auto px-4 py-8">
          {/* 面包屑导航 */}
          <div className="flex items-center justify-between mb-8">
            <div className="text-gray-500">
              <span
                className="cursor-pointer hover:text-blue-500 transition-colors"
                onClick={() => navigate('/followup/btc')}
              >
                {t('跟單交易')}
              </span>
              {' > '}
              <span>{t('成為交易員')}</span>
            </div>
            <button
              onClick={() => navigate('/followup/btc')}
              className="text-gray-500 hover:text-blue-500 transition-colors"
            >
              ← {t('返回')}
            </button>
          </div>

          {/* 主要内容区域 */}
          <div className="flex gap-8">
            {/* 左侧内容 */}
            <div className="flex-1">
              {/* 顶部信息卡片 */}
              <div className="bg-[#111] text-white rounded-2xl p-12 mb-8">
                <div className="flex justify-between items-center">
                  <div className="max-w-[700px]">
                    {' '}
                    {/* 增加最大宽度 */}
                    <h1 className="text-4xl font-bold mb-2">
                      {`${t('成為')}${platformTitle}${t('帶單交易大師')}`}
                    </h1>
                    <p className="text-gray-300 text-sm mb-6">
                      {t('加入全球最大的跟單社區，賺取高額分潤收入')}
                    </p>
                    {/* 减小统计数据间距 */}
                    <div className="grid grid-cols-3 gap-4">
                      {' '}
                      {/* gap-8 改为 gap-4 */}
                      <div>
                        <div className="text-sm font-extrabold text-white leading-none mb-1">
                          {statsData?.maxProfitRate}%
                        </div>
                        <div className="text-gray-400 text-xs">
                          {t('分潤比例最高')}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-extrabold text-white leading-none mb-1">
                          {statsData?.followCount?.toLocaleString()}
                        </div>
                        <div className="text-gray-400 text-xs">
                          {t('跟單人次')}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-extrabold text-white whitespace-nowrap leading-none mb-1">
                          {statsData?.totalProfit?.toLocaleString()}
                        </div>
                        <div className="text-gray-400 text-xs">
                          {t('分潤收入')}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 右侧图标 */}
                  <img
                    src="/followup/become-trader-h5-logo-C24bo3d1.png"
                    alt="Trader Icon"
                    className="w-40 h-40 ml-4"
                  />
                </div>
              </div>

              {/* 资格审查卡片 */}
              <div className="bg-white rounded-2xl p-8 mb-8">
                <div className="flex items-center justify-between mb-6">
                  <div className="text-sm font-bold">
                    {t('交易員申請資格')}{' '}
                    <span className="ml-2 text-xs text-gray-500">
                      （{t('餘額')} {balance?.toLocaleString()} USDT）
                    </span>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-xl">
                    <div
                      className={
                        requirements.hasBalance
                          ? 'text-green-500'
                          : 'text-red-500'
                      }
                    >
                      <svg
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        {requirements.hasBalance ? (
                          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                        ) : (
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                        )}
                      </svg>
                    </div>
                    <div
                      className={`text-sm ${
                        requirements.hasBalance
                          ? 'text-green-500'
                          : 'text-gray-500'
                      }`}
                    >
                      {t('跟單賬戶餘額')} ≥ {REQUIRED_BALANCE.toLocaleString()}{' '}
                      USDT
                    </div>
                  </div>

                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-xl">
                    <div
                      className={
                        requirements.hasNoFollowing
                          ? 'text-green-500'
                          : 'text-red-500'
                      }
                    >
                      <svg
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        {requirements.hasNoFollowing ? (
                          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                        ) : (
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                        )}
                      </svg>
                    </div>
                    <div
                      className={`text-sm ${
                        requirements.hasNoFollowing
                          ? 'text-green-500'
                          : 'text-gray-500'
                      }`}
                    >
                      {t('當前沒有跟隨交易員')}
                    </div>
                  </div>
                </div>
              </div>

              {/* 申请按钮 */}
              <button
                className={`w-full rounded-xl py-4 text-sm font-medium transition-colors ${
                  requirements.hasBalance && requirements.hasNoFollowing
                    ? 'bg-[#4ea5ab] text-white hover:bg-[#458f94]'
                    : 'bg-gray-200 text-gray-500'
                }`}
                onClick={handleApply}
              >
                {t('申請成為交易員')}
              </button>
            </div>

            {/* 右侧权益列表 */}
            <div className="w-[400px]">
              <div className="bg-white rounded-2xl p-8">
                <h2 className="text-sm font-bold mb-8">{t('交易員權益')}</h2>
                <div className="space-y-8">
                  {/* 高额分润收益 */}
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-xl bg-[#ebfaf7] flex items-center justify-center flex-shrink-0">
                      <svg
                        className="w-6 h-6 text-[#4ea5ab]"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
                      </svg>
                    </div>
                    <div>
                      <div className="text-sm font-bold mb-2">
                        {t('高額分潤收益')}
                      </div>
                      <div className="text-gray-500">
                        {t('行業內最高帶單分潤收益，最高享 40% 盈利分潤')}
                      </div>
                    </div>
                  </div>

                  {/* 全球影响力 */}
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-xl bg-[#ebfaf7] flex items-center justify-center flex-shrink-0">
                      <svg
                        className="w-6 h-6 text-[#4ea5ab]"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
                      </svg>
                    </div>
                    <div>
                      <div className="text-sm font-bold mb-2">
                        {t('全球影響力')}
                      </div>
                      <div className="text-gray-500">
                        {t(
                          '進駐全球最大跟單社區，連接百萬跟單用戶，享譽全球100+國家和地區，構建個人品牌與影響力'
                        )}
                      </div>
                    </div>
                  </div>

                  {/* 资源扶持 */}
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-xl bg-[#ebfaf7] flex items-center justify-center flex-shrink-0">
                      <svg
                        className="w-6 h-6 text-[#4ea5ab]"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z" />
                      </svg>
                    </div>
                    <div>
                      <div className="text-sm font-bold mb-2">
                        {t('資源扶持')}
                      </div>
                      <div className="text-gray-500">
                        {t(
                          '平台社群互動，站內外流量雙向增長，跟單用戶官宣曝光，持續運營秘笈用戶'
                        )}
                      </div>
                    </div>
                  </div>

                  {/* 贴心服务 */}
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-xl bg-[#ebfaf7] flex items-center justify-center flex-shrink-0">
                      <svg
                        className="w-6 h-6 text-[#4ea5ab]"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                      </svg>
                    </div>
                    <div>
                      <div className="text-sm font-bold mb-2">
                        {t('貼心服務')}
                      </div>
                      <div className="text-gray-500">
                        {t(
                          '交易員新人社群，專屬活動助力成長。7×24小時專屬客服，享受平台VIP待遇'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BecomeTrader;
