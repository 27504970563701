// src/pages/followup/index.tsx
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FollowModal } from './components/FollowModal';
import './index.css';
// import { useNavigate } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { followApi } from '@/api/followup';
import { Divider, Footer, Skeleton } from 'antd-mobile';
import { debug } from 'util';
import BottomBar from '@components/bottomBar';
import TopBar from '@components/topBar';
import { Header } from '@components/header';
import { userApi } from '@/api/user-api';
import { match } from 'ts-pattern';
import { PCFollowModal } from './components/PCFollowModal';

// 类型定义
interface Trader {
  uid: string;
  name: string;
  avatar: string;
  ranking: string;
  monthProfitRate: number;
  monthProfit: number;
  fourteenDayRate: number;
  currentBalance: number;
  maxFollowAmount: number;
  strategy?: string;
  followerCount?: number;
  balance?: number;
  availableBalance?: number;
  followCount?: string;
  profit?: string;
  totalAmount?: string;
  minInvest?: number;
  follow?: boolean;
}

// Mock数据
const mockTraders: any[] = [
  {
    uid: 174,
    name: '1119076443luln@gmail.com',
    followCount: 1,
    thirtyDayRate: 48.91, // 对应thirtyDayRate
    thirtyDayAmount: 6026267.31, // 对应thirtyDayAmount
    totalAmount: 3713663.82, // 对应currentBalance
    currentBalance: 78979,
    minMoney: 45554,
    maxFollowAmount: 23,
    fourteenDayRate: 23,
    avatar: '/followup/1.png',
  },
  {
    uid: 174,
    name: '1119076443luln@gmail.com',
    followCount: 1,
    thirtyDayRate: 48.91, // 对应thirtyDayRate
    thirtyDayAmount: 6026267.31, // 对应thirtyDayAmount
    totalAmount: 3713663.82, // 对应currentBalance
    currentBalance: 78979,
    minMoney: 233,
    maxFollowAmount: 23,
    fourteenDayRate: 23,
    avatar: '/followup/2.png',
  },
];
// 交易员卡片组件
const TraderCard = ({
  trader,
  onFollow,
}: {
  trader: Trader;
  onFollow: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // 添加路由导航

  // 处理名称显示的函数
  const formatTraderName = (name: string) => {
    if (name.length <= 9) return name;
    const start = name.slice(0, 6);
    const end = name.slice(-5);
    return `${start}...${end}`;
  };

  // 处理跟单按钮点击，阻止事件冒泡
  const handleFollowClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    onFollow(id);
  };

  // 跳转到交易员详情页
  const goToTraderDetail = () => {
    navigate(`/followup/trader-detail/${trader.uid}`);
  };
  const [userInfoData, setUserInfoData] = useState({});

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      console.log('faith=============data.data', data.data);
      setUserInfoData(data.data);
    }
  };
  useEffect(() => {
    loadUserInfoData();
  }, []);

  return (
    <div
      onClick={goToTraderDetail}
      className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 cursor-pointer active:bg-gray-50 ${
        isMobile ? '' : 'w-[380px]'
      }`}
    >
      {}

      <>
        {/* 交易员基本信息 */}
        <div className="p-4 flex items-center justify-between group">
          <div className="flex items-center gap-3">
            <img
              className="w-12 h-12 rounded-full object-cover ring-2 ring-gray-100 group-hover:ring-blue-100 transition-all"
              src={trader.avatar || '/followup/2.png'}
              alt=""
            />
            <div>
              <div
                className="font-medium text-gray-900 mb-0.5 group-hover:text-blue-500 transition-colors"
                title={trader.name}
              >
                {formatTraderName(trader.name)}
              </div>
              <div className="flex items-center text-xs text-gray-500 gap-1">
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" />
                </svg>
                <span>
                  {trader?.followCount}/{trader?.peopleCount}
                </span>
              </div>
            </div>
          </div>

          <button
            disabled={trader.follow || userInfoData.merchandiser === 1}
            style={{
              backgroundColor:
                trader.follow || userInfoData.merchandiser === 1
                  ? '#E5E7EB'
                  : '',
            }}
            onClick={(e) => handleFollowClick(e, trader.id)}
            className="px-4 py-2 bg-[#4ea5ab] text-white rounded-lg hover:bg-[#458f94] active:bg-[#3d7f84] transition-colors duration-200 text-xs font-medium"
          >
            {t('跟单')}
          </button>
        </div>

        {/* 核心统计数据 */}
        <div className="px-4 py-3 bg-gray-50 border-t border-b border-gray-100 group-hover:bg-gray-100 transition-colors">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-3 bg-white rounded-lg hover:shadow-sm transition-all duration-200">
              <div className="text-xs text-gray-500 mb-1">
                {t('30天盈亏率')}
              </div>
              <div
                className={`text-sm font-semibold ${
                  trader.monthProfitRate >= 0
                    ? 'text-emerald-500'
                    : 'text-red-500'
                }`}
              >
                {trader.monthProfitRate >= 0 ? '+' : ''}
                {trader.monthProfitRate}%
              </div>
            </div>

            <div className="p-3 bg-white rounded-lg hover:shadow-sm transition-all duration-200">
              <div className="text-xs text-gray-500 mb-1">{t('30天收益')}</div>
              <div className="text-sm font-semibold text-gray-900">
                ${trader.monthProfit}
              </div>
            </div>
          </div>
        </div>

        {/* 详细信息 */}
        <div className="p-4 hover:bg-gray-50 transition-colors">
          {/* Labels */}
          <div className="grid grid-cols-3 gap-4 text-xs mb-2">
            <span className="text-gray-500 text-center">{t('14日勝率')}</span>
            <span className="text-gray-500 text-center">
              {t('當前資金管理')}
            </span>
            <span className="text-gray-500 text-center">
              {t('最低跟隨金額')}
            </span>
          </div>

          {/* Values */}
          <div className="grid grid-cols-3 gap-4 text-xs">
            <span className="font-medium text-gray-900 text-center">
              {trader.monthProfitRate}%
            </span>
            <span className="font-medium text-gray-900 text-center">
              ${trader.totalAmount}
            </span>
            <span className="font-medium text-gray-900 text-center">
              ${trader.minInvest}
            </span>
          </div>
        </div>

        {/* 添加一个查看详情的提示 */}
        <div className="px-4 py-2 text-xs text-[#4ea5ab] border-t border-gray-100 flex items-center justify-center hover:bg-[#ebfaf7] transition-colors">
          <span>{t('查看詳情')}</span>
          <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="currentColor">
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          </svg>
        </div>
      </>
    </div>
  );
};

const TraderCard2 = ({
  trader,
  onFollow,
}: {
  trader: Trader;
  onFollow: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // 添加路由导航

  const goToTraderDetail = () => {
    navigate(`/followup/trader-detail/${trader.uid}`);
  };

  // 处理名称显示的函数
  const formatTraderName = (name: string) => {
    if (name.length <= 9) return name;
    const start = name.slice(0, 6);
    const end = name.slice(-5);
    return `${start}...${end}`;
  };

  // 处理跟单按钮点击，阻止事件冒泡
  const handleFollowClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    onFollow(id);
  };

  const [userInfoData, setUserInfoData] = useState({});

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      console.log('faith=============data.data', data.data);
      setUserInfoData(data.data);
    }
  };
  useEffect(() => {
    loadUserInfoData();
  }, []);

  return (
    <div
      onClick={goToTraderDetail}
      className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 cursor-pointer active:bg-gray-50 ${
        isMobile ? '' : 'w-[380px]'
      }`}
    >
      {}

      <>
        {/* 交易员基本信息 */}
        <div className="p-4 flex items-center justify-between group">
          <div className="flex items-center gap-3">
            <img
              className="w-12 h-12 rounded-full object-cover ring-2 ring-gray-100 group-hover:ring-blue-100 transition-all"
              src={trader.avatar || '/followup/2.png'}
              alt=""
            />
            <div>
              <div
                className="font-medium text-gray-900 mb-0.5 group-hover:text-blue-500 transition-colors"
                title={trader.name}
              >
                {formatTraderName(trader.name)}
              </div>
              <div className="flex items-center text-xs text-gray-500 gap-1">
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" />
                </svg>
                <span>
                  {trader?.followCount}/{trader?.peopleCount}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* 核心统计数据 */}
        <div className="px-4 py-3 bg-gray-50 border-t border-b border-gray-100 group-hover:bg-gray-100 transition-colors">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-3 bg-white rounded-lg hover:shadow-sm transition-all duration-200">
              <div className="text-xs text-gray-500 mb-1">
                {t('30天盈亏率')}
              </div>
              <div
                className={`text-sm font-semibold ${
                  trader.monthProfitRate >= 0
                    ? 'text-emerald-500'
                    : 'text-red-500'
                }`}
              >
                {trader.monthProfitRate >= 0 ? '+' : ''}
                {trader.monthProfitRate}%
              </div>
            </div>

            <div className="p-3 bg-white rounded-lg hover:shadow-sm transition-all duration-200">
              <div className="text-xs text-gray-500 mb-1">{t('30天收益')}</div>
              <div className="text-sm font-semibold text-gray-900">
                ${trader.monthProfit}
              </div>
            </div>
          </div>
        </div>

        {/* 详细信息 */}
        <div className="p-4 hover:bg-gray-50 transition-colors">
          {/* Labels */}
          <div className="grid grid-cols-3 gap-4 text-xs mb-2">
            <span className="text-gray-500 text-center">{t('14日勝率')}</span>
            <span className="text-gray-500 text-center">
              {t('當前資金管理')}
            </span>
            <span className="text-gray-500 text-center">
              {t('最低跟隨金額')}
            </span>
          </div>

          {/* Values */}
          <div className="grid grid-cols-3 gap-4 text-xs">
            <span className="font-medium text-gray-900 text-center">
              {trader.monthProfitRate}%
            </span>
            <span className="font-medium text-gray-900 text-center">
              ${trader.totalAmount}
            </span>
            <span className="font-medium text-gray-900 text-center">
              ${trader.minInvest}
            </span>
          </div>
        </div>

        {/* 添加一个查看详情的提示 */}
        <div className="px-4 py-2 text-xs text-[#4ea5ab] border-t border-gray-100 flex items-center justify-center hover:bg-[#ebfaf7] transition-colors">
          <span>{t('查看详情')}</span>
          <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="currentColor">
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          </svg>
        </div>
      </>
    </div>
  );
};

const mockFollowUsers = [
  {
    id: 1,
    username: '张小明',
    amount: 5000,
    status: 'active',
    plannedTime: '2024-01-10T08:30:00',
  },
  {
    id: 2,
    username: '王思思',
    amount: 10000,
    status: 'active',
    plannedTime: '2024-01-09T14:20:00',
  },
  {
    id: 3,
    username: '李晓华',
    amount: 3000,
    status: 'pending',
    plannedTime: '2024-01-10T16:45:00',
  },
  {
    id: 4,
    username: '陈*强',
    amount: 8000,
    status: 'ended',
    plannedTime: '2024-01-08T09:15:00',
  },
  {
    id: 5,
    username: '赵*敏',
    amount: 15000,
    status: 'active',
    plannedTime: '2024-01-10T11:30:00',
  },
  {
    id: 6,
    username: '林小梅',
    amount: 6000,
    status: 'pending',
    plannedTime: '2024-01-10T15:20:00',
  },
  {
    id: 7,
    username: '吴*昊',
    amount: 12000,
    status: 'active',
    plannedTime: '2024-01-09T10:40:00',
  },
  {
    id: 8,
    username: '周小红',
    amount: 4500,
    status: 'ended',
    plannedTime: '2024-01-08T13:50:00',
  },
  {
    id: 9,
    username: '黄*腾',
    amount: 7500,
    status: 'active',
    plannedTime: '2024-01-10T09:25:00',
  },
  {
    id: 10,
    username: '刘小芳',
    amount: 9000,
    status: 'pending',
    plannedTime: '2024-01-10T14:15:00',
  },
];
// 主页面组件
export default function FollowUp() {
  const { t } = useTranslation();
  const [traders, setTraders] = useState<Trader[]>([]);
  const [selectedTrader, setSelectedTrader] = useState<Trader | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [merchandiserLoading, setMerchandiserLoading] = useState(false);
  const navigate = useNavigate();
  const [mockUserInfo, setMockUserInfo] = useState({} as any);
  const param = useParams();

  // 交易所下面用的数据
  const [followUsers, setFollowUsers] = useState([]);
  const [userInfoData, setUserInfoData] = useState({});

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      console.log('faith=============data.data', data.data);
      setUserInfoData(data.data);
    }
  };
  useEffect(() => {
    loadUserInfoData();
    //setTraders(mockTraders);
  }, []);

  const fetchTraders = async () => {
    try {
      setLoading(true);
      const res = await followApi.getTraderList();
      if (res.code === 0 && res.data.length > 0) {
        // console.log('faith=============transformedTraders', transformedTraders);
        //setTraders(mockTraders);
        setTraders(res.data);
      }
    } catch (error) {
      console.error('获取交易员列表失败:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTraders();
    getMockUserInfo();
  }, []);

  const handleConfirmFollow = async (amount: number) => {
    if (!selectedTrader) return;

    try {
      // 调用跟单API
      await fetch('/api/follow', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          traderId: selectedTrader.id,
          amount,
        }),
      });

      // 跳转到我的跟单页面
      navigate('/my-follows');
    } catch (error) {
      throw error;
    }
  };

  const handleFollow = (trader: Trader) => {
    console.log('Selected trader:', trader);
    setSelectedTrader(trader);
    setIsModalVisible(true);
  };

  // 骨架屏组件
  const SkeletonTrader = () => (
    <div className="trader-card">
      <div className="trader-info">
        {/* <Skeleton.Avatar className="trader-avatar" /> */}
        <div className="trader-basic" style={{ flex: 1, marginLeft: '12px' }}>
          <Skeleton.Title style={{ width: '120px' }} />
          <Skeleton.Paragraph
            lineCount={1}
            style={{ width: '80px', marginTop: '8px' }}
          />
        </div>
        {/* <Skeleton.Button style={{ width: '80px', height: '32px' }} /> */}
      </div>

      <div className="trader-stats">
        <div className="stat-row">
          <Skeleton.Paragraph lineCount={1} style={{ width: '100px' }} />
          <Skeleton.Paragraph lineCount={1} style={{ width: '60px' }} />
        </div>
        <div className="stat-row">
          <Skeleton.Paragraph lineCount={1} style={{ width: '80px' }} />
          <Skeleton.Paragraph lineCount={1} style={{ width: '100px' }} />
        </div>
      </div>

      <div className="trader-details">
        {[1, 2, 3].map((item) => (
          <div key={item} className="detail-item">
            <Skeleton.Paragraph lineCount={1} style={{ width: '120px' }} />
            <Skeleton.Paragraph lineCount={1} style={{ width: '80px' }} />
          </div>
        ))}
      </div>
    </div>
  );

  const goToMyOrder = () => {
    navigate('/followup/order');
  };

  const becomeTrader = () => {
    navigate('/followup/become-trader');
  };

  const getMerchandiserUserList = async () => {
    setMerchandiserLoading(true);

    // mock
    //setFollowUsers(mockFollowUsers);
    const uid = localStorage.getItem('uid');
    const data = await followApi.getMcdUserInfoList(uid);
    if (data.ok) {
      setFollowUsers(data.data);
    }

    setMerchandiserLoading(false);
  };

  const getMockUserInfo = async () => {
    const uid = localStorage.getItem('uid');
    const data = await userApi.mockUserInfo({ uid });
    if (data.ok) {
      console.log('faith=============data.data', data.data);
      setMockUserInfo(data.data);

      // if (data.data.merchandiser === 1) {
      getMerchandiserUserList();
      // }
    }
  };

  const uid = localStorage.getItem('uid');
  mockUserInfo.merchandiser = 1;

  // 普通
  if (userInfoData.merchandiser === 0) {
    return (
      <div className="page follow-container">
        <Header />
        <Divider />

        {match(isMobile)
          .with(true, () => (
            <>
              {/* 頂部標題 */}
              <div className="follow-header">
                <div className="follow-title">{t('跟單交易')}</div>
                <p className="follow-subtitle">{t('與最優秀的交易員同行')}</p>
              </div>
              {/* 功能區塊 */}
              <div className="follow-functions">
                <div className="function-card" onClick={goToMyOrder}>
                  <div className="function-content">
                    <div className="text-content">
                      <h4>{t('我的跟單')}</h4>
                      <p className="!text-[10px]">{t('查看跟單收益')}</p>
                    </div>
                    <img
                      src="/followup/income-DWjozVpO.svg"
                      alt=""
                      className="function-icon1"
                    />
                  </div>
                </div>
                <div className="function-card" onClick={becomeTrader}>
                  <div className="function-content">
                    <div className="text-content">
                      <h4>{t('成為交易員')}</h4>
                      <p className="!text-[10px]">{t('立即開始帶單')}</p>
                    </div>
                    <img
                      src="/followup/xxxkn.svg"
                      alt=""
                      className="function-icon"
                    />
                  </div>
                </div>
              </div>

              {/* 全部交易員 */}
              <div className="follow-all-traders">
                <div className="section-title">{t('全部交易員')}</div>
                <div className="trader-list">
                  {loading ? (
                    <>
                      <SkeletonTrader />
                      <SkeletonTrader />
                    </>
                  ) : (
                    traders.map((trader) => (
                      <TraderCard
                        key={trader.id}
                        trader={trader}
                        onFollow={() => handleFollow(trader)}
                      />
                    ))
                  )}
                </div>
              </div>

              {isModalVisible &&
                (isMobile ? (
                  <FollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ) : (
                  <PCFollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ))}

              {/* {isModalVisible && (
                <FollowModal
                  mockUserInfo={mockUserInfo}
                  trader={selectedTrader}
                  isVisible={isModalVisible}
                  onClose={() => {
                    setIsModalVisible(false);
                    setSelectedTrader(null);
                  }}
                  onConfirm={handleConfirmFollow}
                />
              )} */}

              <BottomBar index={4} />
              <Footer />
            </>
          ))
          .otherwise(() => (
            <div className="max-w-[1200px] mx-auto px-4">
              {/* 頂部標題 */}
              <div className="flex justify-between items-start gap-8">
                {/* 左侧内容 */}
                <div className="flex-1">
                  {/* 頂部標題 */}
                  <div className="mb-2">
                    <h1 className="text-sm font-bold text-gray-900">
                      {t('跟單交易')}
                    </h1>
                    <p className="text-sm text-gray-500 mt-1">
                      {t('與最優秀的交易員同行')}
                    </p>
                  </div>

                  {/* 功能按钮 */}
                  <div className="flex gap-3">
                    <button
                      onClick={goToMyOrder}
                      className="px-6 py-2 text-sm bg-[#4ea5ab] text-white rounded-full hover:bg-[#458f94] active:bg-[#3d7f84] transition-colors duration-200 font-medium"
                    >
                      {t('我的跟單')}
                    </button>
                    <button
                      onClick={becomeTrader}
                      className="px-6 py-2 text-sm border border-gray-200 rounded-full hover:bg-gray-50 transition-colors duration-200 font-medium text-gray-800"
                    >
                      {t('成為交易員')}
                    </button>
                  </div>
                </div>

                {/* 右侧卡片 */}
                <div className="bg-[#4ea5ab]/5 rounded-lg p-8 shadow-sm border border-[#4ea5ab]/10 flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <h2 className="text-sm font-semibold text-gray-900">
                        {`${t('成為')}${
                          localStorage.getItem('title') || 'GQ'
                        }${t('交易員')}`}
                      </h2>
                      <p className="text-gray-500 mt-2 text-base">
                        {t('交易从未如此简单！')}
                      </p>
                    </div>
                    <img
                      src="/followup/illustration-Cai2SUB5.svg"
                      alt=""
                      className="w-20 h-20 object-contain"
                    />
                  </div>
                </div>
              </div>

              {/* 全部交易員 */}
              <div className="follow-all-traders">
                <div className="section-title">{t('全部交易員')}</div>
                <div className="trader-list">
                  {loading ? (
                    <>
                      <SkeletonTrader />
                      <SkeletonTrader />
                    </>
                  ) : (
                    traders.map((trader) => (
                      <TraderCard
                        key={trader.id}
                        trader={trader}
                        onFollow={() => handleFollow(trader)}
                      />
                    ))
                  )}
                </div>
              </div>
              {/* {isModalVisible && (
                <FollowModal
                  mockUserInfo={mockUserInfo}
                  trader={selectedTrader}
                  isVisible={isModalVisible}
                  onClose={() => {
                    setIsModalVisible(false);
                    setSelectedTrader(null);
                  }}
                  onConfirm={handleConfirmFollow}
                />
              )} */}

              {isModalVisible &&
                (isMobile ? (
                  <FollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ) : (
                  <PCFollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ))}

              <BottomBar index={4} />
              <Footer />
            </div>
          ))}
      </div>
    );
  }

  // 交易
  if (userInfoData.merchandiser === 1) {
    return (
      <div className="page follow-container">
        <Header />
        <Divider />

        {match(isMobile)
          .with(true, () => (
            <>
              {/* 全部交易員 */}
              <div className="follow-all-traders">
                <div className="section-title">{t('跟单用户')}</div>
                <div className="trader-list">
                  {loading ? (
                    <>
                      <SkeletonTrader />
                      <SkeletonTrader />
                    </>
                  ) : (
                    traders
                      .filter((trader) => trader.uid == uid)
                      .map((trader) => (
                        <TraderCard2
                          key={trader.id}
                          trader={trader}
                          onFollow={() => handleFollow(trader)}
                        />
                      ))
                  )}
                </div>
              </div>

              <div className="follow-users-section mt-6">
                <div className="section-title mb-3">{t('跟单用户列表')}</div>
                <div className="follow-users-list space-y-3">
                  {merchandiserLoading ? (
                    // 加载状态
                    <>
                      <div className="h-16 bg-gray-800/40 animate-pulse rounded-lg"></div>
                      <div className="h-16 bg-gray-800/40 animate-pulse rounded-lg"></div>
                    </>
                  ) : (
                    followUsers.map((user) => (
                      <div
                        key={user.uid}
                        className="follow-user-card bg-white rounded-lg p-3 shadow-sm mb-2"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex flex-col">
                            <span className="text-gray-900 text-sm font-medium">
                              {user.name}
                            </span>
                            <span className="text-gray-500 text-xs mt-1">
                              {t('跟單時間')}:{' '}
                              {new Date(user.date).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="flex flex-col items-end">
                            <span className="text-indigo-600 text-sm font-medium">
                              ${user.investProp.toLocaleString()}
                            </span>
                            <span
                              className="text-xs mt-1 text-emerald-500"
                              // className={`text-xs mt-1 ${
                              //   user.status === 'active'
                              //     ? 'text-emerald-500' // 跟单中状态
                              //     : user.status === 'pending'
                              //     ? 'text-amber-500' // 待确认状态
                              //     : 'text-gray-500' // 已结束状态
                              // }`}
                            >
                              {t('跟单中')}
                              {/* {user.status === 'active'
                                ? '跟单中'
                                : user.status === 'pending'
                                ? '待确认'
                                : '已结束'} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              {isModalVisible &&
                (isMobile ? (
                  <FollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ) : (
                  <PCFollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ))}

              {/* {isModalVisible && (
                <FollowModal
                  mockUserInfo={mockUserInfo}
                  trader={selectedTrader}
                  isVisible={isModalVisible}
                  onClose={() => {
                    setIsModalVisible(false);
                    setSelectedTrader(null);
                  }}
                  onConfirm={handleConfirmFollow}
                />
              )} */}

              <BottomBar index={4} />
              <Footer />
            </>
          ))
          .otherwise(() => (
            <div className="max-w-[1200px] mx-auto px-4">
              {/* 頂部標題 */}

              <div className="follow-all-traders">
                <div className="section-title">{t('跟单用户')}</div>
                <div className="trader-list">
                  {loading ? (
                    <>
                      <SkeletonTrader />
                      <SkeletonTrader />
                    </>
                  ) : (
                    traders.map((trader) => (
                      <TraderCard2
                        key={trader.id}
                        trader={trader}
                        onFollow={() => handleFollow(trader)}
                      />
                    ))
                  )}
                </div>
              </div>

              <div className="follow-users-section mt-6">
                <div className="section-title mb-3">{t('跟单用户列表')}</div>

                {/* PC端表格式布局 */}
                <div className="hidden md:block">
                  <div className="bg-white rounded-lg shadow overflow-hidden">
                    {/* 表头 */}
                    <div className="grid grid-cols-4 bg-gray-50 p-4 border-b">
                      <div className="text-sm font-medium text-gray-500">
                        {t('用戶名')}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {t('跟單金額')}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {t('跟單狀態')}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {t('跟單時間')}
                      </div>
                    </div>

                    {/* 表格内容 */}
                    {merchandiserLoading ? (
                      <div className="animate-pulse">
                        {[1, 2, 3].map((i) => (
                          <div key={i} className="h-16 bg-gray-100"></div>
                        ))}
                      </div>
                    ) : (
                      followUsers.map((user) => (
                        <div
                          key={user.id}
                          className="grid grid-cols-4 p-4 border-b hover:bg-gray-50 transition-colors items-center"
                        >
                          <div className="text-sm text-gray-900 font-medium">
                            {user.username}
                          </div>
                          <div className="text-sm text-indigo-600 font-medium">
                            ${user.amount.toLocaleString()}
                          </div>
                          <div>
                            <span
                              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${
                    user.status === 'active'
                      ? 'bg-emerald-100 text-emerald-800'
                      : user.status === 'pending'
                      ? 'bg-amber-100 text-amber-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                            >
                              {user.status === 'active'
                                ? t('跟單中')
                                : user.status === 'pending'
                                ? t('待確認')
                                : t('已結束')}
                            </span>
                          </div>
                          <div className="text-sm text-gray-500">
                            {new Date(user.plannedTime).toLocaleDateString()}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                {/* 移动端卡片式布局 */}
                <div className="md:hidden">
                  <div className="follow-users-list space-y-3">
                    {merchandiserLoading ? (
                      <>
                        <div className="h-16 bg-gray-100 animate-pulse rounded-lg"></div>
                        <div className="h-16 bg-gray-100 animate-pulse rounded-lg"></div>
                      </>
                    ) : (
                      followUsers.map((user) => (
                        <div
                          key={user.id}
                          className="follow-user-card bg-white rounded-lg p-3 shadow-sm mb-2"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                              <span className="text-gray-900 text-sm font-medium">
                                {user.username}
                              </span>
                              <span className="text-gray-500 text-xs mt-1">
                                {t('跟單時間')}:{' '}
                                {new Date(
                                  user.plannedTime
                                ).toLocaleDateString()}
                              </span>
                            </div>
                            <div className="flex flex-col items-end">
                              <span className="text-indigo-600 text-sm font-medium">
                                ${user.amount.toLocaleString()}
                              </span>
                              <span
                                className={`text-xs mt-1 ${
                                  user.status === 'active'
                                    ? 'text-emerald-500'
                                    : user.status === 'pending'
                                    ? 'text-amber-500'
                                    : 'text-gray-500'
                                }`}
                              >
                                {user.status === 'active'
                                  ? t('跟單中')
                                  : user.status === 'pending'
                                  ? t('待確認')
                                  : t('已結束')}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>

              {/* {isModalVisible && (
                <FollowModal
                  mockUserInfo={mockUserInfo}
                  trader={selectedTrader}
                  isVisible={isModalVisible}
                  onClose={() => {
                    setIsModalVisible(false);
                    setSelectedTrader(null);
                  }}
                  onConfirm={handleConfirmFollow}
                />
              )} */}

              {isModalVisible &&
                (isMobile ? (
                  <FollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ) : (
                  <PCFollowModal
                    mockUserInfo={mockUserInfo}
                    trader={selectedTrader}
                    isVisible={isModalVisible}
                    onClose={() => {
                      setIsModalVisible(false);
                      setSelectedTrader(null);
                    }}
                    onConfirm={handleConfirmFollow}
                  />
                ))}

              <BottomBar index={4} />
              <Footer />
            </div>
          ))}
      </div>
    );
  }
}
