import React from 'react';

interface AvatarProps {
  username: string;
  size?: number;  
}

const Avatar: React.FC<AvatarProps> = ({ username, size = 40 }) => {
    const firstLetter = (username || '').charAt(0).toUpperCase();

  return (
    <div className='bg-background-subtle-teal'
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        fontSize: size / 2,  
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
      }}
    >
      <div className='text-text-color-teal'>{firstLetter}</div>
    </div>
  );
};

export default Avatar;
