import { imageConfig } from "@config/config";
import { Ctmarketlist } from "@/hooks/useCtmarketlist";

export const getLogo = (name: string, ctmarketlist: Ctmarketlist[]) => {
  let logo = "";
  for (const ctmarket of ctmarketlist) {
    if (name == ctmarket.coinname) {
      logo = imageConfig.baseImageUrl + ctmarket.logo;
      break;
    }
  }
  return logo;
};
