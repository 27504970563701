import { useRouter } from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
// import { useMediaQuery } from "react-responsive";
import { Else, If, Then } from '@components/If';
import Link from '@components/link';
// import { UserStatus } from "@type/model/profile";
import { getToken } from '@/utils/token-util';
import { LogoutOutlined, SwapOutlined } from '@ant-design/icons';
import { useUserInfo } from '@hooks/useUserInfo';
import { getRedirectHref } from '@utils/redirection';
import { getText } from '@utils/util';
import { Badge, Toast } from 'antd-mobile';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import CopyIcon from './copy-icon';
// import Profile from "./Profile";
import { languagesData } from '@/i18n/i18n';
import { LoginMsgContext } from '@/router/router';
import Container from '@components/container';
import MenuIcon from '@components/Icon/icons/MenuIcon';
import MessageIcon from '@components/Icon/icons/MessageIcon';
import XCloseIcon from '@components/Icon/icons/XClose';
import { Logo } from '@components/logo';
import { Dropdown } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { match } from 'ts-pattern';
import { menuList } from '../tool';
import { SwitchAccountModal } from './switch-account-modal';
import { useSwitchAccountStore } from './switch-account-modal-store';
import Avatar from './Avatar';
import Propertypop from './propertypop';

interface AuthMenuProps {
  fixedNavbar: boolean;
  profile?: {
    riskLevelType: number;
    fullName: string;
    email: string;
    // status: UserStatus;
    isLoading: boolean;
    riskLevelName: string;
  };
  menuClick: () => void;
  transparentBg: boolean;
}
const types = ['未認證', '審核中', '已認證', '審核拒絕'];

const MobileMenu = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  translate,
  handleCopy,
}: {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
  translate: (text: string) => string;
  handleCopy: (value: string) => void;
}) => {
  const router = useRouter();
  const token = getToken();
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const lan = localStorage.getItem('i18n');

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  return (
    <div
      className={`fixed left-0 h-[100vh] w-full bg-white transform transition-transform duration-300 ease-in-out 
      shadow-lg z-[990]`}
      style={{
        transform: isMobileMenuOpen ? 'translateY(0)' : 'translateY(-100%)',
        top: isMobileMenuOpen ? '48px' : '0',
      }}
    >
      <div className="flex flex-col">
        {/* <Container
          className={classNames(
            "h-[48px] mb-4 flex items-center justify-between h-full"
          )}
        >
          <div>
            <Link
              className="flex items-center mr-8 xl:mr-16"
              href={"/"}
              ariaLabel="Reku's logo"
            >
              <Logo className="w-[48px] h-[48px]" />
            </Link>
          </div>
          <button onClick={() => setIsMobileMenuOpen(false)}>
            <XCloseIcon className="w-6 h-6" />
          </button>
        </Container> */}
        {match(!!token)
          .with(true, () => (
            <div className="px-4 py-3 border-b border-gray-100">
              <div className="flex items-center gap-3">
                {/* <img
                  src="/home/profile.png"
                  alt="Profile"
                  className="w-10 h-10"
                /> */}
                <Avatar username={userInfo?.username} size={50} />
                <div className="grow">
                  <div className="font-medium title-css-info-5x">
                    {userInfo?.username}
                  </div>
                  <div className="text-sm text-gray-500 flex items-center gap-2">
                    ID: {userInfo?.userCode}
                    <button onClick={() => handleCopy(userInfo?.userCode)}>
                      <CopyIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      useSwitchAccountStore.getState().setVisible(true);
                    }}
                    className="flex items-center gap-1 text-primary-600 hover:text-primary-700 transition-colors px-2 py-1 rounded"
                  >
                    <SwapOutlined className="text-lg" />
                    <span className="text-sm">
                      {translate(getText('切换'))}
                    </span>
                  </button>
                </div>
              </div>
              <div
                className="mt-3 text-sm text-gray-500"
                onClick={() => {
                  if (userInfo?.rzstatus === 2) {
                    return;
                  }
                  navigate('/idcard');
                }}
              >
                {translate(getText('身份認證'))}:{' '}
                {translate(getText(types[userInfo?.rzstatus]))}
              </div>
            </div>
          ))
          .otherwise(() => null)}

        {[
          // 动态添加高级认证选项
          // ...(userInfo?.rzstatus === 2
          //   ? [
          //       {
          //         key: '高级認證',
          //         href: '/gjidcard',
          //         // 添加点击处理逻辑
          //         onClick: (e) => {
          //           e.preventDefault(); // 防止 Link 默认跳转
          //           if (userInfo?.rzstatus === 2 && userInfo?.cardsc) {
          //             return;
          //           }
          //           navigate('/gjidcard');
          //           setIsMobileMenuOpen(false);
          //         },
          //       },
          //     ]
          //   : []),
          { key: '支付密码', href: '/setpass' },
          { key: '交易記錄', href: '/jyjl' },
          { key: '分享', href: '/sharecenter' },
          { key: '客服', href: '/chat' },
          ,
        ].map((item) => (
          <Link
            key={item.key}
            href={item.href}
            className="py-3 px-4 border-b border-gray-100"
            onClick={(e) => {
              if (item.onClick) {
                item.onClick(e);
              } else {
                setIsMobileMenuOpen(false);
              }
            }}
          >
            {translate(getText(item.key))}
          </Link>
        ))}
        {match(!!token)
          .with(false, () => (
            <div className="flex justify-between gap-4 px-4 mt-4">
              <Link
                href={getRedirectHref(router.asPath)}
                className="flex-1 py-3 px-4 border border-primary-600 rounded-lg text-primary-600 text-center hover:bg-primary-50 transition-colors"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {translate(getText('登錄'))}
              </Link>
              <Link
                href="/register"
                className="flex-1 py-3 px-4 bg-primary-600 text-white rounded-lg text-center hover:bg-primary-700 transition-colors"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {translate(getText('立即註冊'))}
              </Link>
            </div>
          ))
          .otherwise(() => (
            <div className="flex justify-between gap-4 px-4 mt-4">
              <button
                onClick={() => {
                  localStorage.removeItem('uid');
                  localStorage.removeItem('username');
                  localStorage.removeItem('x-access-token');
                  navigate('/login-page');
                  setIsMobileMenuOpen(false);
                }}
                className="flex-1 py-3 px-4 border border-primary-600 rounded-lg text-primary-600 text-center hover:bg-primary-50 transition-colors"
              >
                {translate(getText('退出登錄'))}
                <LogoutOutlined className="ml-2 text-lg" />
              </button>
            </div>
          ))}
        <div className="flex items-center justify-center py-3">
          <Link
            href="/download"
            className="text-md font-semibold text-text-neutral-subtle dark:text-dark-text-neutral-subtle"
          >
            App
            {(lan == 'zh' ? '' : ' ') + translate(getText('下載'))}
          </Link>
        </div>
      </div>
    </div>
  );
};

const AuthMenu: React.FC<AuthMenuProps> = ({
  fixedNavbar,
  profile,
  menuClick,
  transparentBg,
}) => {
  // const { t } = useTranslation("components");
  const { t: translate } = useTranslation();
  const [loginmsg] = useContext(LoginMsgContext);
  const lan = localStorage.getItem('i18n');
  const router = useRouter();
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const token = getToken();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleCopy = (value) => {
    if (copy(value)) {
      Toast.show({ content: translate(getText('成功')) });
    }
  };

  const changeLanguage = (languageStr: string) => {
    localStorage.setItem('i18n', languageStr);
    window.location.reload();
  };

  const languageMenu = {
    items: languagesData.map((lang) => ({
      key: lang.code,
      label: (
        <div
          className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
          onClick={() => changeLanguage(lang.code)}
        >
          <span>{lang.title}</span>
          {lan === lang.code && (
            <span className="ml-2 text-primary-600">✓</span>
          )}
        </div>
      ),
    })),
  };

  const handleMobileMenuClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const mobildHeader = (
    <div className="flex items-center gap-3">
      <div className="flex items-center" onClick={() => navigate('/noice2')}>
        <Badge
          content={loginmsg?.noticeCount ? loginmsg?.noticeCount : 0}
          className=""
        >
          <MessageIcon
            className={classNames('w-5 h-5', {
              'text-primary-600': !transparentBg,
              'text-white': transparentBg,
            })}
          />
        </Badge>
      </div>

      <Dropdown menu={languageMenu} placement="bottom">
        <div
          className={classNames(
            'cursor-pointer text-base font-medium hover:text-primary-600 transition-colors duration-200',
            `${!transparentBg ? 'text-primary-600' : 'text-white'}`
          )}
        >
          <div
            className={`w-12 h-6 flex items-center rounded-full p-1 px-[3px] cursor-pointer justify-between bg-[#1BA7AC]`}
          >
            <div
              className={`bg-white w-5 h-5 rounded-full shadow-md transform`}
            />
            <span className="text-[12px] pr-1 text-white">
              {lan?.toUpperCase()}
            </span>
          </div>
          {/* {translate(getText("語言"))} */}
        </div>
      </Dropdown>
      <button
        onClick={handleMobileMenuClick}
        className={classNames(
          'cursor-pointer hover:text-primary-600 transition-colors duration-200',
          `${!transparentBg ? 'text-primary-600' : 'text-white'}`
        )}
      >
        {isMobileMenuOpen ? (
          <XCloseIcon className="w-6 h-6" />
        ) : (
          <MenuIcon className="w-6 h-6" />
        )}
      </button>
    </div>
  );

  return (
    <>
      <div className="flex items-center gap-4 xl:gap-6 order-2 xl:order-1">
        <If condition={!!token}>
          <Then>
            {match(isMobile)
              .with(true, () => mobildHeader)
              .otherwise(() => (
                <div
                  className={`flex items-center gap-4 xl:gap-6 ${
                    !transparentBg ? 'text-primary-600' : 'text-white'
                  }`}
                >
                  <div className="flex flex-row items-center gap-4">
                    <button onClick={() => navigate('/jyjl')} className="">
                      {translate(getText('交易記錄'))}
                    </button>
                    <button
                      onClick={() => navigate('/sharecenter')}
                      className=""
                    >
                      {translate(getText('分享'))}
                    </button>
                    <div
                      className="cursor-pointer"
                      onClick={() => navigate('/noice2')}
                    >
                      <Badge
                        content={
                          loginmsg?.noticeCount ? loginmsg?.noticeCount : 0
                        }
                      >
                        {translate(getText('留言列表'))}
                      </Badge>
                    </div>
                    <button onClick={() => navigate('/helplist')}>
                      {translate(getText('幫助中心'))}
                    </button>
                    <button onClick={() => navigate('/setting')}>
                      {translate(getText('設置'))}
                    </button>
                  </div>

                  <div className="cursor-pointer flex items-center">
                    <div className="flex flex-col">
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: '0',
                              label: (
                                <div
                                  className={classNames('flex items-center')}
                                  onClick={() => handleCopy(userInfo?.userCode)}
                                >
                                  {translate(getText('ID'))}：
                                  {userInfo?.userCode}
                                  <span
                                    style={{
                                      marginLeft: '.5rem',
                                      fontSize: '.83rem',
                                    }}
                                  >
                                    <CopyIcon className="w-4 h-4" />
                                  </span>
                                </div>
                              ),
                            },
                            {
                              key: '1',
                              label: (
                                <div className="flex items-center gap-2 py-1">
                                  <div className="">
                                    {translate(getText('身份認證'))}
                                  </div>
                                  <div className="">
                                    {translate(
                                      getText(types[userInfo?.rzstatus])
                                    )}
                                  </div>
                                </div>
                              ),
                              onClick: () => {
                                if (userInfo?.rzstatus === 2) {
                                  return;
                                }
                                navigate('/idcard');
                              },
                            },
                            ...(userInfo?.rzstatus === 2
                              ? [
                                  {
                                    key: '2',
                                    label: (
                                      <div
                                        onClick={() => {
                                          if (
                                            userInfo?.rzstatus === 2 &&
                                            userInfo?.cardsc
                                          ) {
                                            return;
                                          }
                                          navigate('/gjidcard');
                                        }}
                                      >
                                        {translate(getText('高级認證'))}
                                      </div>
                                    ),
                                  },
                                ]
                              : []),
                            {
                              key: '7',
                              label: (
                                <div onClick={() => navigate('/aboutus')}>
                                  {translate(getText('平台介绍'))}
                                </div>
                              ),
                            },
                            {
                              key: '9',
                              label: (
                                <div onClick={() => navigate('/download')}>
                                  App
                                  {(lan == 'zh' ? '' : ' ') +
                                    translate(getText('下載'))}
                                </div>
                              ),
                            },
                            {
                              key: '10',
                              label: (
                                <div
                                  onClick={() => navigate('/securitycenter')}
                                >
                                  {translate(getText('安全中心'))}
                                </div>
                              ),
                            },
                            {
                              key: '11',
                              label: (
                                <div
                                  onClick={() => {
                                    useSwitchAccountStore
                                      .getState()
                                      .setVisible(true);
                                  }}
                                >
                                  {translate(getText('用户切换'))}
                                </div>
                              ),
                            },
                            {
                              type: 'divider',
                            },
                            {
                              key: '12',
                              label: (
                                <div
                                  onClick={() => {
                                    localStorage.removeItem('uid');
                                    localStorage.removeItem('username');
                                    localStorage.removeItem('x-access-token');
                                    navigate('/login-page');
                                  }}
                                  className="flex items-center gap-2"
                                >
                                  <LogoutOutlined className="text-lg" />
                                  {translate(getText('退出登錄'))}
                                </div>
                              ),
                            },
                          ],
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <div
                            className={classNames(
                              'cursor-pointer text-base font-medium hover:text-primary-600 transition-colors duration-200',
                              `${
                                !transparentBg
                                  ? 'text-primary-600'
                                  : 'text-white'
                              }`
                            )}
                          >
                            {userInfo?.username}
                          </div>
                          <img
                            src="/home/profile.png"
                            alt="Profile"
                            className="w-10 h-10"
                          />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                  <Dropdown menu={languageMenu} placement="bottom">
                    <div
                      className={classNames(
                        'cursor-pointer text-base font-medium hover:text-primary-600 transition-colors duration-200',
                        `${!transparentBg ? 'text-primary-600' : 'text-white'}`
                      )}
                    >
                      {translate(getText('語言'))}
                    </div>
                  </Dropdown>
                  <button
                    onClick={() => {
                      localStorage.removeItem('uid');
                      localStorage.removeItem('username');
                      localStorage.removeItem('x-access-token');
                      navigate('/login-page');
                    }}
                    className="hidden px-4 py-2 flex items-center gap-2 text-primary-600 hover:text-primary-700 transition-colors duration-200 rounded-full bg-gray-100 hover:bg-gray-200"
                  >
                    {translate(getText('退出登錄'))}
                    <LogoutOutlined className="text-lg" />
                  </button>
                </div>
              ))}

            {/* {profile && <Profile fixedNavbar={fixedNavbar} profile={profile} />} */}
          </Then>
          <Else>
            {match(isMobile)
              .with(true, () => mobildHeader)
              .otherwise(() => (
                <div className="flex gap-3">
                  {router.pathname === '/login-page' ? null : (
                    <Link
                      href={getRedirectHref(router.asPath)}
                      dataTestId="welcome_signinBtn"
                    >
                      <div className="px-4 py-2 flex items-center gap-2 text-primary-600 hover:text-primary-700 transition-colors duration-200 rounded-full bg-gray-100 hover:bg-gray-200">
                        {translate(getText('登錄'))}
                      </div>
                    </Link>
                  )}
                  <Link href="/register" dataTestId="welcome_registerBtn">
                    <div className="px-4 py-2 flex items-center gap-2 text-primary-600 hover:text-primary-700 transition-colors duration-200 rounded-full bg-gray-100 hover:bg-gray-200">
                      {translate(getText('立即註冊'))}
                    </div>
                  </Link>
                  <Dropdown menu={languageMenu} placement="bottom">
                    <div className="px-4 py-2 flex items-center gap-2 text-primary-600 hover:text-primary-700 transition-colors duration-200 rounded-full bg-gray-100 hover:bg-gray-200">
                      {translate(getText('語言'))}
                    </div>
                  </Dropdown>
                </div>
              ))}
          </Else>
        </If>
      </div>
      {isMobile && (
        <MobileMenu
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          translate={translate}
          handleCopy={handleCopy}
        />
      )}
      <SwitchAccountModal />
    </>
  );
};
export default AuthMenu;
