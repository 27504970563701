import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ProfitChart = ({ data }) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (!chartRef.current) return;

    const chart = echarts.init(chartRef.current);

    const profits = data.map((item) => Number(item.todayProfit));
    const maxProfit = Math.max(...profits);
    const minProfit = Math.min(...profits);
    const maxIndex = profits.indexOf(maxProfit);
    const minIndex = profits.indexOf(minProfit);

    // 计算标签位置
    const getPosition = (index, total) => {
      const threshold = Math.floor(total * 0.2); // 前20%认为是靠左
      if (index <= threshold) {
        return ['right', 'top']; // 靠左的点，标签往右上方显示
      }
      return ['top']; // 其他位置正常向上显示
    };

    const [maxLabelPosition] = getPosition(maxIndex, profits.length);

    const option = {
      backgroundColor: '#F9FAFB',
      grid: {
        top: 50,
        right: 20,
        bottom: 20,
        left: 20,
        containLabel: false,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          return `${params[0].value.toFixed(2)}%`;
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        show: false,
        data: new Array(profits.length).fill(''),
      },
      yAxis: {
        type: 'value',
        show: false,
        scale: true,
      },
      series: [
        {
          data: profits,
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: '#4ea5ab',
            width: 2,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(4, 207, 153, 0.2)',
              },
              {
                offset: 1,
                color: 'rgba(4, 207, 153, 0)',
              },
            ]),
          },
          markPoint: {
            symbol: 'circle',
            symbolSize: 6,
            data: [
              {
                value: maxProfit,
                coord: [maxIndex, maxProfit],
                label: {
                  show: true,
                  color: '#333',
                  fontSize: 12,
                  fontWeight: 'normal',
                  position: maxLabelPosition,
                  distance: 10,
                  formatter: `${maxProfit.toFixed(2)}%`,
                  padding: [4, 8],
                  backgroundColor: 'transparent',
                  align:
                    maxIndex <= Math.floor(profits.length * 0.2)
                      ? 'left'
                      : 'center',
                },
                itemStyle: {
                  color: '#4ea5ab',
                },
              },
              {
                value: minProfit,
                coord: [minIndex, minProfit],
                label: {
                  show: true,
                  color: '#333',
                  fontSize: 12,
                  fontWeight: 'normal',
                  position: 'bottom',
                  distance: 10,
                  formatter: `${minProfit.toFixed(2)}%`,
                  padding: [4, 8],
                  backgroundColor: 'transparent',
                },
                itemStyle: {
                  color: '#4ea5ab',
                },
              },
            ],
          },
        },
      ],
    };

    chart.clear();
    chart.setOption(option, true);

    const resizeHandler = () => {
      if (chart && !chart.isDisposed()) {
        chart.resize();
      }
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      if (chart && !chart.isDisposed()) {
        chart.dispose();
      }
    };
  }, [data]);

  const profits = data.map((item) => Number(item.todayProfit));
  const maxProfit = Math.max(...profits);
  const minProfit = Math.min(...profits);

  return (
    <div>
      <div
        ref={chartRef}
        style={{
          height: '140px',
          width: '100%',
          background: '#F9FAFB',
          borderRadius: '8px',
          marginBottom: '8px',
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 20px',
          fontSize: '13px',
          color: '#666',
        }}
      >
        <div>
          <span>{t('最低')} : </span>
          <span style={{ color: '#4ea5ab' }}>{minProfit.toFixed(2)}%</span>
        </div>
        <div>
          <span>{t('最高')} : </span>
          <span style={{ color: '#4ea5ab' }}>{maxProfit.toFixed(2)}%</span>
        </div>
      </div>
    </div>
  );
};

export default ProfitChart;
