import { useNavigate, useLocation, useParams } from "react-router-dom";

// Custom hook to mimic Next.js's useRouter functionality
export function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  return {
    // Mimic Next.js router properties
    pathname: location.pathname,
    query: Object.fromEntries(new URLSearchParams(location.search)),
    asPath: location.pathname + location.search,

    // Mimic Next.js router methods
    push: (url: string) => navigate(url),
    replace: (url: string) => navigate(url, { replace: true }),
    back: () => navigate(-1),

    // Additional properties from react-router-dom
    params,
  };
}
