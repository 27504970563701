import Footer from "@components/Footer";
import { Header } from "@components/header";
import React from "react";
import Hero from "./_component/hero";
import LearningHub from "./_component/LearningHub";
import ManyAdvantages from "./_component/ManyAdvantages";
import MultiplePortfolio from "./_component/multiplePortfolio";
import OfficialPartners from "./_component/OfficialPartners";
import Service from "./_component/sevice";
import Testimonies from "./_component/Testimonies";
import "./index.css";
import BottomBar from "@components/bottomBar";

const PcHome: React.FC = () => {
  return (
    <>
      <Header />
      <main className="reku-new-theme bg-background-neutral-main dark:bg-dark-background-neutral-main xl:pt-[72px]">
        <Hero />
        <Service />
        <MultiplePortfolio />
        <ManyAdvantages />
        {/* <Secure /> */}
        <OfficialPartners />
        <Testimonies />
        <LearningHub />
        {/* <CTADownLoad /> */}
        <Footer />
      </main>

      <BottomBar index={1} />
    </>
  );
};

export default PcHome;
