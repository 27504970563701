import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoinListData } from "./types";
import { getLogo } from "@utils/get-logo";
import { useCtmarketlist } from "@hooks/useCtmarketlist";
import { SearchIcon } from "@components/Icon/icons/SearchIcon";

interface Props {
  coinListData: CoinListData;
  nowTab: string;
  uri?: string;
  handleClick?: () => void;
}

export const MarketList = ({
  coinListData,
  nowTab,
  uri = "trade",
  handleClick,
}: Props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const { ctmarketlist } = useCtmarketlist();

  const handleFavorite = (coin: string, e: React.MouseEvent) => {
    e.stopPropagation();
    return;
    // const newFavorites = new Set(favorites);
    // if (favorites.has(coin)) {
    //   newFavorites.delete(coin);
    // } else {
    //   newFavorites.add(coin);
    // }
    // setFavorites(newFavorites);
  };

  const filteredCoins = Object.entries(coinListData).filter(([key]) =>
    key.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="h-[80vh]">
      {/* Search Bar */}
      <div className="px-4 py-3">
        <div className="relative">
          <input
            type="text"
            className="w-full h-10 pl-10 pr-4 rounded-lg bg-grey-25 dark:bg-dark-grey-25 text-sm focus:outline-none"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="absolute left-3 top-1/2 -translate-y-1/2">
            <SearchIcon className="w-4 h-4 text-grey-500" />
          </div>
        </div>
      </div>

      {/* Coin List */}
      <div className="overflow-y-auto h-[calc(80vh-76px)]">
        {filteredCoins.map(([key, coin]) => (
          <div
            key={key}
            className="flex items-center px-4 py-3 hover:bg-grey-25 dark:hover:bg-dark-grey-25 cursor-pointer"
            onClick={() => {
              handleClick?.();
              return navigate(`/${uri}/${key}`);
            }}
          >
            {/* Favorite Star */}
            {/* <div
              className="mr-3 cursor-pointer"
              onClick={(e) => handleFavorite(key, e)}
            >
              <svg
                viewBox="0 0 24 24"
                className={`w-5 h-5 ${
                  favorites.has(key)
                    ? "text-yellow-400 fill-current"
                    : "text-grey-300 stroke-current"
                }`}
              >
                <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
              </svg>
            </div> */}

            {/* Coin Icon & Name */}
            <div className="flex items-center flex-1">
              <img
                src={getLogo(key, ctmarketlist)}
                alt={`${key} logo`}
                className="w-6 h-6 mr-3"
              />
              <div>
                <div className="font-medium">{key.toUpperCase()}</div>
                <div className="text-xs text-grey-500">
                  {ctmarketlist?.[key]?.name || ""}
                </div>
              </div>
            </div>

            {/* Price & Change */}
            <div className="text-right">
              <div className="font-medium">
                {typeof coin.close === "number"
                  ? coin.close.toLocaleString()
                  : "0"}
              </div>
              <div
                className={`text-sm ${
                  coin.close < coin.open ? "text-red-500" : "text-green-500"
                }`}
              >
                {coin.close < coin.open ? "" : "+"}
                {coin.close && coin.open
                  ? (((coin.close - coin.open) / coin.open) * 100).toFixed(2)
                  : "0.00"}
                %
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
