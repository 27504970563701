import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/topBar";
import { getText } from "../../utils/util";
import CenterPage from "./components/centerPage";
import {RekuLayout} from '@components/reku/layout'

export default function JYJL() {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  return (
    <div className="page">
      <RekuLayout
          title={translate(getText("交易記錄"))}
      >
        <CenterPage />
      </RekuLayout>
    </div>
  );
}
