import { useTranslation } from 'react-i18next';
import { getText } from '../../../../utils/util';
import { useEffect, useState } from 'react';
import { financeApi } from '@/api/finance-api';
import './index.css';

export default function RechargeOrderList() {
  const uid = localStorage.getItem('uid');
  const { t: translate } = useTranslation();
  const [czList, setCzList] = useState([] as any[]);
  const loadListRechargeData = async () => {
    const data = await financeApi.listRecharge({ uid });
    if (data.ok) {
      setCzList(data.data);
    }
  };

  useEffect(() => {
    loadListRechargeData();
  }, []);

  console.log(32233232, czList);

  const getCzNodes = () => {
    const nodes = [];
    for (const cz of czList) {
      const node = (
        <>
          <li class="rechargeorderlist-4">
            <div class="rechargeorderlist-5">
              <h1 class="rechargeorderlist-6">{cz.amount} USDT</h1>
              <div class="rechargeorderlist-7">
                <span class="rechargeorderlist-8">
                  <small class="rechargeorderlist-9"> {cz.createTime}</small>
                </span>
              </div>
            </div>
            {/*<div class="rechargeorderlist-10">
              <p class="rechargeorderlist-11">
                {translate(
                  getText(
                    cz.status === 1
                      ? "待審查"
                      : cz.status === 2
                      ? "已完成"
                      : "失敗"
                  )
                )}
              </p>
            </div>*/}
          </li>
        </>
      );
      nodes.push(node);
    }
    return nodes;
  };

  return (
    <div class="rechargeorderlist-1">
      <div class="rechargeorderlist-2">
        <ul class="rechargeorderlist-3"> {getCzNodes()}</ul>
      </div>
    </div>
  );
}
