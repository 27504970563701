import clsx from "clsx";

interface LinkProps extends React.PropsWithChildren<{}> {
  href: string;
  target?: "_blank";
  locale?: string;
  className?: string;
  onClick?: () => void;
  external?: boolean;
  ariaLabel?: string;
  dataTestId?: string;
}

export const Link: React.FC<LinkProps> = (props) => {
  return <a {...props} className={clsx("inline-block", props.className)} />;
};

export default Link;
