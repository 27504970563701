import { Header } from '@components/header'
import React from 'react'
import MyWallet from './components/myWallet/MyWallet'

const PropertyCenter: React.FC<{ userInfo: any; mockUserInfo: any }> = ({ userInfo, mockUserInfo }) => {
  return (
    <div className='w-full bg-background-neutral-main'>
      <Header></Header>
      <MyWallet userInfo={userInfo} mockUserInfo={mockUserInfo} />
    </div>
  )
}

export default PropertyCenter
