import { currencyApi } from "@/api/currency-api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Dialog } from "react-vant";
import { imageConfig } from "../../../../config/config";
import { getText } from "../../../../utils/util";
import C2CTk from "../c2ctk";

interface WithdrawCardListProps {
  coinList: any[];
  c2ctxStatus: string | null;
}

export default function WithdrawCardList({
  coinList,
  c2ctxStatus,
}: WithdrawCardListProps) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const lan = localStorage.getItem("i18n");
  const [visibleTK, setVisibleTK] = useState(false);
  const [currencylist, setcurrencylist] = useState([] as any[]);
  const [currencyIdTK, setcurrencyIdTK] = useState(0);
  const [bankType, setbankType] = useState(0);

  const loadCurrencyData = async () => {
    const data = await currencyApi.list({ pageNum: 1, pageSize: 100 });
    if (data.ok) {
      setcurrencylist(data.data?.records);
    }
  };
  useEffect(() => {
    loadCurrencyData();
  }, []);

  const getNode = () => {
    const nodes = [];
    for (let i = 0; i < coinList.length; i++) {
      const data = coinList[i];
      const node = (
        <div
          key={data.id}
          className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:-translate-y-0.5 cursor-pointer mb-4 w-full sm:w-[48%] lg:w-[31%] xl:w-[23%]"
          onClick={() => {
            navigate(`/extract/${data?.id}`);
          }}
        >
          <div className="p-3 md:p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-3">
              <div className="flex items-center gap-2 sm:gap-3 w-full sm:w-auto">
                <img
                  src={imageConfig.baseImageUrl + data?.img}
                  className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
                  alt={data.name}
                />
                <div className="text-sm sm:text-base font-medium text-gray-800 break-all">
                  {`${data.name.toUpperCase()}${
                    data.czline ? `(${data.czline})`.toUpperCase() : ""
                  }`}
                  {lan == "zh" && (
                    <span className="ml-1 text-gray-600">
                      {translate(getText("提現"))}
                    </span>
                  )}
                  {lan != "zh" && (
                    <div className="text-gray-600">
                      {translate(getText("提現"))}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto gap-2">
                <span className="px-2 sm:px-3 py-1 sm:py-1.5 bg-gray-50 rounded-full text-xs sm:text-sm text-gray-600 hover:bg-gray-100 whitespace-nowrap">
                  {translate(getText("提現"))}
                </span>
                <i className="fas fa-chevron-right text-gray-400"></i>
              </div>
            </div>
          </div>
        </div>
      );
      nodes.push(node);
    }
    return nodes;
  };

  return (
    <div className="flex flex-wrap justify-start gap-4">
      {getNode()}
      {c2ctxStatus == 1 && (
        <div
          className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:-translate-y-0.5 cursor-pointer mb-4 w-full sm:w-[48%] lg:w-[31%] xl:w-[23%]"
          onClick={() => {
            if (c2ctxStatus == 1) {
              setVisibleTK(true);
            }
          }}
        >
          <div className="p-3 md:p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-3">
              <div className="flex items-center gap-2 sm:gap-3 w-full sm:w-auto">
                <img
                  src="/level/20240216074328978161.png"
                  className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
                  alt="C2C"
                />
                <div className="text-sm sm:text-base font-medium text-gray-800">
                  {translate(getText("C2C提現"))}
                </div>
              </div>
              <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto gap-2">
                <span className="px-2 sm:px-3 py-1 sm:py-1.5 bg-gray-50 rounded-full text-xs sm:text-sm text-gray-600 hover:bg-gray-100 whitespace-nowrap">
                  {translate(getText("提現"))}
                </span>
                <i className="fas fa-chevron-right text-gray-400"></i>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* c2c提现款 */}
      <Dialog
        visible={visibleTK}
        title={translate(getText("温馨提示"))}
        showCancelButton
        confirmButtonText={translate(getText("确认"))}
        cancelButtonText={translate(getText("取消"))}
        onConfirm={() => {
          navigate(`/c2ctx/${currencyIdTK}T${bankType}`);
        }}
        onCancel={() => setVisibleTK(false)}
      >
        <C2CTk
          currencylist={currencylist}
          currencyId={currencyIdTK}
          setcurrencyId={setcurrencyIdTK}
          bankType={bankType}
          setbankType={setbankType}
        />
      </Dialog>
    </div>
  );
}
