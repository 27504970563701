import React from "react";

interface SupportProps {
  className?: string;
}

const Support: React.FC<SupportProps> = ({ className }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10.82c0-4.977 4.06-9 9.053-9s9.052 4.023 9.052 9a8.9 8.9 0 0 1-.585 3.19l-.078.208v.004l.02.164.406 3.298c.019.152.04.325.045.475.006.166-.002.425-.125.695a1.5 1.5 0 0 1-.772.754 1.5 1.5 0 0 1-.698.11 6 6 0 0 1-.474-.057l-.027-.004-3.186-.467-.169-.023h-.004l-.014.004-.2.074a9.1 9.1 0 0 1-3.727.56c-.953 2.348-3.217 4.015-5.885 4.015a6.3 6.3 0 0 1-2.083-.356l-.224-.078-.022-.007h-.005l-.168.023-2.995.409a1 1 0 0 1-1.111-1.207l.611-2.762c.021-.096.033-.148.04-.186l.001-.006-.007-.023a7 7 0 0 0-.08-.25 6.6 6.6 0 0 1-.326-2.057c0-2.626 1.533-4.909 3.755-5.93A9 9 0 0 1 5 10.82m2 .031q.312-.03.632-.031c3.54 0 6.368 2.933 6.368 6.5q0 .252-.019.5h.072a7.1 7.1 0 0 0 2.532-.465c.141-.053.308-.115.46-.148.14-.029.258-.041.4-.042.156-.001.314.022.44.041l.037.006 2.925.428-.37-3.01-.005-.034c-.015-.123-.034-.276-.03-.426a2 2 0 0 1 .046-.386 3 3 0 0 1 .163-.486 7 7 0 0 0 .454-2.478c0-3.86-3.151-7-7.052-7C10.15 3.82 7 6.96 7 10.82zm4.86 7.602q.14-.543.14-1.133c0-2.508-1.979-4.5-4.368-4.5s-4.369 1.992-4.369 4.5c0 .504.08.987.227 1.436l.018.055c.056.17.12.365.146.528.024.153.03.27.02.426a3 3 0 0 1-.078.47l-.008.04-.301 1.36 1.572-.215.036-.005c.124-.017.277-.038.423-.037.139.001.242.012.378.039.144.028.317.09.47.144l.05.017a4.2 4.2 0 0 0 1.416.242c2 0 3.71-1.394 4.217-3.319z"
      fill="currentColor"
    />
  </svg>
);

export default Support;
