import { homeApi } from "@/api/home-api";
import { useState, useEffect } from "react";

export interface Ctmarketlist {
  id: number;
  coinname: string;
  name: string;
  symbol: string;
  title: string;
  status: number;
  state: number;
  sort: number;
  addtime: string;
  publishTime: string | null;
  publishNum: number;
  companyId: number;
  logo: string;
  infoZh: string;
  infoEn: string;
  infoEs: string;
  infoAr: string;
  infoVi: string;
  infoJa: string;
  infoDe: string | null;
  infoFr: string | null;
  infoPt: string | null;
  infoIt: string | null;
  whitePaper: string | null;
  website: string | null;
  blockQuery: string | null;
}

export function useCtmarketlist() {
  const [ctmarketlist, setCtmarketlist] = useState([] as Ctmarketlist[]);
  const loadctmarketlistData = async () => {
    const data = await homeApi.ctmarketlist({ pageNum: 1, pageSize: 100 });
    if (data.ok) {
      const list = data.data.records;
      list.sort((d, e) => d.sort - e.sort);
      setCtmarketlist(list);
    }
  };
  useEffect(() => {
    loadctmarketlistData();
  }, []);

  return {
    ctmarketlist,
  };
}
