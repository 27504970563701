import React from "react";

interface WatchlistStarProps {
  className?: string;
}

const WatchlistStar: React.FC<WatchlistStarProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3545 1.77566C11.7628 1.58096 12.2372 1.58096 12.6456 1.77566C12.9986 1.94398 13.1932 2.23622 13.2914 2.39654C13.3929 2.56228 13.497 2.77342 13.5986 2.97921C13.6037 2.98972 13.6089 3.00022 13.6141 3.01069L15.7543 7.34648L20.5763 8.05129C20.8033 8.08442 21.0362 8.11841 21.2252 8.16384C21.4079 8.20779 21.7458 8.30284 22.0147 8.58668C22.3258 8.91505 22.4721 9.36626 22.4129 9.8147C22.3617 10.2023 22.1439 10.4776 22.0217 10.6204C21.8954 10.768 21.7267 10.9322 21.5624 11.0922L18.0745 14.4894L18.8975 19.2876C18.9364 19.5139 18.9762 19.746 18.9916 19.9398C19.0064 20.1272 19.0207 20.4781 18.834 20.8218C18.6181 21.2194 18.2342 21.4983 17.7893 21.5807C17.4047 21.652 17.0755 21.5299 16.9018 21.4579C16.7223 21.3834 16.5138 21.2737 16.3107 21.1668L12 18.8999L7.68936 21.1668C7.4862 21.2737 7.27778 21.3834 7.09823 21.4579C6.9246 21.5299 6.59531 21.652 6.21071 21.5807C5.76584 21.4983 5.38196 21.2194 5.16601 20.8218C4.97931 20.4781 4.99365 20.1272 5.00849 19.9398C5.02383 19.746 5.06369 19.5139 5.10254 19.2876L5.9255 14.4894L2.46283 11.1167C2.45448 11.1086 2.4461 11.1004 2.43771 11.0923C2.27335 10.9322 2.10469 10.768 1.97838 10.6204C1.85618 10.4776 1.63833 10.2023 1.58714 9.8147C1.52792 9.36626 1.67422 8.91505 1.98531 8.58668C2.25423 8.30284 2.59214 8.20779 2.7749 8.16384C2.96382 8.11841 3.19673 8.08442 3.42372 8.05129C3.43531 8.0496 3.44688 8.04791 3.45842 8.04622L8.24576 7.34648L10.386 3.01069C10.3911 3.00022 10.3963 2.98972 10.4015 2.97921C10.503 2.77342 10.6072 2.56228 10.7087 2.39654C10.8068 2.23622 11.0014 1.94398 11.3545 1.77566Z"
      fill="currentColor"
    />
  </svg>
);

export default WatchlistStar;
