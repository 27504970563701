import { create } from 'zustand';

interface SwitchAccountState {
  // 弹窗显示状态
  visible: boolean;
  // 当前账户类型 1:实际账户 2:模拟账户
  currentType: 1 | 2;
  
  // 操作方法
  setVisible: (visible: boolean) => void;
  switchAccountType: (type: 1 | 2) => void;
  updateBalances: (real: number, demo: number) => void;
  reset: () => void;
}

const initialState: Omit<SwitchAccountState, 'setVisible' | 'switchAccountType' | 'updateBalances' | 'reset'> = {
  visible: false,
  currentType: 1,
};

export const useSwitchAccountStore = create<SwitchAccountState>((set) => ({
  ...initialState,

  setVisible: (visible) => set({ visible }),
  
  switchAccountType: (type) => set({ currentType: type }),
  
  updateBalances: (real, demo) => set({
    realBalance: real,
    demoBalance: demo,
  }),
  
  reset: () => set(initialState),
}));
