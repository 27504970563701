import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd-mobile';
import { getText } from '../../../../utils/util';
import './index.css';
import { imageConfig } from '../../../../config/config';

export default function DownlandPage({ companyData }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const title = localStorage.getItem('title');
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-12 md:py-16">
        {/* 顶部背景图区域 - 重新设计 */}
        <div className="relative h-80 md:h-96 mb-16 rounded-3xl overflow-hidden bg-white">
          {/* 主背景 */}
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500 via-blue-600 to-indigo-600">
            {/* 装饰性圆形 */}
            <div className="absolute top-0 left-0 w-64 h-64 bg-white opacity-5 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute bottom-0 right-0 w-96 h-96 bg-blue-400 opacity-10 rounded-full translate-x-1/3 translate-y-1/3"></div>
          </div>

          {/* 波浪装饰 */}
          <div className="absolute bottom-0 left-0 right-0 h-24 opacity-30">
            <svg
              className="absolute bottom-0 w-full h-full"
              viewBox="0 0 1440 320"
              preserveAspectRatio="none"
            >
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,32L48,37.3C96,43,192,53,288,80C384,107,480,149,576,154.7C672,160,768,128,864,112C960,96,1056,96,1152,101.3C1248,107,1344,117,1392,122.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>
          </div>

          {/* 主要内容区域 */}
          <div className="relative h-full flex items-center justify-between px-8 md:px-12">
            {/* 左侧文字 */}
            <div className="text-white space-y-4 w-1/2">
              <h2 className="text-3xl md:text-4xl font-bold">
                {companyData?.companyName}
              </h2>
              <p className="text-blue-100 text-lg">
                {translate(getText('专业的数字资产交易平台'))}
              </p>
            </div>

            {/* 右侧图片 */}
            <div className="w-1/2 h-full flex items-center justify-end">
              <img
                src="/assets/downloadBg-fe9eece8.png"
                draggable="false"
                className="h-4/5 object-contain animate-float"
              />
            </div>
          </div>
        </div>

        {/* Logo区域 - 调整样式 */}
        <div className="text-center mb-12 transform -translate-y-24">
          <div className="inline-block bg-white p-6 rounded-2xl shadow-lg mb-4">
            <img
              src={imageConfig.baseImageUrl + companyData?.companyLogo}
              draggable="false"
              className="w-24 md:w-32 h-auto object-contain"
            />
          </div>
        </div>

        {/* 下载按钮区域 - 调整位置 */}
        <div className="max-w-md mx-auto space-y-4 transform -translate-y-12">
          {/* iOS下载按钮 */}
          <button
            onClick={() => {
              if (companyData?.iosDomain.includes('http')) {
                window.location.href = companyData?.iosDomain;
              }
            }}
            className="w-full bg-white hover:bg-gray-50 active:bg-gray-100 
              transition-all duration-200 rounded-xl shadow-lg hover:shadow-xl
              py-4 px-6 flex items-center justify-center space-x-3
              border border-gray-100 hover:scale-102"
          >
            <img
              src="/ios.png"
              draggable="false"
              className="w-6 h-6 object-contain"
            />
            <span className="text-lg font-medium text-gray-900">
              iOS {translate(getText('下載'))}
            </span>
          </button>

          {/* Android下载按钮 */}
          <button
            onClick={() => {
              if (companyData?.androidDomain.includes('http')) {
                window.location.href = companyData?.androidDomain;
              }
            }}
            className="w-full bg-white hover:bg-gray-50 active:bg-gray-100 
              transition-all duration-200 rounded-xl shadow-lg hover:shadow-xl
              py-4 px-6 flex items-center justify-center space-x-3
              border border-gray-100 hover:scale-102"
          >
            <img
              src="/anzhuo.png"
              draggable="false"
              className="w-6 h-6 object-contain"
            />
            <span className="text-lg font-medium text-gray-900">
              Android {translate(getText('下載'))}
            </span>
          </button>

          {/* 安装说明链接 */}
          <div className="text-center pt-4">
            <button
              onClick={() => {
                navigate('/downloadbook');
              }}
              className="text-blue-600 hover:text-blue-700 font-medium 
                underline-offset-4 hover:underline transition-colors"
            >
              {translate(getText('安装说明'))}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
