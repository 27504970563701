import React from "react";

interface CandleStickProps {
  className?: string;
}

const CandleStick: React.FC<CandleStickProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 1a1 1 0 0 1 1 1v2c.956 0 1.768.295 2.32.935.527.612.68 1.384.68 2.065v10c0 .681-.152 1.453-.68 2.065-.552.64-1.364.935-2.32.935v2a1 1 0 1 1-2 0v-2c-.957 0-1.768-.295-2.32-.935-.527-.612-.68-1.384-.68-2.065V7c0-.681.152-1.453.68-2.065.552-.64 1.364-.935 2.32-.935V2a1 1 0 0 1 1-1m-1 5c-.543 0-.732.155-.805.24-.098.113-.195.341-.195.76v10c0 .419.098.647.195.76.073.085.261.24.805.24h2c.544 0 .732-.155.805-.24.098-.113.195-.341.195-.76V7c0-.419-.098-.647-.195-.76-.073-.085-.261-.24-.805-.24zm-10-2a1 1 0 0 1 1 1v2c.956 0 1.768.295 2.32.935.527.612.68 1.384.68 2.065v6.5c0 .681-.152 1.453-.68 2.065-.552.64-1.364.935-2.32.935V22a1 1 0 1 1-2 0v-2.5c-.956 0-1.768-.295-2.32-.935-.528-.612-.68-1.384-.68-2.065V10c0-.681.152-1.453.68-2.065C3.732 7.295 4.544 7 5.5 7V5a1 1 0 0 1 1-1m-1 5c-.544 0-.732.155-.805.24-.098.113-.195.341-.195.76v6.5c0 .419.098.647.195.76.073.085.261.24.805.24h2c.544 0 .732-.155.805-.24.098-.113.195-.341.195-.76V10c0-.419-.098-.647-.195-.76C8.232 9.155 8.044 9 7.5 9z"
      fill="currentColor"
    />
  </svg>
);

export default CandleStick;
