import { useTranslation } from "react-i18next";
import { Link } from "@components/link";
import Container from "@components/container";
// import { Logo } from "@components/logo";
// import { useTheme } from "@emotion/react";
// import useEmbedView from "@hooks/useEmbedView";
import { Icons } from "@components/Icon";
import FacebookIcon from "./images/facebook";
import InstagramIcon from "./images/instagram";
import AbiIcon from "./images/abi";
import AspakrindoIcon from "./images/aspakrindo";
import Bappebti from "./images/bappebti";
import Iso from "./images/iso";
import Kominfo from "./images/kominfo";
import TelegramIcon from "./images/telegram";
import TwitterIcon from "./images/twitter";
// import { isHideFooter } from "./utils/footer";
import { useLocation } from "react-router-dom";

const Footer: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  // const isEmbed = useEmbedView();
  const isMobile = false;
  const isDarkmode = false;
  // 将所有 router.asPath 替换为 location.pathname
  // 将 router.query.symbol 替换为从 URL 中解析的值
  const coinCode = new URLSearchParams(location.search).get("symbol") || "";
  const settingsPath = "/settings";
  const tradePath = `/trade/${coinCode}`;
  const lightPath = `/lightning/price/${coinCode}`;
  const isTradPath = location.pathname === tradePath;
  const isLightPath = location.pathname === lightPath;
  const isSettingsPath = location.pathname.includes(settingsPath);
  // if (isHideFooter(router.pathname, isEmbed)) {
  //   return null;
  // }
  if (
    (isTradPath && isMobile) ||
    (isLightPath && isMobile) ||
    (isSettingsPath && isMobile)
  ) {
    return null;
  }
  const lists = [
    {
      title: t("components.footer.product.title"),
      links: [
        {
          title: t("components.footer.product.crypto_transaction"),
          url: "/markets",
        },
        {
          title: t("components.footer.product.withdraw"),
          url: "/help/cara-melakukan-withdraw-rupiah",
        },
        {
          title: t("components.footer.product.two_fa"),
          url: "/help/cara-mengaktifkan-2-factor-authentication",
        },
        {
          title: t("components.footer.product.affiliate"),
          url: "/settings/referral-program",
        },
      ],
    },
    {
      title: t("components.footer.information.title"),
      links: [
        {
          title: t("components.footer.information.trading_rules"),
          url: "/trading-rule",
        },
        {
          title: t("components.footer.information.fees"),
          url: "/fees",
        },
        {
          title: t("components.footer.information.coinmarketcap"),
          url: "https://coinmarketcap.com/exchanges/rekeningku-com/",
          newTab: true,
        },
        {
          title: t("components.footer.information.blog"),
          url: "/information",
        },
        {
          title: t("components.footer.information.terms"),
          url: "/help/syarat-dan-ketentuan-pelanggan-aset-kripto-rekeningku-com",
        },
        {
          title: t("components.footer.information.privacy_terms"),
          url: "/help/kebijakan-privasi-reku",
        },
        {
          title: t("components.footer.information.help"),
          url: "/help",
        },
        {
          title: "FAQ",
          url: "/help",
        },
        {
          title: t("components.footer.information.api"),
          url: "https://api.reku.id/",
          newTab: true,
        },
      ],
    },
    {
      title: t("components.footer.company.title"),
      links: [
        {
          title: t("components.footer.company.about_us"),
          url: "/about",
        },
        {
          title: t("components.footer.company.career"),
          url: "/careers",
        },
      ],
    },
  ];
  return (
    <section className="reku-new-theme py-6 pb-24 xl:py-8 bg-background-neutral-main dark:bg-dark-background-neutral-main">
      {/* <Container className="flex flex-col gap-6">
        <div className="flex flex-col xl:flex-row gap-8">
          <div className="xl:w-[414px] space-y-4 xl:space-y-6">
            <Logo
              logo={isDarkmode ? "LogotypeWhite" : "LogotypeDefault"}
              className="h-8 w-[99px]"
            /> 
            <div className="space-y-2">
              <div className="flex gap-2 text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                <Icons icon="Mail" width={20} height={20} />
                <a href="mailto:cs@reku.id">cs@reku.id</a>
              </div>
              <div className="flex gap-2 text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                <Icons icon="Location" width={20} height={20} />
                <a
                  href="https://www.google.com/maps/place/Reku+(Rekeningku.com)/@-6.2261139,106.8088843,15z/data=!4m2!3m1!1s0x0:0x4944702b0a9627ec"
                  target="_blank"
                  rel="noreferrer"
                >
                  Equity Tower - 11th Floor, Suite E <br /> SCBD - Jakarta
                  Selatan 12190
                </a>
              </div>
            </div>
            <div className="flex gap-4 xl:gap-6">
              <a
                href="https://fb.me/reku.id"
                target="_blank"
                rel="noreferrer"
                aria-label="Reku Facebook link"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/reku_id"
                target="_blank"
                rel="noreferrer"
                aria-label="Reku Instagram link"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://twitter.com/reku_id"
                target="_blank"
                rel="noreferrer"
                aria-label="Reku Twitter link"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://t.me/reku_id"
                target="_blank"
                rel="noreferrer"
                aria-label="Reku Telegram link"
              >
                <TelegramIcon />
              </a>
            </div>
          </div>
          <div className="flex-1 flex flex-col xl:flex-row gap-4 xl:justify-between">
            {lists.map((list) => (
              <div key={list.title} className="flex flex-col gap-1 flex-1">
                <span className="text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main mb-2">
                  {list.title}
                </span>
                {list.links.map((item) => (
                  <Link
                    href={item.url}
                    target={item.newTab ? "_blank" : undefined}
                    className="text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle !w-fit"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col xl:flex-row gap-4 xl:gap-10">
            <div className="space-y-2">
              <span className="text-body-sm text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                {t("components.footer.registed")}
              </span>
              <div className="flex gap-4">
                <Link
                  href="/help/rekeningku-com-telah-terdaftar-dan-diawasi-oleh-bappebti"
                  target="_blank"
                  ariaLabel="Bappebti's logo"
                >
                  <Bappebti height={48} />
                </Link>
                <Kominfo height={48} />
                <Link
                  href="https://www.cbqaglobal.com/validation/company/pt-rekeningku-dotcom-indonesia/"
                  target="_blank"
                  ariaLabel="ISO's logo"
                >
                  <Iso height={48} />
                </Link>
              </div>
            </div>
            <div className="space-y-2">
              <span className="text-body-sm text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                {t("components.footer.registed")}
              </span>
              <div className="flex gap-4">
                <a
                  href="https://asosiasiblockchain.co.id/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="ABI's logo"
                >
                  <AbiIcon />
                </a>
                <a
                  href="https://aspakrindo.org/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Aspakrindo's logo"
                >
                  <AspakrindoIcon height={48} />
                </a>
              </div>
            </div>
          </div>
          <p className="text-body-sm text-text-neutral-disabled dark:text-dark-text-neutral-disable">
            {t("footer.warning")}
          </p>
        </div>
        <div className="h-[1px] w-full bg-outline-neutral-main dark:bg-dark-outline-neutral-main" />
        <p className="text-body-sm text-text-neutral-disabled dark:text-dark-text-neutral-disabled text-center">
          © {new Date().getFullYear()} PT Rekeningku Dotcom Indonesia | All
          rights reserved.
        </p>
      </Container> */}
    </section>
  );
};
export default Footer;
