import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from '@components/header';
import { Tabs } from 'antd-mobile';
import { Line } from '@ant-design/charts';
import { message } from 'antd';
import { FollowModal } from '../components/FollowModal';
import { userApi } from '@/api/user-api';
import { followApi } from '@/api/followup';
import { isMobile } from 'react-device-detect';
import { PCFollowModal } from '../components/PCFollowModal';
import ProfitChart from './ProfitChart';

// Mock数据
const MOCK_TRADER_INFO = {
  id: '1',
  name: 'Isha Kimpel',
  avatar: '/followup/1.png',
  followerId: 4785,
  description:
    'Utilize advanced multi-market trading strategies to help clients maximize returns',
  profitRate: 50,
  profit: 6139981,
  totalProfit: 9945652.15,
  tradingDays: 475,
  managementSize: 3880383.7,
  minFollowAmount: 10,
  // 14日收益数据
  profitHistory: [
    { date: '12-17', profit: 420000 },
    { date: '12-18', profit: 260000 },
    { date: '12-19', profit: 190000 },
    { date: '12-20', profit: 185000 },
    { date: '12-21', profit: 360000 },
    { date: '12-22', profit: 220000 },
    { date: '12-23', profit: 450000 },
    { date: '12-24', profit: 370000 },
    { date: '12-25', profit: 390000 },
    { date: '12-26', profit: 480000 },
    { date: '12-27', profit: 330000 },
    { date: '12-28', profit: 460000 },
    { date: '12-29', profit: 340000 },
    { date: '12-30', profit: 460000 },
  ],
};

// Mock当前带单数据
const MOCK_CURRENT_ORDERS = [
  {
    id: '1',
    followerId: '12345',
    amount: 133479.28,
    downPrice: 93640.1,
    direction: 'buy', // 买入方向
    profit: 99909.24,
    profitRate: 5,
    status: 'active',
    period: '1min(20:21~20:22)',
  },
  {
    id: '2',
    followerId: '12346',
    amount: 150000,
    downPrice: 94200.5,
    direction: 'sell', // 卖出方向
    profit: 85000,
    profitRate: 8,
    status: 'active',
    period: '1min(20:25~20:26)',
  },
];

// Mock历史带单数据
const MOCK_HISTORY_ORDERS = [
  {
    id: '3',
    followerId: '12347',
    amount: 120000,
    downPrice: 92100.3,
    direction: 'buy',
    profit: 78500,
    profitRate: 15,
    status: 'completed',
    period: '1min(19:21~19:22)',
  },
  {
    id: '4',
    followerId: '12348',
    amount: 98000,
    downPrice: 91800.6,
    direction: 'sell',
    profit: -25000,
    profitRate: -2.5,
    status: 'completed',
    period: '1min(19:25~19:26)',
  },
];

const MOCK_USER_INFO = {
  uid: '12345',
  balance: 50000,
  name: 'Test User',
};

interface TraderDetailProps {}

const TraderDetail: FC<TraderDetailProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [traderInfo, setTraderInfo] = useState<any>(MOCK_TRADER_INFO);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mockUserInfo, setMockUserInfo] = useState<any>(MOCK_USER_INFO);

  const [currentOrders, setCurrentOrders] = useState<any[]>([]);
  const [historyOrders, setHistoryOrders] = useState<any[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userInfoData, setUserInfoData] = useState({});

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      setUserInfoData(data.data);
    }
  };

  const getMockUserInfo = async () => {
    const uid = localStorage.getItem('uid');
    const data = await userApi.mockUserInfo({ uid });
    if (data.ok) {
      console.log('faith=============data.data', data.data);
      const mock = [
        {
          'todayProfit': '453217.55',
          'statsDate': 1734825600000,
        },
        {
          'todayProfit': '190350.93',
          'statsDate': 1734912000000,
        },
        {
          'todayProfit': '96120.18',
          'statsDate': 1734998400000,
        },
        {
          'todayProfit': '262192.09',
          'statsDate': 1735084800000,
        },
        {
          'todayProfit': '197621.11',
          'statsDate': 1735171200000,
        },
        {
          'todayProfit': '200242.34',
          'statsDate': 1735257600000,
        },
        {
          'todayProfit': '202898.34',
          'statsDate': 1735344000000,
        },
        {
          'todayProfit': '204300.40',
          'statsDate': 1735430400000,
        },
        {
          'todayProfit': '206993.19',
          'statsDate': 1735516800000,
        },
        {
          'todayProfit': '211044.85',
          'statsDate': 1735603200000,
        },
        {
          'todayProfit': '258321.71',
          'statsDate': 1735689600000,
        },
        {
          'todayProfit': '266464.71',
          'statsDate': 1735776000000,
        },
        {
          'todayProfit': '206944.89',
          'statsDate': 1735862400000,
        },
        {
          'todayProfit': '274158.47',
          'statsDate': 1735948800000,
        },
      ];

      data.data.profitHistory = mock;
      console.log('faith=============data.data2', data.data.profitHistory);
      setMockUserInfo(data.data);
    }
  };

  // Mock获取当前带单数据
  const getCurrentOrders = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 300));
    // setCurrentOrders(MOCK_CURRENT_ORDERS);
    const data = await followApi.getHistoryOrders(id);
    if (data.ok) {
      setCurrentOrders(data.data);
    }
  };

  // Mock获取历史带单数据
  const getHistoryOrders = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 300));
    // setHistoryOrders(MOCK_HISTORY_ORDERS);
    const data = await followApi.getHistoryOrders(id);
    if (data.ok) {
      setHistoryOrders(data.data);
    }
  };

  const getMyFollowupOrder = async () => {
    const { code, level, msg, data } = await followApi.getMyFollowList();

    data.forEach((item: any) => {
      if (item.uid == id) {
        setButtonDisabled(true);
      }
    });
  };

  // Mock获取交易员信息
  const getTraderInfo = async () => {
    const data = await followApi.getTraderInfo(id);
    if (data.ok) {
      const mock = [
        {
          'todayProfit': '453217.55',
          'statsDate': 1734825600000,
        },
        {
          'todayProfit': '190350.93',
          'statsDate': 1734912000000,
        },
        {
          'todayProfit': '96120.18',
          'statsDate': 1734998400000,
        },
        {
          'todayProfit': '262192.09',
          'statsDate': 1735084800000,
        },
        {
          'todayProfit': '197621.11',
          'statsDate': 1735171200000,
        },
        {
          'todayProfit': '200242.34',
          'statsDate': 1735257600000,
        },
        {
          'todayProfit': '202898.34',
          'statsDate': 1735344000000,
        },
        {
          'todayProfit': '204300.40',
          'statsDate': 1735430400000,
        },
        {
          'todayProfit': '206993.19',
          'statsDate': 1735516800000,
        },
        {
          'todayProfit': '211044.85',
          'statsDate': 1735603200000,
        },
        {
          'todayProfit': '258321.71',
          'statsDate': 1735689600000,
        },
        {
          'todayProfit': '266464.71',
          'statsDate': 1735776000000,
        },
        {
          'todayProfit': '206944.89',
          'statsDate': 1735862400000,
        },
        {
          'todayProfit': '274158.47',
          'statsDate': 1735948800000,
        },
      ];

      data.data.profitHistory = mock;
      console.log('faith=============data.data', data.data);
      setTraderInfo(data.data);
    }
  };

  useEffect(() => {
    loadUserInfoData();
    getMyFollowupOrder();
    getTraderInfo();
    getCurrentOrders();
    getHistoryOrders();
    getMockUserInfo();
  }, [id]);

  // 优化后的图表配置
  const chartConfig = {
    data: traderInfo.profitHistory || [],
    xField: 'date',
    yField: 'profit',
    smooth: true,
    point: {
      size: 4,
      shape: 'circle',
    },
    color: '#1890ff',
    tooltip: {
      formatter: (datum: any) => {
        return {
          name: t('收益'),
          value: `$${datum.profit.toLocaleString()}`,
        };
      },
    },
    xAxis: {
      label: {
        rotate: 45,
        offset: 10,
        style: {
          fontSize: 12,
        },
      },
    },
    yAxis: {
      label: {
        formatter: (value: string) => {
          const num = parseInt(value);
          return `${num / 1000}k`;
        },
      },
    },
    padding: [20, 20, 50, 40], // 调整内边距，为旋转的标签留出空间
  };

  // Mock跟单操作
  const handleFollow = async (amount: number) => {
    try {
      // 模拟API延迟
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (amount > mockUserInfo.balance) {
        message.error(t('餘額不足'));
        return;
      }

      message.success(t('跟單成功'));
      setIsModalVisible(false);
    } catch (error) {
      message.error(t('操作失敗'));
    }
  };

  // 渲染当前带单列表
  // 渲染当前带单列表
  const renderCurrentOrders = () => (
    <div className={`${isMobile ? 'space-y-3 px-3' : 'flex flex-wrap gap-8'}`}>
      {currentOrders
        .filter((order) => order.status == 0 || order.status == 1)
        .map((order) => (
          <div
            key={order.id}
            className={`relative bg-gradient-to-br from-white to-emerald-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ${
              !isMobile ? 'w-[340px]' : ''
            } border-l-4 border-emerald-400 hover:translate-y-[-2px]`}
          >
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('幣種')}</div>
                <div className="text-gray-800 font-medium">
                  {order.coin || 'BTC'}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('收益')}</div>
                <div className="text-emerald-500 font-medium">
                  {order.isWin === 1 ? order.ploss : -order.ploss} USDT
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單週期')}</div>
                <div className="text-gray-800 font-medium">{order.time}</div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單方向')}</div>
                <div
                  className={`font-medium ${
                    order.hyzd === 1 ? 'text-emerald-500' : 'text-rose-500'
                  }`}
                >
                  {order.hyzd === 1 ? t('買') : t('賣')}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單價格')}</div>
                <div className="text-gray-800 font-medium">
                  {order.buyprice}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單金額')}</div>
                <div className="text-gray-800 font-medium">{order.num}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );

  // 渲染历史带单列表
  const renderHistoryOrders = () => (
    <div className={`${isMobile ? 'space-y-3 px-3' : 'flex flex-wrap gap-8'}`}>
      {historyOrders
        .filter((order) => order.status == 2 || order.status == 4)
        .map((order) => (
          <div
            key={order.id}
            className={`relative bg-gradient-to-br from-white to-emerald-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ${
              !isMobile ? 'w-[340px]' : ''
            } border-l-4 border-emerald-400 hover:translate-y-[-2px]`}
          >
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('收益')}</div>
                <div
                  className={`font-medium ${
                    order.isWin === 1 ? 'text-emerald-500' : 'text-rose-500'
                  }`}
                >
                  {order.isWin === 1 ? order.ploss : -order.ploss} USDT
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單週期')}</div>
                <div className="text-gray-800 font-medium">{order.time}</div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單方向')}</div>
                <div
                  className={`font-medium ${
                    order.hyzd === 1 ? 'text-emerald-500' : 'text-rose-500'
                  }`}
                >
                  {order.hyzd === 1 ? t('買') : t('賣')}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單價格')}</div>
                <div className="text-gray-800 font-medium">
                  {order.buyprice}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-gray-500">{t('下單金額')}</div>
                <div className="text-gray-800 font-medium">{order.num}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
  // 处理名称显示的函数
  const formatTraderName = (name: string) => {
    if (name.length <= 9) return name;
    const start = name.slice(0, 9);
    const end = name.slice(-7);
    return `${start}...${end}`;
  };

  return (
    <div
      className={`min-h-screen bg-gray-50 pb-20 ${
        !isMobile ? 'flex justify-center' : ''
      }`}
    >
      <div className={`${!isMobile ? 'max-w-[1200px] w-full' : ''}`}>
        <Header />
        {/* 导航 */}
        <div className="flex items-center justify-between px-4 py-2 text-gray-500 text-xs">
          <div>
            <span
              className="cursor-pointer hover:text-blue-500 transition-colors"
              onClick={() => navigate('/followup/btc')}
            >
              {t('跟單交易')}
            </span>
            {' > '}
            <span>{t('交易員詳情')}</span>
          </div>
          <button
            onClick={() => navigate('/followup/btc')}
            className="text-gray-500 hover:text-blue-500 transition-colors"
          >
            ← {t('返回')}
          </button>
        </div>

        {/* 基本信息 */}
        {isMobile && (
          <div className="bg-white rounded-lg p-4 mb-2 shadow-sm">
            <div className="flex items-start gap-4">
              <img
                src={'/followup/2.png'}
                className="w-16 h-16 rounded-full object-cover border-2 border-[#4ea5ab]/20"
                alt="avatar"
              />
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2">
                  <span className="font-semibold text-sm text-gray-900 truncate">
                    {formatTraderName(traderInfo?.name)}
                  </span>
                  {/* VIP图标可以添加在这里 */}
                </div>

                <div className="mt-1.5 flex items-center text-sm text-gray-500">
                  <span className="flex items-center">
                    ID:{' '}
                    <span className="text-[#4ea5ab] ml-1">
                      {traderInfo.followCount}
                    </span>
                    <span className="mx-1">/</span>
                    <span>{traderInfo.peopleCount}</span>
                  </span>
                </div>

                <div className="mt-1.5 text-sm text-gray-500 line-clamp-2">
                  {traderInfo.description}
                </div>
              </div>
            </div>
          </div>
        )}

        {!isMobile && (
          <div className="bg-white mb-2 px-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img
                  // src={traderInfo.avatar}
                  src={'/followup/4.png'}
                  className="w-20 h-20 rounded-full object-cover"
                  alt="avatar"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-sm">
                      {formatTraderName(traderInfo?.name)}
                    </span>
                    {/* <img src="/followup/vip-icon.svg" className="w-5 h-5" alt="vip" /> */}
                  </div>
                  <div className="text-gray-500 text-xs mt-1">
                    {traderInfo.followCount}/{traderInfo?.peopleCount}
                  </div>
                  <div className="text-gray-500 text-xs mt-1">
                    {traderInfo.description}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                {/* <button className="px-6 py-2 border border-black rounded-full">
                  关注
                </button> */}
                <button
                  disabled={buttonDisabled || userInfoData.merchandiser === 1}
                  style={{
                    backgroundColor:
                      buttonDisabled || userInfoData.merchandiser === 1
                        ? '#E5E7EB'
                        : '',
                  }}
                  className="px-6 py-2 bg-[#4ea5ab] text-white rounded-full"
                  onClick={() => setIsModalVisible(true)}
                >
                  跟单
                </button>
              </div>
            </div>
          </div>
        )}

        {/* 数据统计 */}
        <div className="bg-white p-4 mb-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="text-gray-500 text-xs">{t('30天盈虧率')}</div>
              <div className="text-green-500 text-sm font-bold">
                +{traderInfo.monthProfitRate}%
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">{t('30天收益')}</div>
              <div className="text-green-500 text-sm font-bold">
                ${traderInfo.monthProfit?.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">{t('盈利金額')}</div>
              <div className="font-bold">
                ${traderInfo.profit?.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">{t('帶單天數')}</div>
              <div className="font-bold">{traderInfo.days}</div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">
                {t('當前資金管理規模')}
              </div>
              <div className="font-bold">
                ${traderInfo.totalAmount?.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">{t('最低跟隨金額')}</div>
              <div className="font-bold">
                ${traderInfo.minInvest?.toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        {/* 标签页 */}
        <div className="bg-white min-h-[500px]">
          <Tabs>
            <Tabs.Tab title={t('交易資料')} key="info">
              <div className="px-2">
                <div className="text-sm font-bold mb-2">{t('14日收益')}</div>
                <div className="flex flex-col">
                  {/* 最高和最低收益显示 */}
                  <div className="grid grid-cols-2 gap-4 mb-2">
                    <div className="bg-green-50 rounded-lg p-3">
                      <div className="text-xs text-gray-500 mb-1">
                        {t('期間最高收益')}
                      </div>
                      <div className="text-sm font-bold text-green-500">
                        $
                        {Math.max(
                          ...traderInfo.profitHistory.map((item) => item.profit)
                        ).toLocaleString()}
                      </div>
                    </div>
                    <div className="bg-red-50 rounded-lg p-3">
                      <div className="text-xs text-gray-500 mb-1">
                        {t('期間最低收益')}
                      </div>
                      <div className="text-sm font-bold text-red-500">
                        $
                        {Math.min(
                          ...traderInfo.profitHistory.map((item) => item.profit)
                        ).toLocaleString()}
                      </div>
                    </div>
                  </div>

                  {/* 收益数据列表 */}
                  <div className="bg-gray-50 rounded-lg pb-7">
                    <ProfitChart data={traderInfo.profitHistory} />
                  </div>
                </div>
              </div>
            </Tabs.Tab>
            <Tabs.Tab title={t('當前帶單')} key="current">
              <div>
                {currentOrders.length > 0 ? (
                  renderCurrentOrders()
                ) : (
                  <div className="text-center text-gray-500 py-8">
                    {t('暫無帶單記錄')}
                  </div>
                )}
              </div>
            </Tabs.Tab>
            <Tabs.Tab title={t('歷史帶單')} key="history">
              <div>
                {historyOrders.length > 0 ? (
                  renderHistoryOrders()
                ) : (
                  <div className="text-center text-gray-500 py-8">
                    {t('暫無歷史記錄')}
                  </div>
                )}
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>

        {/* 底部固定按钮 */}
        {isMobile && (
          <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t">
            <button
              disabled={buttonDisabled || userInfoData.merchandiser === 1}
              style={{
                backgroundColor:
                  buttonDisabled || userInfoData.merchandiser === 1
                    ? '#E5E7EB'
                    : '',
              }}
              onClick={() => setIsModalVisible(true)}
              className="w-full py-3 bg-[#4ea5ab] hover:bg-[#03b989] active:bg-[#03a57a] text-white rounded-full font-medium transition-colors duration-200"
            >
              {t('跟單')}
            </button>
          </div>
        )}

        {isModalVisible &&
          (isMobile ? (
            <FollowModal
              mockUserInfo={mockUserInfo}
              trader={traderInfo}
              isVisible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              onConfirm={handleFollow}
            />
          ) : (
            <PCFollowModal
              mockUserInfo={mockUserInfo}
              trader={traderInfo}
              isVisible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              onConfirm={handleFollow}
            />
          ))}
      </div>
    </div>
  );
};

export default TraderDetail;
