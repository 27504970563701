export const menuList = [
  // demo
  { key: '首页', href: '/' },
  { key: '行情', href: '/quotation' },
  { key: '交割合约', href: '/trade/btc' },
  // { key: '永续', href: '/lever/btc' },
  { key: '跟單', href: '/followup/btc' },
  { key: '资产', href: '/propertycenter' },
  // { key: "交易记录", href: "/jyjl" },
  // { key: "分享中心", href: "/sharecenter" },
];
