// src/api/followup.js
import { getRequest } from '../lib/axios';

export const followApi = {
  /**
   * 获取我的跟单列表
   */
  getMyFollowList: () => {
    const uid = localStorage.getItem('uid');
    return getRequest('/api/pc/mcd/my/follow', { uid });
  },

  /**
   * 获取交易员列表
   */
  getTraderList: () => {
    const uid = localStorage.getItem('uid');
    return getRequest('/api/pc/mcd/list', {uid});
  },

  /**
   * 取消跟单
   * @param {string} followUid - 被跟单的交易员uid
   */
  cancelFollow: (followUid) => {
    const uid = localStorage.getItem('uid');
    return getRequest('/api/pc/mcd/follow/delete', {
      followUid,
      uid,
    });
  },

  /**
   * 添加跟单
   * @param {string} followUid - 要跟单的交易员uid
   * @param {number} investProp - 跟投金额
   */
  addFollow: (followUid, investProp) => {
    const uid = localStorage.getItem('uid');
    return getRequest('/api/pc/mcd/follow/add', {
      followUid,
      uid,
      investProp,
    });
  },

  /**
   * 申请成为交易员
   */
  applyTrader: () => {
    const uid = localStorage.getItem('uid');
    return getRequest('/api/pc/mcd/apply', { uid });
  },

  /**
   * 获取历史跟单订单
   * @param {string} uid - 用户ID
   */
  getHistoryOrders: (uid) => {
    return getRequest('/api/pc/contract/follow/hyorder', { uid });
  },

  getTraderInfo: (uid) => {
    return getRequest('/api/pc/mcd/info', {uid});
  },

  getMcdUserInfo: (uid) => {
    return getRequest('/api/pc/mcd/userinfo', {uid});
  },

  getMcdUserInfoList: (uid) => {
    return getRequest('/api/pc/mcd/follow/list', {uid});
  },
};
