import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function CenterPage({ help }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const lan = localStorage.getItem("i18n");
  const name = lan == "zh" ? "" : lan.charAt(0).toUpperCase() + lan.slice(1);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 md:py-12">
      <div className="bg-white rounded-2xl shadow-sm p-6 md:p-8">
        {/* 标题区域 */}
        <h1 className="text-2xl md:text-3xl font-medium text-gray-900 mb-6 text-center">
          {help[`type${name}`]}
        </h1>

        {/* 分隔线 */}
        <div className="w-24 h-1 bg-blue-500 mx-auto mb-8 rounded-full opacity-75" />

        {/* 内容区域 */}
        <div className="prose prose-lg max-w-none">
          <div
            className="text-gray-700 leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: help[`content${name}`],
            }}
          />
        </div>
      </div>
    </div>
  );
}
