import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "antd-mobile";
import { getText } from "../../../../utils/util";
import "./index.css";
import { imageConfig } from "../../../../config/config";

export default function DownlandPage({ companyData }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const title = localStorage.getItem("title");
  const lan = localStorage.getItem("i18n");
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* 主容器 */}
      <div className="max-w-5xl mx-auto px-4 py-12 md:py-20">
        {/* Logo区域 */}
        <div className="flex justify-center mb-12 md:mb-16 animate-fadeIn">
          <div className="relative w-48 md:w-64 h-24 md:h-32">
            <img
              src={imageConfig.baseImageUrl + companyData?.companyLogo}
              draggable="false"
              className="w-full h-full object-contain filter drop-shadow-lg"
            />
          </div>
        </div>

        {/* 内容区域 */}
        <div className="bg-white rounded-3xl shadow-xl p-8 md:p-12 animate-slideUp">
          <div className="prose prose-lg max-w-none">
            {/* 标题 */}
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 text-center mb-8">
              {translate(getText("关于我们"))}
            </h1>

            {/* 段落区域 */}
            <div className="space-y-6 text-gray-600 leading-relaxed">
              {/* 第一段 */}
              <p className="text-lg md:text-xl font-medium">
                <span className="text-blue-600">
                  {companyData?.companyName}
                </span>
                {translate(
                  getText(
                    "全球專業站，一個服務全球專業交易用戶的創新數字資產交易平台，致力於發現優質創新數字資產投資機會。目前提供四十多種數字資產產品交易和投資服務。總部位於加拿大，由"
                  )
                )}
                <span className="text-blue-600">
                  {companyData?.companyName}
                </span>
                {translate(getText("全球专业站团队负责运营。"))}
              </p>

              {/* 第二段 */}
              <p className="relative pl-4 border-l-4 border-blue-500 bg-blue-50 p-4 rounded-r-lg">
                <span className="text-blue-600">
                  {companyData?.companyName}
                </span>
                {translate(
                  getText(
                    "是全球領先的區塊鏈資產金融服務商。它已經為來自全球130多個國家的數百萬用戶提供了高質量的服務。在新加坡、韓國、香港、中國大陸等國家和地區設有獨立的辦事處。交易業務和運營中心。"
                  )
                )}
              </p>

              {/* 第三段 */}
              <p className="text-lg bg-gradient-to-r from-gray-50 to-white p-6 rounded-xl shadow-sm">
                <span className="text-blue-600">
                  {companyData?.companyName}
                </span>
                {translate(
                  getText(
                    "及旗下子品牌在技术平台、产品支线、安全风控体系、运营及客户服务体系等方面均处于全球领先地位。"
                  )
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
