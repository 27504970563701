import { Empty } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { newsApi } from "../../../../api/news-api";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function CenterPage({ hyorders }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const lan = localStorage.getItem("i18n");

  const getNode = () => {
    const nodes = [];
    for (let index = 0; index < hyorders.length; index++) {
      const data = hyorders[index];
      const node = (
        <li
          key={data.id}
          className={`bg-white rounded-lg p-4 mb-4 transition-all duration-200 border border-gray-100
            ${
              data.status != 1
                ? "cursor-pointer hover:shadow-lg hover:-translate-y-0.5"
                : "opacity-80"
            }`}
          onClick={() => {
            const sendData = {
              orderNo: data.orderNo,
              coinname: data.coinname,
              num: data.num,
              ploss: data.ploss,
              buytime: data.buytime,
              status: data.status,
              isWin: data.isWin,
            };
            if (data.status == 1) return;
            navigate(
              `/marketOrderInfo/${data.id}?data=${JSON.stringify(sendData)}`
            );
          }}
        >
          {/* Header Section */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
              <span className="text-lg font-semibold text-gray-900">
                {data.coinname}
              </span>
              <div
                className={`px-2 py-1 rounded-full text-sm font-medium
                ${
                  data.hyzd == 1
                    ? "bg-green-50 text-green-600"
                    : "bg-red-50 text-red-600"
                }`}
              >
                {data.hyzd == 1
                  ? translate(getText("買多"))
                  : translate(getText("買空"))}
              </div>
            </div>
            <div className="text-sm text-gray-500">
              {data.buytime?.substring(0, 16)}
            </div>
          </div>

          {/* Amount Section */}
          <div className="flex justify-between items-center py-3 border-b border-gray-100">
            <div className="flex items-baseline">
              <span className="text-xl font-bold">{data.num.toFixed(2)}</span>
              <span className="ml-1 text-sm text-gray-500">USDT</span>
            </div>
            <div className="flex items-center">
              <span className="text-gray-600 mr-2">
                {translate(getText("盈虧"))}
              </span>
              {data.status != 1 && (
                <span
                  className={`flex items-center font-medium
                  ${data.isWin == 1 ? "text-green-600" : "text-red-600"}`}
                >
                  {data.isWin == 1 ? (
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 10l7-7m0 0l7 7m-7-7v18"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                      />
                    </svg>
                  )}
                  <span>
                    {data.isWin == 1 ? "+" : "-"}
                    {data.ploss}
                  </span>
                </span>
              )}
            </div>
          </div>

          {/* Price Info Grid */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="flex flex-col">
              <div className="text-sm text-gray-500 mb-1">
                {translate(getText("購買"))}
                {lan == "zh" ? "" : <span>&nbsp;</span>}
                {translate(getText("價格"))}
              </div>
              <div className="font-medium text-gray-900">{data.buyprice}</div>
            </div>
            <div className="flex flex-col">
              <div className="text-sm text-gray-500 mb-1">
                {translate(getText("結算價格"))}
              </div>
              <div className="font-medium text-gray-900">{data.sellprice}</div>
            </div>
            <div className="flex flex-col">
              <div className="text-sm text-gray-500 mb-1">
                {translate(getText("周期"))}
              </div>
              <div className="font-medium text-gray-900">{data.time}</div>
            </div>
          </div>
        </li>
      );
      nodes.push(node);
    }
    return nodes;
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {getNode()}
      </ul>
    </div>
  );
}
