import { useState, useEffect } from 'react';
import { coinApi } from '@/api/coin-api';

export const useCoinList = () => {
  const [coinList, setCoinList] = useState<any[]>([]);

  const loadCoinData = async () => {
    const data = await coinApi.list();
    if (data.ok) {
      data.data.sort((a, b) => a.sort - b.sort);
      setCoinList(data.data);
    }
  };

  useEffect(() => {
    loadCoinData();
  }, []);

  return {
    coinList,
    loadCoinData
  };
};
