import { Image, Input } from "antd";
import { Button, Upload } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getText } from "../../../../utils/util";
import { imageConfig } from "../../../../config/config";
import { Toast } from "antd-mobile";

export default function CenterPage({ sendMsg, listData }) {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const messagesRef = useRef(null);
  const { t: translate } = useTranslation();
  const [length, setLength] = useState(0);
  //上传
  const handleChange = (info) => {
    if (info.file.status === "done") {
      if (info.file.response && info.file.response.ok) {
        Toast.show({
          content: `${info.file.name} ${translate(getText("上傳成功"))}`,
        });
        const data = info.file.response;
        sendMsg(`image:${data.data}`);
      } else {
        Toast.show({ content: translate(getText(`上传失败`)) });
      }
    } else if (info.file.status === "error") {
      Toast.show({ content: translate(getText(`上传失败`)) });
    }
  };
  //自动滚动到底部
  const scrollDiv = () => {
    if (messagesRef.current) {
      // 使用 scrollHeight 属性来获取整个滚动内容的高度
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };
  //加载节点
  const getNodes = () => {
    const nodes = [];
    for (const data of listData) {
      const node =
        data.type === 1 ? (
          <div className="flex w-full mb-4">
            <div className="flex max-w-[80%]">
              <div className="w-10 h-10 flex-shrink-0">
                <img
                  src="/aichat/80b12be3-46df-4489-aa71-4bf784752950.jpg"
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="ml-2 bg-white rounded-lg shadow-sm">
                <div className="p-3">
                  {data.content.startsWith("image:") ? (
                    <div className="p-1">
                      <Image
                        width="100px"
                        height="100px"
                        src={
                          imageConfig.baseImageUrl +
                          data.content.replace("image:", "")
                        }
                      />
                    </div>
                  ) : (
                    <div className="p-2 rounded whitespace-pre-line">
                      {data.content}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full mb-4 justify-end">
            <div className="flex max-w-[80%]">
              <div className="mr-2 bg-blue-50 rounded-lg shadow-sm">
                <div className="p-3">
                  {data.content.startsWith("image:") ? (
                    <div className="p-1">
                      <Image
                        width="100px"
                        height="100px"
                        src={
                          imageConfig.baseImageUrl +
                          data.content.replace("image:", "")
                        }
                      />
                    </div>
                  ) : (
                    <div className="p-2 rounded whitespace-pre-line">
                      {data.content}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      nodes.push(node);
    }
    //滚动
    if (length < listData.length) {
      setTimeout(() => {
        scrollDiv();
      }, 1000);
      setLength(listData.length);
    }
    return nodes;
  };

  return (
    <div className="flex flex-col min-h-[44vh] h-full bg-gray-50">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="h-full flex-1 flex flex-col">
          {/* 头部 */}
          <div className="bg-white p-4 border-b">
            <div className="flex items-center">
              <div className="w-12 h-12">
                <img
                  src="/aichat/80b12be3-46df-4489-aa71-4bf784752950.jpg"
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="ml-3">
                <div className="font-medium">Lorna</div>
                <div className="text-sm text-gray-500">
                  {translate(getText("online service"))}
                </div>
              </div>
            </div>
          </div>

          {/* 消息区域 */}
          <div className="flex-1 min-h-0 overflow-y-auto p-4" ref={messagesRef}>
            <div className="text-center text-gray-500 text-sm mb-4">
              {translate(getText("请直接提交您的问题,等待客服回复即可"))}
            </div>
            {getNodes()}
          </div>

          {/* 输入区域 */}
          <div className="border-t bg-white p-4">
            <div className="flex items-end">
              <div className="flex-1">
                <textarea
                  placeholder="Message"
                  className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                  rows={3}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </div>
              <div className="ml-3 flex items-center">
                <Upload
                  name="file"
                  accept="image/*"
                  showUploadList={false}
                  action={imageConfig.uploadUrl}
                  onChange={handleChange}
                >
                  <button className="p-2 hover:bg-gray-100 rounded-full">
                    <PlusCircleOutlined className="text-xl text-gray-600" />
                  </button>
                </Upload>
                <button
                  className="ml-2 bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600"
                  onClick={() => {
                    setContent("");
                    sendMsg(content);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-2 text-center text-sm text-gray-500">
              <span>Chat </span>
              <img
                src="/aichat/favicon.ico"
                className="inline-block w-4 h-4 mx-1"
              />
              <span> by aichat.net</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
