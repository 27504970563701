import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getText } from "../../../../utils/util";
import copy from "copy-to-clipboard";
import { Cell, Dialog } from "react-vant";
import "./index.css";
import { Button } from "antd";
import { Toast } from "antd-mobile";
import { useState } from "react";
import { imageConfig } from "../../../../config/config";
import WithdrawCardList from "../WithdrawCardList";

export default function CenterPage({
  coinList,
  userInfo,
  qbSum,
  setVisible,
  setVisibleTK,
  setVisibleTK2,
  setVisibleCK,
  isShowZF,
}) {
  const c2ctxStatus = localStorage.getItem("c2ctxStatus");

  return (
    <div className="w-full px-4 sm:px-6 py-4">
      <div className="max-w-7xl mx-auto">
        <WithdrawCardList
          coinList={coinList}
          c2ctxStatus={c2ctxStatus}
        />
      </div>
    </div>
  );
}
