import React from "react";

interface BankIconProps {
  className?: string;
  onClick?: () => void;
}

const Bank: React.FC<BankIconProps> = ({ className, onClick }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-6 h-6 xl:w-7 xl:h-7 ${className}`}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.825 2.032a2 2 0 0 1 .35 0c.133.012.261.04.362.063l.027.006 7.427 1.65c.2.045.4.09.568.14.188.056.402.138.61.288a2 2 0 0 1 .669.834c.101.236.135.463.15.658.012.176.012.38.012.585v1.176c0 .252 0 .498-.017.706a2 2 0 0 1-.201.77 2 2 0 0 1-.874.874 2 2 0 0 1-.77.201q-.066.006-.138.009v6.016l.138.009c.229.019.499.063.77.201a2 2 0 0 1 .874.874c.138.271.182.541.201.77.017.208.017.454.017.706v.864c0 .252 0 .498-.017.706a2 2 0 0 1-.201.77 2 2 0 0 1-.874.874 2 2 0 0 1-.77.201c-.208.017-.454.017-.706.017H4.568c-.252 0-.498 0-.706-.017a2 2 0 0 1-.77-.201 2 2 0 0 1-.874-.874 2 2 0 0 1-.201-.77C2 19.93 2 19.684 2 19.432v-.864c0-.252 0-.498.017-.706a2 2 0 0 1 .201-.77 2 2 0 0 1 .874-.874 2 2 0 0 1 .77-.201L4 16.008V9.992l-.138-.009a2 2 0 0 1-.77-.201 2 2 0 0 1-.874-.874 2 2 0 0 1-.201-.77C2 7.93 2 7.684 2 7.432V6.256c0-.205 0-.41.013-.585.014-.195.048-.422.15-.658a2 2 0 0 1 .668-.834 2 2 0 0 1 .61-.288 8 8 0 0 1 .595-.146l7.4-1.644.027-.006a3 3 0 0 1 .362-.063M6 10v6h2.5v-6zM4.6 8a8 8 0 0 1-.589-.011v-.014A8 8 0 0 1 4 7.4V6.283a7 7 0 0 1 .008-.474l.009-.003c.09-.027.215-.055.453-.108l7.4-1.645.13-.028h.002l.128.028 7.4 1.645a7 7 0 0 1 .462.11v.01a7 7 0 0 1 .008.465V7.4c0 .297 0 .459-.01.575l-.001.014h-.014C19.86 8 19.696 8 19.4 8zm5.9 2v6h3v-6zm5 0v6H18v-6zM4.6 18a8 8 0 0 0-.589.012v.013C4 18.14 4 18.304 4 18.6v.8a8 8 0 0 0 .011.588l.014.002c.116.01.278.01.575.01h14.8c.297 0 .459 0 .575-.01l.014-.001v-.014c.01-.116.011-.279.011-.575v-.8a8 8 0 0 0-.012-.588l-.013-.002A8 8 0 0 0 19.4 18z"
      fill="currentColor"
    />
  </svg>
);

export default Bank;
