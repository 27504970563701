import { Badge } from "antd";
import { Card } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function CenterPage({
  listData,
  readAll,
  delAll,
  readOne,
  delOne,
}) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const la = localStorage.getItem("i18n");

  return (
    <div className="max-w-4xl mx-auto px-4 py-6">
      {/* 消息列表 */}
      <div className="space-y-4">
        {listData.map((data) => (
          <div className="transition-all duration-200 hover:transform hover:-translate-y-0.5">
            {data[la === "zh" ? "content" : "contentEn"] && (
              <Card
                className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow"
                title={
                  <div className="flex items-center py-3">
                    <Badge
                      count={data.status == 1 ? 2 : 0}
                      offset={[5, 0]}
                      dot
                      size={"default"}
                      className="flex items-center"
                    >
                      <span className="text-lg font-medium text-gray-900">
                        {data[la === "zh" ? "title" : "titleEn"]}
                      </span>
                    </Badge>
                  </div>
                }
                bodyStyle={{
                  padding: "16px",
                  fontSize: "14px",
                  lineHeight: "1.6",
                  color: "#374151",
                }}
                headStyle={{
                  borderBottom: "1px solid #E5E7EB",
                  padding: "0 16px",
                }}
              >
                <div className="prose prose-sm max-w-none">
                  {data[la === "zh" ? "content" : "contentEn"]}
                </div>
              </Card>
            )}
          </div>
        ))}
      </div>

      {/* 空状态 */}
      {listData.length === 0 && (
        <div className="flex flex-col items-center justify-center py-16">
          <div className="w-48 h-48 mb-6">
            <img
              alt=""
              src="/noice/empty-image-default.png"
              className="w-full h-full object-contain opacity-75"
            />
          </div>
          <p className="text-gray-500 text-base">
            {translate(getText("無留言記錄"))}
          </p>
        </div>
      )}
    </div>
  );
}
