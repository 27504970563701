import { ArrowLeftOutlined } from '@ant-design/icons';
import Footer from '@components/Footer';
import { Header } from '@components/header';
import { useNavigate } from 'react-router-dom';
import './index.css';

export const RekuLayout = ({ children, title }) => {
  const navigate = useNavigate();
  return (
    <div className="layout">
      <Header />
      <div className="container mx-auto">
        <div
          className={
            'flex justify-between items-center px-4 mt-8 mb-2' +
            ' text-sm text-gray-500 border-b border-gray-300 relative' +
            ' h-12'
          }
        >
          <div
            className={
              'absolute left-0 top-1/2 -translate-y-1/2' +
              ' w-8 flex' +
              ' items-center'
            }
          >
            <button className="text-blue-500 p-3" onClick={() => navigate(-1)}>
              <ArrowLeftOutlined />
            </button>
          </div>
          <div className="font-semibold pl-4">{title}</div>
        </div>
        <div className={'max-w-full min-h-[44vh] overflow-x-hidden mb-8'}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};
