import { companyApi } from '@/api/company';
import { useCallback, useEffect, useState } from 'react';

export const useCompanyData = () => {
  const [companyData, setCompanyData] = useState(null as any);
  const initCompany = useCallback(async () => {
    const res = await companyApi.domain();
    console.log('faith=============resresres', res);
    if (res.ok) {
      setCompanyData(res.data);
    }
  }, []);

  useEffect(() => {
    initCompany();
  }, [initCompany]);

  return companyData;
};
