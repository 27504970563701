import { SearchOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Toast } from "antd-mobile";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { collectApi } from "../../../../api/collect-api";
import { imageConfig } from "../../../../config/config";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function CenterPage({
  coinListData,
  ctmarketlist,
  collectlist,
  onCollectChange,
}) {
  console.log(isMobile);

  const navigate = useNavigate();
  const [num, setNum] = useState(1);
  const { t: translate } = useTranslation();
  const [type, setType] = useState(1);
  const [searchText, setSearchText] = useState("");

  const filteredCoinList = Object.keys(coinListData || {}).filter((key) =>
    key.toLowerCase().includes(searchText.toLowerCase())
  );

  const getUSDTNodes = () => {
    const nodes = [];
    let coinListDataTemp = coinListData;
    for (const key in coinListDataTemp) {
      nodes.push(
        <div
          class="quotation-29"
          key={key}
          onClick={() => {
            if (type == 1) {
              navigate(`/trade/${key}`);
            } else {
              navigate(`/lever/${key}`);
            }
          }}
        >
          <div class="quotation-30">
            <div class="quotation-31">
              <span class="quotation-32">{key.toUpperCase()}/USDT</span>
            </div>
            <div class="quotation-33">
              <span class="quotation-34">
                VOL：{(coinListData[key]?.vol / 10000).toFixed(2)}M
              </span>
            </div>
          </div>
          <div class="quotation-35">
            <span class="quotation-36">
              <div class="quotation-37">
                <span class="quotation-38">{coinListData[key]?.close}</span>
              </div>
              <div class="quotation-39">
                <span class="quotation-40">$ {coinListData[key]?.close}</span>
              </div>
            </span>
          </div>
          <div
            class={
              coinListData[key]?.close < coinListData[key]?.open
                ? "quotation-41"
                : "quotation-54"
            }
          >
            {coinListData[key]?.close < coinListData[key]?.open ? "" : "+"}
            {coinListData[key]?.close &&
              (
                ((coinListData[key]?.close - coinListData[key]?.open) /
                  coinListData[key]?.open) *
                100
              ).toFixed(2)}
            %
          </div>
        </div>
        // <li
        //   class="quotationcenter-37"
        //   key={key}
        //   onClick={() => {
        //     navigate(`/trade/${key}`);
        //   }}
        // >
        //   <div class="quotationcenter-38">
        //     <div class="quotationcenter-39">
        //       <div class="quotationcenter-40">
        //         <img src={getLogo(key)} class="quotationcenter-42" />
        //       </div>
        //     </div>
        //     <div class="quotationcenter-43">
        //       <span class="quotationcenter-44">
        //         {key.toUpperCase()}
        //         <small class="quotationcenter-45">/USDT</small>
        //       </span>
        //       <p class="quotationcenter-46">
        //         {translate(getText("量"))}：
        //         {(coinListData[key]?.vol / 10000).toFixed(2)}
        //         {translate(getText("萬"))}
        //       </p>
        //     </div>
        //   </div>
        //   <div class="quotationcenter-47">
        //     <span
        //       class={
        //         coinListData[key]?.close < coinListData[key]?.open
        //           ? "quotationcenter-132"
        //           : "quotationcenter-48"
        //       }
        //     >
        //       {coinListData[key]?.close}
        //     </span>
        //   </div>
        //   <div class="quotationcenter-49">
        //     <div
        //       class={
        //         coinListData[key]?.close < coinListData[key]?.open
        //           ? "quotationcenter-134"
        //           : "quotationcenter-50"
        //       }
        //     >
        //       {coinListData[key]?.close < coinListData[key]?.open ? "" : "+"}
        //       {coinListData[key]?.close &&
        //         (
        //           ((coinListData[key]?.close - coinListData[key]?.open) /
        //             coinListData[key]?.open) *
        //           100
        //         ).toFixed(2)}
        //       %
        //     </div>
        //   </div>
        // </li>
      );
    }
    return nodes;
  };
  const getLogo = (name) => {
    let logo = "";
    for (const ctmarket of ctmarketlist) {
      if (name == ctmarket.coinname) {
        logo = imageConfig.baseImageUrl + ctmarket.logo;
        break;
      }
    }
    return logo;
  };

  const handleFavorite = async (e, key) => {
    e.stopPropagation();
    try {
      if (collectlist?.some((item) => item.coinname === key)) {
        // 取消收藏
        const data = await collectApi.del({
          uid: localStorage.getItem("uid"), // 从localStorage获取uid
          coinname: key,
        });
        if (data.ok) {
          Toast.show({
            content: translate(getText("取消自选成功")),
          });
        }
      } else {
        // 添加收藏
        const data = await collectApi.add({
          uid: localStorage.getItem("uid"),
          coinname: key,
        });
        if (data.ok) {
          Toast.show({
            content: translate(getText("添加自选成功")),
          });
        }
      }
      // 刷新收藏列表
      if (onCollectChange) {
        onCollectChange();
      }
    } catch (error) {
      console.error("收藏操作失败:", error);
      Toast.show({
        content: translate(getText("操作失败，请稍后重试")),
      });
    }
  };

  return (
    <div className="py-4 md:py-0 flex flex-col gap-2 md:gap-0">
      <div className="xl:text-heading-xl text-heading-md text-main dark:text-dark-main text-left xl:px-0 px-4 md:px-0">
        Quotation
      </div>
      <div>
        <div className="reku-new-theme xl:pl-0 pl-4 css-cjr3lq ezyzyd24">
          <div
            className={`css-vtstzx css-4k5h5 ${type === 1 ? "ezyzyd23" : ""}`}
            onClick={() => {
              setType(1);
            }}
          >
            {type === 1 ? (
              <div className="css-yt7azb ezyzyd20" style={{ opacity: 1 }}></div>
            ) : null}
            <span
              className={`!font-bold xl:!text-lg !text-md ${
                type == 1 ? "css-12kojux ezyzyd21" : ""
              }`}
            >
              {translate(getText("交割合约"))}
            </span>
          </div>
          <div
            className={`css-vtstzx css-4k5h5 ${type == 2 ? "ezyzyd23" : ""}`}
            onClick={() => {
              setType(2);
            }}
          >
            {type === 2 ? (
              <div className="css-yt7azb ezyzyd20" style={{ opacity: 1 }}></div>
            ) : null}
            <span
              className={`!font-bold xl:!text-lg !text-md ${
                type == 2 ? "css-12kojux ezyzyd21" : ""
              }`}
            >
              {translate(getText("永续"))}
            </span>
          </div>
        </div>
      </div>

      <div className="p-3 pb-0 md:px-0">
        <Input
          placeholder={translate(getText("搜索"))}
          prefix={<SearchOutlined className="text-gray-400" />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="md:max-w-xs max-w-full rounded-[12px] hover:border-emerald-500 focus:border-emerald-500 focus:shadow-sm focus:shadow-emerald-100"
          allowClear
          style={{
            height: "36px",
            fontSize: "14px",
            backgroundColor: "#f9fafb",
          }}
        />
      </div>

      <div className="w-full">
        {!isMobile ? (
          // 桌面端布局保持不变
          <>
            {/* 表头 */}
            <div className="hidden md:flex items-center justify-between py-2.5 px-3 bg-gray-50/80 border-y border-gray-100">
              <div className="w-[5%]"></div>
              <div className="w-[15%] text-sm font-medium text-gray-500">
                {translate("coin_name")}
              </div>
              <div className="w-[15%] text-right text-sm font-medium text-gray-500">
                {translate("last_price")}
              </div>
              <div className="w-[15%] text-right text-sm font-medium text-gray-500">
                {translate("24h_change")}
              </div>
              <div className="w-[15%] text-right text-sm font-medium text-gray-500">
                {translate("24h_high")}
              </div>
              <div className="w-[15%] text-right text-sm font-medium text-gray-500">
                {translate("24h_low")}
              </div>
              <div className="w-[10%] text-right text-sm font-medium text-gray-500">
                {translate("volume")}
              </div>
              <div className="w-[10%] text-right text-sm font-medium text-gray-500">
                {translate("market_cap")}
              </div>
            </div>

            {/* 列表内容 */}
            {filteredCoinList.map((key) => {
              const item = coinListData[key] || {};
              const changePercent =
                item.close && item.open
                  ? ((item.close - item.open) / item.open) * 100
                  : 0;
              const isFavorite = collectlist?.some(
                (item) => item.coinname === key
              );

              return (
                <div
                  key={`${key}`}
                  className="hidden md:flex items-center justify-between p-4 bg-white hover:bg-gray-50 border-b border-gray-100 transition-colors duration-200 cursor-pointer"
                  onClick={() => navigate(`/trade/${key}`)}
                >
                  {/* 收藏按钮 */}
                  <div className="w-[5%] flex justify-center">
                    <div className="p-1.5 rounded-full hover:bg-gray-100 transition-colors duration-200">
                      {isFavorite ? (
                        <StarFilled
                          onClick={(e) => handleFavorite(e, key)}
                          className="text-yellow-400 text-lg"
                        />
                      ) : (
                        <StarOutlined
                          onClick={(e) => handleFavorite(e, key)}
                          className="text-gray-300 hover:text-yellow-400 text-lg transition-colors duration-200"
                        />
                      )}
                    </div>
                  </div>

                  {/* 币种名称 */}
                  <div className="w-[15%] flex items-center gap-3">
                    <img
                      src={getLogo(key)}
                      className="w-10 h-10 rounded-full object-contain p-1 bg-gray-50 border border-gray-100"
                      draggable={false}
                    />
                    <div className="flex flex-col">
                      <span className="font-semibold text-gray-900">
                        {key.toUpperCase()}
                        <span className="ml-1 text-xs font-normal text-gray-400">
                          /USDT
                        </span>
                      </span>
                      <span className="text-xs text-gray-400 mt-0.5">
                        Vol: {(item.vol / 1000000).toFixed(2)}M
                      </span>
                    </div>
                  </div>

                  {/* 最新价格 */}
                  <div className="w-[15%] text-right">
                    <span className="font-medium">
                      {item.close?.toLocaleString() || "-"}
                    </span>
                  </div>

                  {/* 24h涨跌幅 */}
                  <div className="w-[15%] text-right">
                    <span
                      className={`${
                        changePercent >= 0 ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {changePercent
                        ? `${
                            changePercent >= 0 ? "+" : ""
                          }${changePercent.toFixed(2)}%`
                        : "-"}
                    </span>
                  </div>

                  {/* 24h最高 */}
                  <div className="w-[15%] text-right">
                    <span>{item.high?.toLocaleString() || "-"}</span>
                  </div>

                  {/* 24h最低 */}
                  <div className="w-[15%] text-right">
                    <span>{item.low?.toLocaleString() || "-"}</span>
                  </div>

                  {/* 成交量 */}
                  <div className="w-[10%] text-right">
                    <span>
                      {item.vol ? `${(item.vol / 1000000).toFixed(2)}M` : "-"}
                    </span>
                  </div>

                  {/* 市值 */}
                  <div className="w-[10%] text-right">
                    <span>
                      {item.vol && item.close
                        ? `${((item.vol * item.close) / 1000000000).toFixed(1)}`
                        : "-"}
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          // 移动端布局
          <div className="flex flex-col ">
            {/* 表头 */}
            <div className="flex items-center justify-between px-3 py-2 ">
              <div className="text-sm font-medium text-gray-500">
                {translate("coin_name")}
              </div>
              <div className="text-sm font-medium text-gray-500">
                {translate("last_price")} / {translate("24h_change")}
              </div>
            </div>

            <div className="mx-3 flex flex-col bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md p-3">
              {/* 列表内容 */}
              {filteredCoinList.map((key) => {
                const item = coinListData[key] || {};
                const changePercent =
                  item.close && item.open
                    ? ((item.close - item.open) / item.open) * 100
                    : 0;
                const isFavorite = collectlist?.some(
                  (item) => item.coinname === key
                );

                return (
                  <div
                    key={key}
                    className="group flex items-center px-1 py-3 border-b border-gray-100 transition-colors duration-200"
                    onClick={() => {
                      if (type == 1) {
                        navigate(`/trade/${key}`);
                      } else {
                        navigate(`/lever/${key}`);
                      }
                    }}
                  >
                    {/* 左侧：收藏按钮和币种信息 */}
                    <div className="flex items-center gap-2 flex-1">
                      <div
                        className="p-1.5 rounded-full hover:bg-gray-100 transition-colors duration-200"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFavorite(e, key);
                        }}
                      >
                        {isFavorite ? (
                          <StarFilled className="text-yellow-400 text-lg" />
                        ) : (
                          <StarOutlined className="text-gray-300 group-hover:text-gray-400 text-lg transition-colors duration-200" />
                        )}
                      </div>

                      <div className="flex items-center gap-3">
                        <img
                          src={getLogo(key)}
                          className="w-10 h-10 rounded-full object-contain p-1 bg-gray-50 border border-gray-100"
                          alt={key}
                        />
                        <div className="flex flex-col">
                          <span className="font-semibold text-gray-900">
                            {key.toUpperCase()}
                            <span className="ml-1 text-xs font-normal text-gray-400">
                              /USDT
                            </span>
                          </span>
                          <span className="text-xs text-gray-400 mt-0.5">
                            Vol: {(item.vol / 1000000).toFixed(2)}M
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* 右侧：价格和涨跌幅 */}
                    <div className="flex flex-col items-end">
                      <span className="font-medium text-gray-900">
                        {item.close?.toLocaleString() || "-"}
                      </span>
                      <span
                        className={`text-sm font-medium mt-0.5 ${
                          changePercent >= 0
                            ? "text-emerald-500"
                            : "text-red-500"
                        }`}
                      >
                        {changePercent > 0 && "+"}
                        {changePercent.toFixed(2)}%
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
