import { create } from "zustand";

const useGlobalLoadingStore = create<{
  isGlobalLoading: boolean;
  startGlobalLoading: () => void;
  stopGlobalLoading: () => void;
}>((set) => ({
  isGlobalLoading: false,
  startGlobalLoading: () => set({ isGlobalLoading: true }),
  stopGlobalLoading: () => set({ isGlobalLoading: false }),
}));

const useGlobalLoading = () => {
  const { isGlobalLoading, startGlobalLoading, stopGlobalLoading } =
    useGlobalLoadingStore();

  const loading = {
    start: () => startGlobalLoading(),
    stop: () => stopGlobalLoading(),
  };

  return {
    isGlobalLoading,
    loading,
  };
};
export default useGlobalLoading;
