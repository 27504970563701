import { RekuLayout } from "@components/reku/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { contractApi } from "../../api/contract-api";
import TopBar from "../../components/topBar";
import { getText } from "../../utils/util";
import CenterPage from "./components/centerPage";

export default function JYJLTrade() {
  const uid = localStorage.getItem("uid");
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [hyorders, sethyorders] = useState([] as any[]);

  //加载数据
  const loadhyorderData = async () => {
    const data = await contractApi.gethyorder({ uid });
    if (data.ok) {
      sethyorders(data.data);
    }
  };
  useEffect(() => {
    loadhyorderData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <RekuLayout
        title={
          <div className="text-xl font-medium text-gray-800">
            {translate(getText("交割合约"))}
          </div>
        }
      >
        <div className="bg-white rounded-lg shadow-sm">
          <CenterPage hyorders={hyorders} />
        </div>
      </RekuLayout>
    </div>
  );
}
