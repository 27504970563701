import React from "react";

interface GiftBoxProps {
  className?: string;
}

const GiftBox: React.FC<GiftBoxProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.786 3.818a.86.86 0 0 0-.614.26.93.93 0 0 0-.263.65c0 .246.096.479.263.648a.86.86 0 0 0 .614.26h1.972a6 6 0 0 0-.227-.477c-.442-.817-1.01-1.34-1.745-1.34m2.305 3.637v2.727H4.364c-.27 0-.417 0-.523-.01h-.012l-.001-.013a7 7 0 0 1-.01-.523V8a8 8 0 0 1 .01-.535l.013-.001c.106-.009.253-.01.523-.01zm-4.846-1.82a2.76 2.76 0 0 1 .63-2.831A2.68 2.68 0 0 1 8.784 2C10.418 2 11.43 3.108 12 4.066 12.57 3.108 13.583 2 15.214 2c.72 0 1.408.291 1.912.805a2.74 2.74 0 0 1 .629 2.831h1.91c.23 0 .453 0 .643.016.207.017.453.057.7.183.341.174.62.452.794.794.126.247.166.492.183.7.015.19.015.413.015.642v1.694c0 .23 0 .453-.015.643a1.8 1.8 0 0 1-.183.7 1.82 1.82 0 0 1-.795.794 1.8 1.8 0 0 1-.7.183l-.125.008v6.224c0 .48 0 .893-.028 1.233-.03.36-.094.717-.27 1.06a2.72 2.72 0 0 1-1.191 1.193c-.344.175-.702.24-1.061.27-.34.027-.754.027-1.233.027H7.6c-.479 0-.892 0-1.233-.028-.36-.03-.717-.094-1.06-.27a2.73 2.73 0 0 1-1.193-1.191c-.175-.344-.24-.702-.269-1.061a16 16 0 0 1-.028-1.233v-6.224l-.125-.008a1.8 1.8 0 0 1-.7-.183 1.8 1.8 0 0 1-.795-.795 1.8 1.8 0 0 1-.183-.7C2 10.119 2 9.895 2 9.666V7.971c0-.23 0-.453.015-.642.017-.208.057-.453.183-.7.174-.342.453-.62.795-.794.247-.126.492-.166.7-.183.189-.016.412-.016.642-.016zM5.636 12v6.182c0 .524.001.862.022 1.12.02.247.055.338.077.383.088.172.227.31.398.398.045.023.136.057.383.077.258.021.596.022 1.12.022h3.455V12zm7.273 0v8.182h3.455c.524 0 .862 0 1.12-.022.247-.02.338-.054.383-.077a.9.9 0 0 0 .398-.398c.023-.044.057-.136.077-.383.02-.258.022-.596.022-1.12V12zm6.727-1.818c.27 0 .417 0 .523-.01h.012l.002-.013c.008-.106.009-.253.009-.523V8c0-.27 0-.417-.01-.523v-.012l-.013-.001a7 7 0 0 0-.523-.01H12.91v2.728zm-4.422-4.546a.86.86 0 0 0 .614-.26.93.93 0 0 0 .263-.649.93.93 0 0 0-.263-.648.86.86 0 0 0-.614-.26c-.736 0-1.303.523-1.745 1.34-.086.16-.162.322-.227.477z"
      fill="currentColor"
    />
  </svg>
);

export default GiftBox;
