import React from "react";
import Bank from "./icons/Bank";
import Security from "./icons/Security";
import Support from "./icons/Support";
import CheckCircle from "./icons/CheckCircle";
import WatchlistStar from "./icons/WatchlistStar";
import ArrowRight from "./icons/ArrowRight";
import GiftBox from "./icons/GiftBox";
import CandleStick from "./icons/CandleStick";
import BankNote from "./icons/BankNote";
import Location from "./icons/Location";
import Mail from "./icons/Mail";
import EyeHide from "./icons/EyeHide";
import EyeShow from "./icons/EyeShow";

// 定义图标类型
export type IconName =
  | "Bank"
  | "Security"
  | "Support"
  | "CheckCircle"
  | "WatchlistStar"
  | "ArrowRight"
  | "GiftBox"
  | "CandleStick"
  | "BankNote"
  | "Location"
  | "EyeShow"
  | "EyeHide"
  | "Mail";

// 图标映射
const IconMap = {
  Bank,
  Security,
  Support,
  CheckCircle,
  WatchlistStar,
  ArrowRight,
  GiftBox,
  CandleStick,
  BankNote,
  Location,
  Mail,
  EyeShow,
  EyeHide,
};

// 定义 Icons 组件的属性
interface IconComponentProps {
  icon: IconName;
  className?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

// Icons 组件
export const Icons: React.FC<IconComponentProps> = ({
  icon,
  className,
  width,
  height,
  onClick,
}) => {
  const IconComponent = IconMap[icon];

  if (!IconComponent) {
    console.warn(`Icon "${icon}" not found`);
    return null;
  }

  return (
    <div onClick={onClick}>
      <IconComponent className={className} />
    </div>
  );
};
