import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getText } from '../../../../utils/util';
import copy from 'copy-to-clipboard';
import { Cell, Dialog } from 'react-vant';
import './index.css';
import { Button } from 'antd';
import { Card, Divider, Popup, Toast } from 'antd-mobile';
import { useEffect, useRef, useState } from 'react';
import { imageConfig } from '../../../../config/config';
import { userApi } from '@/api/user-api';

const PasswordDialog = ({ onConfirm, userInfo2 }) => {
  const { t: translate } = useTranslation();
  const [error, setError] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  return (
    <div className="px-4 pb-4">
      <input
        type="password"
        maxLength={40}
        className="w-full h-12 px-4 border-b text-center text-lg focus:outline-none"
        placeholder={translate(getText('當前密碼'))}
        onChange={(e) => {
          setInputPassword(e.target.value);
          setError('');
        }}
      />
      {!userInfo2.paypassword && (
        <div className="mt-3 text-center text-red-500 text-sm">
          {translate(getText('您還未設置支付密碼'))}
        </div>
      )}

      {error && (
        <div className="mt-2 text-center text-red-500 text-sm">{error}</div>
      )}

      <div className="mt-6 grid grid-cols-2 gap-4">
        <button
          className="h-11 text-[#4da4aa] bg-white border border-[#4da4aa] rounded-full"
          onClick={() => {
            window.location.href = '/setpass';
          }}
        >
          {userInfo2.paypassword
            ? translate(getText('修改密碼'))
            : translate(getText('去設置'))}
        </button>
        <button
          className={`h-11 rounded-full ${
            userInfo2.paypassword
              ? 'text-white bg-[#4da4aa]'
              : 'text-white bg-gray-400 cursor-not-allowed'
          }`}
          onClick={async () => {
            if (!userInfo2.paypassword) {
              setError(translate(getText('請先設置支付密碼')));
              return;
            }

            if (!inputPassword) {
              setError(translate(getText('请输入正确信息')));
              return;
            }

            if (inputPassword !== userInfo2.paypassword) {
              setError(translate(getText('密码错误')));
              return;
            }

            try {
              await onConfirm(inputPassword);
            } catch (error) {
              setError(translate(getText('操作失败，请重试')));
            }
          }}
        >
          {translate(getText('確認'))}
        </button>
      </div>
    </div>
  );
};

export default function CenterPage({
  coinList,
  tbhandle,
  use,
  setUse,
  coinPriceData,
  userInfo,
  addressList,
}) {
  const navigate = useNavigate();
  const [addrId, setAddrId] = useState(0);
  const [img, setImg] = useState('');
  const [num, setNum] = useState('');
  const { t: translate } = useTranslation();
  const [address, setAddress] = useState('');
  const [visible, setVisible] = useState(false);

  const [userInfo2, setUserInfo2] = useState({} as any);

  const errorMsg = useRef('');

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [paymentPassword, setPaymentPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const loadUserInfoData = async () => {
    const data = await userApi.userInfo();
    if (data.ok) {
      setUserInfo2(data.data);
    }
  };

  useEffect(() => {
    loadUserInfoData();
  }, []);

  let cid = 0;

  console.log('faith=============userInfo', userInfo);

  //点击回调提前处理

  const callBack = () => {
    if (!num) return;
    if (coinPriceData?.close != undefined && !coinPriceData?.close) return;

    if (!(address && num > 0)) {
      Toast.show({ content: translate(getText('请输入正确信息')) });
      return;
    }

    let inputPassword = '';

    const handler = Dialog.confirm({
      showConfirmButton: false,
      showCancelButton: false,
      closeable: true,
      closeOnClickOverlay: true,
      footer: false,
      title: translate(getText('支付密碼')),
      children: (
        <PasswordDialog
          userInfo2={userInfo2}
          onConfirm={async () => {
            const res = await tbhandle({
              cid,
              num,
              address,
              currenyName: use?.name?.toUpperCase(),
              currenyNum:
                `${num / coinPriceData?.close}` === 'NaN'
                  ? num
                  : num / coinPriceData?.close,
            });

            // 方法 1：找到关闭按钮并触发点击
            document.querySelector('.rv-popup__close-icon--top-right').click();

            setTimeout(() => {
              setNum('');
              setAddress('');
            }, 500);
          }}
        />
      ),
    });
  };

  useEffect(() => {
    cid = coinList[0]?.id;
  });

  return (
    <div class="extract-1">
      <div class="extract-2">
        <div class="extract-3">
          <div class="extract-4">
            <ul class="extract-5">
              <li class="extract-6">
                <div class="extract-7">
                  <img
                    src={imageConfig.baseImageUrl + use?.img}
                    class="extract-9"
                  />
                  <div class="extract-10">
                    <div class="extract-11">
                      <div class="extract-12"></div>
                    </div>
                    <div class="extract-13">
                      <div class="extract-14"></div>
                    </div>
                  </div>
                </div>
                <p class="extract-15">
                  {use?.name?.toUpperCase()} {translate(getText('提現'))}
                </p>
              </li>
              <li class="extract-25">
                <p class="extract-26">{translate(getText('提幣地址'))}</p>
                <div class="extract-27">
                  <div
                    class="extract-28"
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    <input
                      style={{
                        pointerEvents: 'none',
                      }}
                      class="extract-30"
                      placeholder={translate(getText('請輸入提幣地址'))}
                      value={address}
                    />
                  </div>
                </div>
              </li>
              <li class="extract-31">
                <p class="extract-32">{translate(getText('提幣數量'))}(USDT)</p>
                <div class="extract-33">
                  <div class="extract-34">
                    <input
                      type="number"
                      class="extract-36"
                      placeholder={translate(getText('請輸入提幣數量'))}
                      type="number"
                      id="usdtmoney"
                      name="usdtmoney"
                      autocomplete="off"
                      style={{
                        height: '43px',
                      }}
                      value={num}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setNum('');
                        }
                        if (/^[0-9]+(\.[0-9]{0,5})?$/.test(e.target.value)) {
                          setNum(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div class="extract-37">
                  <span class="extract-38">
                    {translate(getText('可用:'))}
                    {userInfo?.usdt} USDT
                  </span>
                </div>
              </li>
            </ul>
            <div class="extract-50">
              <div class="extract-51">
                {translate(getText('預計到賬數量：'))}
                {`${num / coinPriceData?.close}` == 'NaN'
                  ? num
                  : (num / coinPriceData?.close).toFixed(6)}{' '}
                {use?.name?.toUpperCase()}
              </div>
              <div
                class="extract-52"
                onClick={() => {
                  callBack();
                }}
              >
                {translate(getText('提交'))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  地址 */}
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: '40vh', overflowY: 'scroll' }}
      >
        {addressList.map((data) => (
          //判断过滤对应数据 name  data?.name == use?.name &&
          <div
            onClick={() => {
              setAddress(data.addr);
              setVisible(false);
            }}
          >
            <div
              className="addressList-6"
              style={{
                height: 'auto',
              }}
            >
              <div className="addressList-7">
                <div className="addressList-8">
                  <div className="addressList-9">
                    <span className="addressList-10">
                      {`${data?.name.toUpperCase()}${
                        data.czline ? `-${data.czline}`.toUpperCase() : ''
                      }`}
                    </span>
                    <span className="addressList-11">{data.addr}</span>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </Popup>
    </div>
  );
}
