import { FC, useState, useEffect, useRef } from 'react'
import { DownOutlined } from '@ant-design/icons'

interface Props {
  zbs: string[]
  nowzb: string 
  setnowzb: (zb: string) => void
}

export const VolSelect: FC<Props> = ({ zbs, nowzb, setnowzb }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const mainItems = zbs;
  const dropdownItems = [];

  return (
    <div className="my-2 flex flex-wrap gap-x-1 px-2 items-center">
      {mainItems.map((zb) => (
        <div
          key={zb}
          className={`
            px-2 py-1 rounded cursor-pointer text-[12px]
            ${nowzb === zb 
              ? 'text-blue-500 bg-background-subtle-teal' 
              : 'text-gray-600 hover:bg-gray-100'
            }
          `}
          onClick={() => setnowzb(zb)}
        >
          {zb}
        </div>
      ))}
      
      {/* Dropdown button and menu */}
      {dropdownItems.length > 0 && (
        <div className="relative" ref={dropdownRef}>
          <div
            className="px-2 py-1 rounded cursor-pointer text-sm text-gray-600 hover:bg-gray-100 flex items-center"
            onClick={() => setIsOpen(!isOpen)}
          >
            <DownOutlined />
          </div>
          
          {isOpen && (
            <div className="absolute top-full left-0 mt-1 bg-white shadow-lg rounded-md border border-gray-200 z-10">
              {dropdownItems.map((zb) => (
                <div
                  key={zb}
                  className={`
                    px-4 py-2 cursor-pointer text-sm whitespace-nowrap
                    ${nowzb === zb 
                      ? 'bg-blue-50 text-blue-500' 
                      : 'text-gray-600 hover:bg-gray-100'
                    }
                  `}
                  onClick={() => {
                    setnowzb(zb);
                    setIsOpen(false);
                  }}
                >
                  {zb}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}