import { useCtmarketlist } from "@hooks/useCtmarketlist";
import { getLogo } from "@utils/get-logo";
import { CoinListData } from "./types";

export const TradeHeader = ({
  coinname,
  coinListData,
}: {
  coinname: string;
  coinListData: CoinListData;
}) => {
  const { ctmarketlist } = useCtmarketlist();
  return (
    <div className="css-65dlnv eik4a2w5">
      <div className="css-a0377c eik4a2w4  !hidden md:!block">
        <div className="css-6wwad8 eik4a2w3">
          <img
            alt="Bitcoin (BTC) Logo"
            loading="lazy"
            width={40}
            height={40}
            decoding="async"
            data-nimg={1}
            src={getLogo(coinname, ctmarketlist)}
            style={{ color: "transparent" }}
          />
        </div>
        <div className="css-qlgi5i eik4a2w2">
          <div className="fresnel-container fresnel-lessThan-xl fresnel-:r1k:" />
          <div className="fresnel-container fresnel-greaterThanOrEqual-xl fresnel-:r1l:">
            <h1 className="css-1r4fdly eik4a2w1">{coinname}/USDT</h1>
            <h2 className="css-1vts9zp eik4a2w0">{coinname}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
