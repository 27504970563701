import { Popover } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { imageConfig } from "../../../../config/config";
import { getText } from "../../../../utils/util";
import "./index.css";

export default function TopText({
  zbs,
  nowzb,
  setnowzb,
  nowtime,
  setnowtime,
  times,
  settimeindex,
}) {
  const navigate = useNavigate();
  const [num, setNum] = useState(1);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  
  console.log(4, times);
  

  const getItem = () => {
    const nodes = [];
    for (const zb of zbs) {
      nodes.push(
        <div
          class="listvol-2"
          onClick={() => {
            setnowzb(zb);
            setshow1(false);
            setshow2(!show2);
          }}
        >
          <div class={nowzb == zb ? "listvol-3" : "listvol-9"}>
            <span class="listvol-4">{zb}</span>
          </div>
          <div class="listvol-5">
            <span class="listvol-6"></span>
          </div>
          <i class="listvol-7"></i>
        </div>
      );
    }
    return nodes;
  };

  const getTimes = () => {
    const nodes = [];
    for (const i in times) {
      const time = times[i];
      nodes.push(
        <div
          class="listmin-2"
          onClick={() => {
            settimeindex(parseInt(i)+1);
            setnowtime(time);
            setshow2(false);
            setshow1(!show1);
          }}
        >
          <div class="listmin-3">
            <span class="listmin-4">{time}</span>
          </div>
        </div>
      );
    }
    return nodes;
  };
  const { t: translate } = useTranslation();
  
  const mainTimes = ["1M", "5M", "15M", "30M", "1H", "1D", "7D"];

  // const mainTimes = ['1h', '4h', '1D', '1W', '1M']; // 主要显示的时间选项
  // const dropdownTimes = ['5m', '15m', '30m']; // 下拉菜单中的时间选项

  return (
    <div className="mt-2 inline-flex items-center h-8 bg-background-neutral-subtle rounded-sm mx-2">
      {/* 主要时间选项 */}
      {mainTimes.map((time) => (
        <div 
          key={time}
          className={`px-3 py-1 cursor-pointer text-[12px] ${
            nowtime === time 
              ? 'bg-background-subtle-teal dark:bg-blue-900 text-text-color-teal dark:text-blue-300 border border-outline-color-teal/25 rounded-[6px]' 
              : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
          }`}
          onClick={() => {
            const index = times.indexOf(time);
            if(index !== -1) {
              settimeindex(index + 1);
              setnowtime(time);
            }
          }}
        >
          {time}
        </div>
      ))}

      {/* 下拉菜单 */}
      {/* <Popover
        content={
          <div className="py-1">
            {dropdownTimes.map((time) => (
              <div
                key={time}
                className="px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                onClick={() => {
                  const index = times.indexOf(time);
                  if(index !== -1) {
                    settimeindex(index + 1);
                    setnowtime(time);
                  }
                }}
              >
                {time}
              </div>
            ))}
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <div className="px-3 py-1 cursor-pointer text-sm flex items-center text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
          Others
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      </Popover> */}
    </div>
  );
}
