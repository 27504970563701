import styled from "@emotion/styled";

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.span<{ size?: "sm" | "md" }>`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: rgb(var(--textColor-main) / var(--tw-text-opacity));

  [data-theme="dark"] & {
    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
  }

  ${({ size }) =>
    size === "sm" &&
    `
    font-size: 12px;
  `}
`;

const LabelSuffix = styled.div``;

const Prefix = styled.div`
  margin-right: 0.25rem;
  display: flex;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

interface WrapperProps {
  size?: "sm" | "md";
  focus?: boolean;
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
}

const Wrapper = styled.span<WrapperProps>`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  border-radius: var(--borderRadius-full);
  border-width: 1px;

  ${({ size }) =>
    size === "sm"
      ? `
    height: 40px;
    padding: 0.75rem;
    font-size: 12px;
  `
      : `
    height: 48px;
    padding: 0.875rem 1.5rem;
    font-size: var(--fontSize-sm-0);
    line-height: var(--fontSize-sm-1-lineHeight);
  `}

  ${({ focus }) =>
    focus
      ? `
    border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
  `
      : `
    border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
  `}

  ${({ disabled }) =>
    !disabled
      ? `
    @media (min-width: 1280px) {
      &:hover {
        border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
      }
    }
  `
      : `
    border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity)) !important;
    background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity));
  `}

  ${({ error, success }) => {
    if (error)
      return `
      border-color: rgb(var(--borderColor-error) / var(--tw-border-opacity));
      &:hover {
        border-color: rgb(var(--borderColor-error) / var(--tw-border-opacity));
      }
    `;
    if (success)
      return `
      border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
      &:hover {
        border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
      }
    `;
    return "";
  }}

  transition: border 0.1s ease;
`;

const StyledInput = styled.input<{ disabled?: boolean }>`
  width: 100%;
  border-style: none;
  background-color: transparent;
  color: rgb(var(--textColor-bold) / var(--tw-text-opacity));
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  flex: 1;
  height: -webkit-fill-available;

  ${({ disabled }) =>
    disabled
      ? `
    cursor: not-allowed;
  `
      : `
    &:hover {
      border-color: rgb(var(--colors-primary) / var(--tw-border-opacity));
    }
  `}

  &:focus {
    caret-color: rgb(var(--colors-grey-700) / 1) !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &[data-autocompleted] {
    background-color: transparent !important;
  }
`;

const Suffix = styled.div`
  margin-left: 0.25rem;
  display: flex;
`;

const Error = styled.span<{ size?: "sm" | "md" }>`
  margin-right: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  font-weight: 600;
  color: rgb(var(--colors-error) / var(--tw-text-opacity));

  ${({ size }) =>
    size === "sm" &&
    `
    font-size: 10px;
  `}
`;

const HintText = styled.span<{ size?: "sm" | "md" }>`
  margin-right: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  font-weight: 600;
  color: rgb(var(--textColor-additional) / var(--tw-text-opacity));

  ${({ size }) =>
    size === "sm" &&
    `
    font-size: 10px;
  `}
`;

const Success = styled.span<{ size?: "sm" | "md" }>`
  margin-right: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  font-weight: 600;
  color: rgb(var(--colors-success) / var(--tw-text-opacity));

  ${({ size }) =>
    size === "sm" &&
    `
    font-size: 10px;
  `}
`;

const Required = styled(Error)<{ size?: "sm" | "md" }>`
  ${({ size }) =>
    size === "md"
      ? `
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    font-weight: 600;
  `
      : `
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0px;
    font-weight: 600;
  `}

  color: rgb(var(--colors-error) / var(--tw-text-opacity));
  margin-left: 0.125rem;
`;

export {
  Group,
  LabelWrapper,
  Label,
  LabelSuffix,
  Prefix,
  InputWrapper,
  Wrapper,
  StyledInput,
  Suffix,
  Error,
  HintText,
  Success,
  Required,
};
