import "./index.css";
import React, { Component, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Dropdown, DropdownRef, Radio, Space } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { getText } from "../../../../utils/util";
import { imageConfig } from "../../../../config/config";
import { languagesData } from "../../../../i18n/i18n";
import { Select } from "antd";

export default function PageRegister({
  doRegister,
  areas,
  sendSMS,
  show60,
  setShow60,
  companyData,
  invitCode,
}) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [isPhone, setIsPhone] = useState(false);
  const [area, setArea] = useState({} as any);
  const [passwordType1, setPasswordType1] = useState(true);
  const [passwordType2, setPasswordType2] = useState(true);
  const [passwordType3, setPasswordType3] = useState(true);
  const [passwordType4, setPasswordType4] = useState(true);
  const dropdownRef = useRef<DropdownRef>();
  const [time, setTime] = useState(60);
  const lan = localStorage.getItem("i18n");
  const appStatus = localStorage.getItem("appStatus");
  const inviteType = localStorage.getItem("inviteType");
  let timeTemp = 60;
  let timer;

  //发送数据
  const [sendData, setSendData] = useState({
    type: 2,
    username: "",
    password: "",
    repassword: "",
    regcode: "",
    invit: invitCode || "",
    area: areas[0],
  });
  //切换选项
  const changeType = (type: number) => {
    setSendData({
      type: type,
      username: "",
      password: "",
      repassword: "",
      regcode: "",
      area: areas[0],
      invit: sendData.invit,
    });
  };

  //修改数据
  const updateSendData = (e) => {
    const name = e.target.name;
    setSendData({
      ...sendData,
      [name]: e.target.value,
    });
  };

  const getlanguageStr = () => {
    for (const lanData of languagesData) {
      if (lanData.code == lan) {
        return lanData.title;
      }
    }
  };
  useEffect(() => {
    setArea(areas[0]);
  }, [areas]);

  useEffect(() => {
    if (show60) {
      timer = setInterval(() => {
        if (timeTemp <= 1) {
          timeTemp = 60;
        }
        timeTemp = timeTemp - 1;
        setTime(timeTemp);
        if (timeTemp === 1) {
          setShow60(false);
          clearInterval(timer);
        }
      }, 1000);
    }

    return () => {
      // clearInterval(timer);
      // setShow60(false);
    };
  }, [show60]);

  return (
    <div className="mx-auto max-w-[457px] gap-8 rounded-3xl border p-8 relative">
      <div className="absolute top-4 right-4">
        <div className="registercenter-7 cursor-pointer">
          <span
            className="registercenter-8"
            onClick={() => {
              navigate("/changelanguage");
            }}
          >
            {getlanguageStr()}
          </span>
        </div>
      </div>
      <h1 className="text-2xl leading-9 tracking-[-0.1px] font-bold text-center">
        {translate(getText("立即註冊"))}
      </h1>

      <div className="mt-2 flex border-b mb-4">
        <div
          className={`px-4 py-2 cursor-pointer transition-all duration-300 ${
            !isPhone
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500 hover:text-gray-700"
          }`}
          onClick={() => {
            setIsPhone(false);
            setPasswordType1(true);
            setPasswordType2(true);
            setPasswordType3(true);
            setPasswordType4(true);
            changeType(2);
          }}
        >
          <span className="text-sm font-medium">
            {translate(getText("邮箱"))}
          </span>
        </div>
        <div
          className={`px-4 py-2 cursor-pointer transition-all duration-300 ${
            isPhone
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500 hover:text-gray-700"
          }`}
          onClick={() => {
            setIsPhone(true);
            setPasswordType1(true);
            setPasswordType2(true);
            setPasswordType3(true);
            setPasswordType4(true);
            changeType(1);
          }}
        >
          <span className="text-sm font-medium">
            {translate(getText("手机"))}
          </span>
        </div>
      </div>

      <div>
        {!isPhone && (
          <div className="space-y-4">
            <div className="relative">
              <input
                name="username"
                value={sendData.username}
                placeholder={translate(getText("請輸入您的電子郵件"))}
                onChange={updateSendData}
                type="email"
                autoComplete="off"
                className="w-full h-[48px] px-6 py-3.5 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-full"
              />
            </div>
            <div className="relative flex items-center">
              <input
                name="regcode"
                value={sendData.regcode}
                placeholder={translate(getText("請輸入驗證碼"))}
                onChange={updateSendData}
                type="text"
                autoComplete="off"
                className="w-full px-6 py-3.5 h-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <button
                className={`absolute right-2 px-4 py-1 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                  show60 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (show60) return;
                  const data = { ...sendData };
                  data.area = area?.internationalAreaCode;
                  sendSMS(data);
                }}
                disabled={show60}
              >
                {!show60 ? translate(getText("获取")) : `${time}s`}
              </button>
            </div>
            <div className="relative">
              <input
                name="password"
                value={sendData.password}
                placeholder={translate(getText("請輸入密碼"))}
                onChange={updateSendData}
                type="password"
                autoComplete="off"
                className="w-full px-6 py-3.5 h-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="relative">
              <input
                name="repassword"
                value={sendData.repassword}
                placeholder={translate(getText("請輸入密碼"))}
                onChange={updateSendData}
                type="password"
                autoComplete="off"
                className="w-full px-6 py-3.5 h-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            {inviteType === "1" && (
              <div className="relative">
                <input
                  name="invit"
                  value={sendData.invit}
                  placeholder={translate(getText("請輸入邀請碼"))}
                  onChange={updateSendData}
                  type="text"
                  autoComplete="off"
                  className="w-full px-6 py-3.5 h-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            )}
          </div>
        )}
        {isPhone && (
          <div className="space-y-4">
            <div className="relative flex items-center">
              <Select
                className="shrink-0 border-none"
                variant="borderless"
                style={{ minWidth: 60 }}
                dropdownStyle={{ minWidth: 200 }}
                value={`+ ${area?.internationalAreaCode}`}
                onChange={(value) => {
                  const selectedArea = areas.find((a) => a.id === value);
                  if (selectedArea) {
                    setArea(selectedArea);
                  }
                }}
              >
                {areas.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    <div className="flex justify-between items-center">
                      <span>
                        {localStorage.getItem("i18n") === "zh"
                          ? data.nameZh
                          : data.nameEn}
                      </span>
                      <span>+{data.internationalAreaCode}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
              <input
                name="username"
                value={sendData.username}
                placeholder={translate(getText("請輸入電話號碼"))}
                onChange={updateSendData}
                type="tel"
                autoComplete="off"
                className="w-full h-[48px] px-6 py-3.5 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-full"
              />
            </div>
            <div className="relative flex items-center">
              <input
                name="regcode"
                value={sendData.regcode}
                placeholder={translate(getText("請輸入驗證碼"))}
                onChange={updateSendData}
                type="text"
                autoComplete="off"
                className="w-full h-[48px] px-6 py-3.5 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-full"
              />
              <button
                className="absolute right-2 px-4 py-2 text-blue-500 bg-transparent border border-blue-500 rounded-full hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out"
                onClick={() => {
                  if (show60) return;
                  const data = { ...sendData };
                  data.area = area?.internationalAreaCode;
                  sendSMS(data);
                }}
              >
                {!show60 && translate(getText("获取"))}
                {show60 && `${time}s`}
              </button>
            </div>
            <div className="relative">
              <input
                name="password"
                value={sendData.password}
                placeholder={translate(getText("請輸入密碼"))}
                onChange={updateSendData}
                type={passwordType3 ? "password" : "text"}
                autoComplete="off"
                className="w-full h-[48px] px-6 py-3.5 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-full"
              />
              <button
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setPasswordType3(!passwordType3)}
              >
                {passwordType3 ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
            <div className="relative">
              <input
                name="repassword"
                value={sendData.repassword}
                placeholder={translate(getText("請輸入密碼"))}
                onChange={updateSendData}
                type={passwordType4 ? "password" : "text"}
                autoComplete="off"
                className="w-full h-[48px] px-6 py-3.5 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-full"
              />
              <button
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setPasswordType4(!passwordType4)}
              >
                {passwordType4 ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
            {inviteType === "1" && (
              <div>
                <input
                  name="invit"
                  value={sendData.invit}
                  placeholder={translate(getText("請輸入邀請碼"))}
                  onChange={updateSendData}
                  type="text"
                  autoComplete="off"
                  className="w-full h-[48px] px-6 py-3.5 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent rounded-full"
                />
              </div>
            )}
          </div>
        )}
      </div>

      <button
        className="w-full px-6 py-3.5 mt-4 text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out"
        onClick={() => {
          const data = { ...sendData };
          if (data.type === 1) {
            data.area = area?.internationalAreaCode;
          }
          doRegister(data);
        }}
      >
        {translate(getText("立即註冊"))}
      </button>
    </div>
  );
}
