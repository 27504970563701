// src/pages/followup/components/FollowModal/index.tsx
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { followApi } from '@/api/followup';
import { message } from 'antd';
import { financeApi } from '@/api/finance-api';

interface Trader {
  uid: string;
  name: string;
  avatar: string;
  strategy: string;
  followerCount: number;
  balance: number;
  availableBalance: number;
}

interface FollowModalProps {
  trader: Trader | null;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (amount: number) => Promise<void>;
}

export const FollowModal: React.FC<FollowModalProps> = ({
  trader,
  mockUserInfo,
  isVisible,
  onClose,
  onConfirm,
}) => {
  const navigate = useNavigate(); // 添加这行
  console.log('faith=============mockUserInfo', mockUserInfo);
  console.log('faith=============isVisible', isVisible);
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usercoinData, setusercoinData] = useState({});

  const loadUserCoinData = async () => {
    const uid = localStorage.getItem('uid');
    const data = await financeApi.userCoin({ uid });
    if (data.ok) {
      setusercoinData(data.data);
    }
  };

  useEffect(() => {
    loadUserCoinData();
    if (isVisible) {
      // 保存当前滚动位置
      const scrollY = window.scrollY;
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    }

    return () => {
      // 恢复滚动位置
      const scrollY = document.body.style.top;
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, [isVisible]);

  // 阻止穿透滚动
  const handleTouchMove = (e: React.TouchEvent) => {
    e.stopPropagation(); // 停止事件冒泡
    const target = e.target as HTMLElement;
    const isModalContent = target.closest('.modal-content');

    // 如果不是在 modal-content 内滚动，则阻止事件
    if (!isModalContent) {
      e.preventDefault();
    }
  };

  const handleConfirm = async () => {
    if (!amount || isSubmitting) return;

    setIsSubmitting(true);
    try {
      const addRes = await followApi.addFollow(trader.uid, Number(amount));
      if (addRes.code === 0) {
        message.success(t('操作成功'));
        setTimeout(() => {
          setAmount(''); // 重置输入框
          onClose();
          navigate('/followup/order');
        }, 500); // 延迟半秒跳转，让用户看到成功提示
      } else {
        setIsSubmitting(false);
        message.warning(addRes.msg);
      }
    } catch (error) {
      console.error('Follow failed:', error);
    } finally {
    }
  };

  if (!isVisible || !trader) return null;

  // mock 数据
  trader.availableBalance = mockUserInfo?.money || 0;

  const isAmountValid =
    Number(amount) > 0 && Number(amount) <= usercoinData.usdt;

  const formatTraderName = (name: string) => {
    if (name.length <= 9) return name; // 如果名称长度小于等于9，直接返回

    const start = name.slice(0, 6);
    const end = name.slice(-5);
    return `${start}...${end}`;
  };

  // 使用逻辑运算符来处理默认值
  const availableBalance =
    trader && trader.availableBalance !== undefined
      ? trader.availableBalance
      : 0;

  // 添加input blur处理
  const handleInputBlur = () => {
    // 给系统一点时间来处理软键盘收起
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  // 修改点击处理
  const handleModalClick = (e: React.MouseEvent) => {
    // 如果点击的是模态框背景，则关闭模态框
    if ((e.target as HTMLElement).className === 'modal-overlay') {
      onClose();
    }
  };

  return (
    <div
      className="modal-overlay"
      onTouchMove={handleTouchMove}
      onClick={handleModalClick}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3>{t('跟單設置')}</h3>
          <button className="close-btn" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="trader-brief">
          <img src={trader?.avatar} alt="" className="trader-avatar" />
          <div className="trader-info">
            <div className="trader-name">{formatTraderName(trader?.name)}</div>
            <div className="trader-strategy">{trader.strategy}</div>
          </div>
        </div>

        <div className="stats-row">
          <div className="stat-item">
            <div className="stat-value">{trader?.days}</div>
            <div className="stat-label">{t('帶單天數')}</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">{trader?.followCount}</div>
            <div className="stat-label">{t('跟單人數')}</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">{trader?.profit || 0} USDT</div>
            <div className="stat-label">{t('收益(USDT)')}</div>
          </div>
        </div>

        <div className="input-section">
          <div className="flex items-center justify-between py-3 border-b border-gray-100">
            <span className="text-xs text-gray-700">
              {t('跟隨交易員下單金額')}
            </span>
            <span className="inline-flex items-baseline gap-1.5 text-gray-600">
              <span className="text-xs">{t('可用')}</span>
              <span className="font-medium text-sm text-gray-900">
                {usercoinData.usdt}
              </span>
              <span className="text-xs text-gray-500">USDT</span>
            </span>
          </div>
          <div className="input-wrapper">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={handleInputBlur}
              placeholder={t('請輸入金額')}
              disabled={usercoinData.usdt <= 0}
            />
            <span className="unit">USDT</span>
          </div>
        </div>

        <button
          className={`confirm-btn ${
            isAmountValid
              ? 'bg-[#4ea5ab] text-white hover:bg-[#03b989] active:bg-[#03a57a]'
              : 'bg-gray-200 text-gray-400 cursor-not-allowed'
          }`}
          onClick={handleConfirm}
          disabled={!isAmountValid || isSubmitting}
        >
          {isSubmitting ? t('處理中...') : t('確認')}
        </button>
      </div>
    </div>
  );
};
