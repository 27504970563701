import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useRouter } from "@hooks/useRouter";
import { When } from "@components/If";
import { Link } from "@components/link";
import Container from "@components/container";
import { Logo } from "@components/logo";
import { ROOT_DOMAIN_HOST } from "@config/config";
import useScroll from "@hooks/useScroll";
// import { UserStatus } from "@type/model/profile";
// import { isBrowser } from "@utils/browser";
// import HamburgerMenu from "./components/HamburgerMenu";
// import LanguageSwitch from "./components/LanguageSwitch";
// import MainMenu from "./components/MainMenu";
// import Notification from "./components/Notification";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { getText } from "@/utils/util";
import AuthMenu from "./component/AuthMenu";
import HomePopup from "@/pages/homecenter/components/homepopup";
import { LoginMsgContext } from "@/router/router";
import { useUserInfo } from "@hooks/useUserInfo";
import { menuList } from "./tool";
import { create } from "zustand";
// const AuthMenu = dynamic(() => import("./components/AuthMenu"), {
//   ssr: false,
// });
// const RekuizBanner = dynamic(() => import("./components/RekuizBanner"), {
//   ssr: false,
// });
// const DarkModeSwitch = dynamic(() => import("./components/DarkModeSwitch"), {
//   ssr: false,
// });
const Wrap = styled.div`
  &.slide-up {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
`;
const absoluteNavbarPath = ["/about"];
const fixedNavbarPath = ["/", "/pc-home"];
const transparentPath = ["/", "/pc-home"];
const rekuiz = ["/", "/pc-home", "/markets"];
interface HeaderProps {
  profile?: {
    riskLevelType: number;
    fullName: string;
    email: string;
    // status: UserStatus;
    isLoading: boolean;
    riskLevelName: string;
  };
}

const useTopNavBox = create<{
    isShowTopNavBox: boolean;
    isShowHomePop: boolean;
    setIsShowTopNavBox: (isShowTopNavBox: boolean) => void;
    setIsShowHomePop: (isShowHomePop: boolean) => void;
  }>()((set) => ({
    isShowTopNavBox: false,
    isShowHomePop: false,
    setIsShowTopNavBox: (isShowTopNavBox: boolean) => set({ isShowTopNavBox }),
    setIsShowHomePop: (isShowHomePop: boolean) => set({ isShowHomePop }),
  }));

const Header: React.FC<HeaderProps> = ({ profile }) => {
  const { t: translate } = useTranslation();
  const [loginmsg] = useContext(LoginMsgContext);
  const { userInfo } = useUserInfo();
  const { isShowHomePop, setIsShowHomePop } = useTopNavBox();

  const router = useRouter();
  const navbarRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { verticalDirection } = useScroll();
  const isDarkMode = false;
  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const isAbsolute = Boolean(
    absoluteNavbarPath.find((e) => e === router.pathname)
  );
  const isFixed = Boolean(fixedNavbarPath.find((e) => e === router.pathname));
  const isRekuiz = Boolean(rekuiz.find((e) => e === router.pathname));
  const isTransparent = Boolean(
    transparentPath.find((e) => e === router.pathname)
  );
  // const transparencyPath =
  //   isBrowser && window?.location.href.includes("transparansi");
  const transparentBg = isTransparent && !expanded && !scrolled;
  useEffect(() => {
    const handleScroll = () => {
      if (isFixed) {
        if (!navbarRef.current) return;
        const isTop = window.scrollY < 10;
        if (verticalDirection !== "down") {
          navbarRef.current.classList.add("slide-up");
        } else {
          navbarRef.current.classList.remove("slide-up");
        }
        if (isTop) {
          navbarRef.current.classList.remove("slide-up");
          setScrolled(false);
        } else {
          setScrolled(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed, verticalDirection]);
  // useEffect(() => {
  //   const navItems = document.querySelectorAll(".reku-nav-item");
  //   if (isFixed) {
  //     if (scrolled || expanded) {
  //       Array.from(navItems).forEach((nav) => {
  //         nav.classList.remove("!text-dark-actions-grey-active");
  //       });
  //     } else {
  //       Array.from(navItems).forEach((nav) => {
  //         nav.classList.add("!text-dark-actions-grey-active");
  //       });
  //     }
  //   } else {
  //     Array.from(navItems).forEach((nav) => {
  //       nav.classList.remove("!text-dark-actions-grey-active");
  //     });
  //   }
  // }, [scrolled, expanded, router.asPath, router.isReady]);
  const getPositionNav = () => {
    if (isAbsolute) {
      return "absolute top-0 left-0 right-0";
    }
    if (isFixed) {
      return "fixed top-0 left-0 right-0 bg-background-card-main dark:bg-dark-background-card-main";
    }
    return "";
  };
  const getLogo = () => {
    if (isFixed && !scrolled) {
      if (expanded && !isDarkMode) {
        return "LogotypeDefault";
      }
      return "LogotypeWhite";
    }
    return isDarkMode ? "LogotypeWhite" : "LogotypeDefault";
  };
  return (
    <Wrap
      className={classNames(
        "reku-new-theme w-full h-auto flex flex-col",
        "bg-background-card-main dark:bg-dark-background-card-main",
        "transition duration-300",
        `z-[30]`,
        getPositionNav(),
        {
          "bg-transparent dark:bg-transparent": transparentBg,
        }
      )}
      ref={navbarRef}
    >
      <div className="h-[48px] xl:h-[72px] flex flex-col justify-center items-center xl:gap-2">
        <Container
          className={classNames("flex items-center justify-between h-full")}
        >
          <nav className="flex items-center space-x-6">
            <Link
              className="flex items-center mr-8 xl:mr-16"
              href={"/"}
              ariaLabel="Reku's logo"
            >
              <Logo className="w-[48px] h-[48px]" />
            </Link>
            {menuList.map((item) => (
              <Link
                key={item.key}
                href={item.href}
                className={classNames(
                  "md:block hidden text-base font-medium hover:text-primary-600 transition-colors duration-200",
                  `${!transparentBg ? "text-primary-600" : "text-white"}`
                )}
              >
                {translate(getText(item.key))}
              </Link>
            ))}
          </nav>
          <div className="flex items-center gap-2 xl:gap-4">
            {/* <When condition={profile}>
              <Notification fixedNavbar={isFixed} />
            </When> */}
            {/* <LanguageSwitch /> */}
            {/* <DarkModeSwitch /> */}
            <AuthMenu
              fixedNavbar={isFixed}
              profile={profile}
              menuClick={() => setIsShowHomePop(true)}
              transparentBg={transparentBg}
            />
            {/* <HamburgerMenu expanded={expanded} setExpanded={setExpanded} /> */}
          </div>
        </Container>
      </div>
      {/* <When condition={isRekuiz}>
        <RekuizBanner />
      </When> */}
      <HomePopup
        isShowHomePop={isShowHomePop}
        setIsShowHomePop={setIsShowHomePop}
        userInfo={userInfo}
        loginmsg={loginmsg}
      />
    </Wrap>
  );
};
export default Header;
