import { FC, ReactNode } from "react";

interface Props {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const BottomSheet: FC<Props> = ({ visible, onClose, children }) => {
  if (!visible) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black/50 z-40" onClick={onClose} />
      <div
        className={`fixed bottom-0 left-0 right-0 bg-white dark:bg-dark-bg-primary rounded-t-2xl z-[60] transform transition-transform duration-300 ${
          visible ? "translate-y-0" : "translate-y-full"
        }`}
      >
        {children}
      </div>
    </>
  );
};
